import { Box } from "@mui/material";

import VariableCard from "./variable-card";

export default function DataVariables({
    model,
    trainingSet,
    setTrainingSet,
    visibility,
    setVisibility,
    handleSensorDelete,
    sensorColors
}){
    return (
        <Box
            sx={{
                maxHeight:model.model_objective === 'Predictive' ? '360px' : '600px',
                margin:'20px',
                overflow:'scroll',
                gap:'10px',
                display:'flex',
                flexDirection:'column'
            }}
        >
            {
                trainingSet.sensor_config.map((config, idx) => (
                    <VariableCard
                        config={config}
                        idx={idx}
                        model={model}
                        trainingSet={trainingSet}
                        setTrainingSet={setTrainingSet}
                        visibility={visibility}
                        setVisibility={setVisibility}
                        handleDelete={handleSensorDelete}
                        sensorColors={sensorColors}
                    />
                ))
            }
        </Box>
    )
}