import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  TableHead,
  Table,
  TableRow,
  TableCell,
  IconButton,
  TableBody
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import CloseIcon from '@mui/icons-material/Close';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { getSensorData, getRcaData } from "../../../../api"
import RCATSGraph from './rca-graph';
import dayjs from "dayjs";

// Register the necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const RootCauseAnalysisModal = ({sensor, open, setOpen, predictionData, startDate, endDate}) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Dummy data for illustration
  const currentValue = 45;
  const stdDeviation = 3.5;
  const averageValue = 42;

  const [latestValue, setLatestValue] = useState(null);
  const [data, setData] = React.useState(null);

  const [selectedMetric, setSelectedMetric] = useState(null);
  const [featureImportance, setFeatureImportance] = useState([]);
    const [statsResults, setStatsResults] = useState([]);
    const [examples, setExamples] = useState([]);
    const [exampleIdx, setExampleIdx] = useState(0);

    useEffect(() => {
        var results = [];
        predictionData['rca'].forEach((analysis) => {
            if(analysis.resource_type === 'sensor' && analysis.resource_id === sensor.sensor_id){
                results.push(analysis);
            }
        })
        setStatsResults(results);
    }, [predictionData])

    useEffect(() => {
        if(selectedMetric){
            var downtimeExamples = [];
            var idx = 0;
            predictionData['rca_examples'].forEach((example) => {
                if(example.resource_type === 'sensor' && example.resource_id === sensor.sensor_id && example.metric === selectedMetric){
                    if(idx >= 10){
                        downtimeExamples.push(example);
                    }
                    idx += 1;
                }
            })
            setExamples([...downtimeExamples]);
            setExampleIdx(0);
        }
    }, [predictionData, selectedMetric])

  useEffect(() => {
    var metricImportance = predictionData['feature_importance'][`sensor_${sensor.sensor_id}`];
    var importance = [];
    for(const key of Object.keys(metricImportance)){
        importance.push({
            'metric': key,
            'importance': metricImportance[key]
        })
    }
    setFeatureImportance(importance);
  }, [predictionData])
  const metricsWithSignificance = ['Value', 'Average'];

  // Chart data
  const chartData = {
    labels: ['Value', 'Standard Deviation', 'Average'],
    datasets: [
      {
        label: 'Feature Importance',
        data: featureImportance.map(f => f.importance),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
      const sensorData = async () => {
        const { data, header, latest_value, latest_reading } = await getSensorData(sensor, startDate, endDate);
        setLatestValue(latest_value);
        setData(data);
      }
      if(open){
          sensorData();
      }
      sensorData();
  }, [sensor, open])

  const [sensorData, setSensorData] = React.useState(null);
  const [downtimesData, setDowntimesData] = React.useState(null);
  const [previewStart, setPreviewStart] = useState(startDate);
  const [previewEnd, setPreviewEnd] = useState(endDate);
  useEffect(() => {
    const sensorData = async () => {
      const result = await getRcaData(sensor.sensor_id);
      setDowntimesData(result.downtimes);
      setSensorData(result.sensor);
    }
    if(open){
        sensorData();
    }
    sensorData();
}, [sensor, open])

useEffect(() => {
    if(examples.length > 0){
        const time = dayjs(examples[exampleIdx].time);
        const start = time.subtract(12, 'hour')
        const end = time.add(12, 'hour')
        setPreviewStart(start);
        setPreviewEnd(end);
    }
}, [examples, exampleIdx])

const handlePreviousExample = () => {
    if(exampleIdx > 0){
        setExampleIdx(exampleIdx - 1);
    }
}

const handleNextExample = () => {
    if(exampleIdx < examples.length){
        setExampleIdx(exampleIdx + 1);
    }
}


  return (
    <div>
        <Box sx={{
            height:'90vh',
            overflow:'scroll'
        }}>
            <Box
                sx={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-between',
                    gap:'50px',
                }}
            >
                <Typography variant='h5' fontWeight='bold'>
                    Root Cause Analysis
                </Typography>
                <IconButton
                    onClick={handleClose}
                >
                    <CloseIcon/>
                </IconButton>
            </Box>
            <Box>
                <Typography variant="h6" component="h2">
                    {sensor.name} ({sensor.tag_id})
                </Typography>
                <Typography variant="h6" fontSize='1rem' component="h2">
                    {sensor.description}
                </Typography>
            </Box>
            <Box
                sx={{
                    display:'flex',
                    gap:'20px',
                    alignItems:'center',
                    marginTop:'20px'
                }}
            >
                <Typography>Latest Value</Typography>
                <Box
                    sx={{
                        padding:'10px',
                        pl:'20px',
                        pr:'20px',
                        background:'lightgrey',
                        borderRadius:'10px',
                        display:'flex',
                    }}
                >
                    <span style={{fontWeight:'bold'}}>{latestValue}</span>
                </Box>
            </Box>
          <div style={{ width: '100%', marginTop:'20px', marginBottom:'20px', height: '200px' }}>
            <Bar data={chartData} options={{ responsive: true }} />
          </div>

          <Typography fontWeight='bold' variant="h7">
            Metric Significance
          </Typography>
          <Box
            sx={{
                overflow:'scroll'
            }}
            >
                <Table>
                    <TableHead>
                        {
                            [
                                'Metric',
                                'Significant',
                                'P Value',
                                'T Stat',
                                'Mean Value (Downtime)',
                                'Mean Value (No Downtime)',
                            ].map((col) => (
                                <TableCell>
                                    {col}
                                </TableCell>
                            ))
                        }
                    </TableHead>
                    <TableBody>
                            {
                                statsResults.map((result) => (
                                    <TableRow
                                        sx={{
                                            backgroundColor: (selectedMetric && (selectedMetric == result.metric)) ? 'lightgrey' : 'none',
                                            '&:hover': {backgroundColor:'lightgrey'}}}
                                        onClick={() => setSelectedMetric(result.metric)}
                                    >
                                        <TableCell>
                                            {result.metric}
                                        </TableCell>
                                        <TableCell>
                                            {result.significant ? 'True' : 'False'}
                                        </TableCell>
                                        <TableCell>
                                            {result.p_value}
                                        </TableCell>
                                        <TableCell>
                                            {result.t_stat}
                                        </TableCell>
                                        <TableCell>
                                            {result.true_mean}
                                        </TableCell>
                                        <TableCell>
                                            {result.false_mean}
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
        
                    </TableBody>
                </Table>
          </Box>
          <Box
            sx={{
                marginTop:'10px',
                padding:'5px'
            }}
          >
                {
                    selectedMetric &&
                    <RCATSGraph sensorData={sensorData} eventData={downtimesData} startDate={previewStart} endDate={previewEnd}/>
                }
                {
                    selectedMetric &&
                    <Box
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            gap:'10px',
                            width:'100%'
                        }}
                    >
                        <Box
                            sx={{
                                background:'lightgrey',
                            }}
                        >
                            <IconButton onClick={handlePreviousExample}>
                                <ArrowBackIcon/>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                background:'lightgrey',
                            }}
                        >
                            <IconButton onClick={handleNextExample}>
                                <ArrowForwardIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                }
          </Box>
        </Box>
    </div>
  );
};

export default RootCauseAnalysisModal;
