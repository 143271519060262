import { useState, useEffect, useRef } from "react";

import { Box, Typography, Popover, Popper, Divider } from "@mui/material";

import { Visibility as VisibilityIcon, VisibilityOff } from '@mui/icons-material';

import SensorsIcon from '@mui/icons-material/Sensors';
import EventIcon from '@mui/icons-material/Event';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

export default function InputCard({config, dataColors, visibility, setVisibility, resourceImportance, handleInsightsButton}){
    const dataType = config['data_type'];
    const idCol = `${dataType}_id`;
    const key = `${dataType}_${config[idCol]}`;
    const color = dataColors[key];

    const importance = resourceImportance[key]*100;

    const [detailsRef, setDetailsRef] = useState(null);
    const detailsOpen = Boolean(detailsRef);
    const [placement, setPlacement] = useState(null);
    const handleDetailsOpen = (event) => {
        setDetailsRef(event.currentTarget);
        setPlacement(event);
    }
    const open = Boolean(detailsRef);

    const handleDetailsClose = () => {
        setDetailsRef(null);
    }

    const ProgressBar = ({progress}) => {
        return (
            <Box
                sx={{
                    height:'10px',
                    backgroundColor:'#CDCDCD',
                    width:'100%',
                    borderRadius:'8px'
                }}
            >
                <Box
                    sx={{
                        width:`${progress.toLocaleString(0)}%`,
                        backgroundColor:'#4643D4',
                        height:'10px',
                        borderRadius:'8px'
                    }}
                />
            </Box>
        )
    }

    const handleVisibility = () => {
        var _visibility = {...visibility};
        _visibility[key] = !_visibility[key];
        console.log('helel');
        setVisibility({..._visibility});
      }
  
    const [visibile, setVisibile] = useState(false);
    useEffect(() => {
        const _visibile = visibility[key];
        setVisibile(_visibile);
    }, [visibility])



    return (
        <Box
            sx={{
                padding:'20px',
                backgroundColor:'white',
                border:'1px solid rgba(47,47,47,0.2)',
                borderRadius:'8px',
                '&:hover':{
                    border:'1px solid rgba(47,47,47,0.6)'
                },
                cursor:'pointer',
            }}
            onMouseEnter={handleDetailsOpen}
            onMouseLeave={handleDetailsClose}
        >
            <Popper
                placement={'top-end'}
                open={open}
                anchorEl={detailsRef} 
            >
                <Box
                    sx={{
                        display:'flex',
                        padding:'10px',
                        gap:'5px',
                        borderRadius:'8px',
                        backgroundColor:'white',
                        shadow:1,
                        border:'0.5px solid rgba(47,47,47,0.3)',
                    }}
                >
                    <Typography
                        sx={{fontSize:'14px', color:'#4643D4', cursor:'pointer'}}
                        onClick={() => handleInsightsButton(config)}
                    >
                        View Details
                    </Typography>
                    <Divider flexItem orientation='vertical'/>
                    { 
                        visibile ?
                        <VisibilityIcon onClick={handleVisibility} sx={{height:'20px'}}/>
                        : <VisibilityOff onClick={handleVisibility} sx={{height:'20px'}}/>
                    }
                </Box>
            </Popper>
            <Box
                sx={{
                    display:'flex',
                    justifyContent:'space-between',
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        gap:'10px'
                    }}
                >
                    <Box
                        sx={{
                        // width:'20px',
                        maxHeight:'25px',
                        borderRadius:'5px',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        padding:'2px',
                        backgroundColor:color
                        }}
                    >
                        {
                            config.data_type === 'sensor' ? <SensorsIcon sx={{height:'20px', fill:'white'}}/> :
                            config.data_type === 'event' ? <EventIcon sx={{height:'20px', fill:'white'}}/> :
                            <RocketLaunchIcon sx={{height:'20px', fill:'white'}}/> 
                        }
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                        }}
                    >
                        <Typography sx={{color:'#262626', fontSize:'14px'}}>{config.name}</Typography>
                        <Typography sx={{color:'#262626', fontSize:'12px'}}>{config.description}</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        gap:'5px'
                    }}

                >
                    <Typography sx={{fontSize:'14px', fontWeight:'bold'}}>{parseFloat(importance).toFixed(2)}%</Typography>
                    <ProgressBar progress={importance}/>
                </Box>
            </Box>
        </Box>
    )
}