import React, { useState } from 'react';
import {
  Box,
  Modal,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Badge,
  ListItemSecondaryAction,
  Tooltip
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import AiExplainButton from './ai-explain-button';

const AlertNotificationCenter = ({sendMaxeHiddenQuery}) => {
    const alertsData = [
        { id: 1, time: '10 min ago', source: 'Downtime prediction model', description: 'Predicted downtime on machine 1' },
        { id: 2, time: '38 min ago', source: 'Temperature anomaly detection model', description: 'Control limit violations on temperature sensor' },
        { id: 3, time: '1 hr ago', source: 'Humidity anomaly detection model', description: 'Control limit violations on humidity sensor' },
    ];
  const [open, setOpen] = useState(false);
  const [alerts, setAlerts] = useState(alertsData);

  const handleCloseAlert = (id) => {
    setAlerts(alerts.filter((alert) => alert.id !== id));
  };

  return (
    <>
    <IconButton
        onClick={() => setOpen(true)}
    >
        <Badge
            badgeContent={
                <Box
                    sx={{
                        backgroundColor:'rgba(236,34,31,0.2)',
                        borderRadius:'8px',
                        padding:'1px 6px',
                    }}
                >
                    <Typography fontSize='14px' fontWeight='bold' color='red'>3</Typography>
                </Box>
            }
        >
            <NotificationsIcon style={{ color: '#4F4F4F' }} />
        </Badge>
    </IconButton>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 2,
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <NotificationsIcon sx={{fill:'#4F4F4F'}} fontSize="small" />
              Alerts
            </Typography>
            <Box
              sx={{display:'flex', gap:'4px', alignItems:'center'}}
            >
              <IconButton sx={{p:'1px'}} onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <List>
            {alerts.map(({ id, time, source, description }) => (
              <ListItem
                key={id}
                sx={{
                  mb: 1,
                  bgcolor: 'grey.100',
                  borderRadius: 1,
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  '&:hover': { bgcolor: 'grey.200' },
                }}
              >
                <ListItemText
                  primary={`${source} - ${description}`}
                  secondary={time}
                  primaryTypographyProps={{
                    fontSize: '0.9rem',
                    fontWeight: 'bold',
                  }}
                  secondaryTypographyProps={{
                    fontSize: '0.8rem',
                    color: 'text.secondary',
                  }}
                />
                <ListItemSecondaryAction>
                  <Tooltip title="AI Explain">
                    <IconButton sx={{p:0, m:0}} onClick={() => sendMaxeHiddenQuery(`Summarize the ${description}`)}>
                      <AiExplainButton/>
                    </IconButton>
                  </Tooltip>
                  <IconButton sx={{m:0, mr:'2px', p:0}} edge="end" onClick={() => handleCloseAlert(id)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>
    </>
  );
};

export default AlertNotificationCenter;
