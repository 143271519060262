import { useState } from 'react';

import { Link } from "react-router-dom";

import { Box, Divider, Typography, Popover, Button, Drawer, IconButton } from "@mui/material";

import SensorsIcon from "@mui/icons-material/Sensors";
import CircleIcon from '@mui/icons-material/Circle';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import ProfilePic from '../profile-pic-abby.avif'

function formatDateTime(inputString) {
    // Parse the input date string
    const date = new Date(inputString);
  
    // Options for date formatting
    const dateOptions = {
      month: "long",
      day: "2-digit",
      year: "numeric",
    };
  
    // Format the date part
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
  
    // Options for time formatting
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
  
    // Format the time part
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);
  
    // Combine date and time with the desired format
    return `${formattedDate} at ${formattedTime}`;
}

export default function MonitorCard ({ monitor, handleMonitorSelect }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const SeverityTag = ({level}) => {
        const backgroundColor = level === 'high' ? 'rgba(236, 34, 31, 0.1)' : level === 'healthy' ? 'rgba(33, 163, 95, 0.15)' : 'rgba(225, 174, 19, 0.15)'
        const textColor = level === 'high' ? '#900B09' : level === 'healthy' ? '#118046' : '#967100'
        const circleColor = level === 'high' ? '#EC221F' : level === 'healthy' ? '#118046' : '#E1AE13'
        return (
            <Box
                sx={{
                    backgroundColor:backgroundColor,
                    borderRadius:'24px',
                    display:'flex',
                    padding:'10px',
                    pt:'5px',
                    pb:'5px',
                    alignItems:'center',
                    gap:'5px'
                }}
            >
                <CircleIcon sx={{fill:circleColor, fontSize:'10px', p:0, m:0}}/>
                <Typography color={textColor}>{level.charAt(0).toUpperCase() + level.slice(1)}</Typography>
            </Box>
        );
    }

    return (
      <Box
        sx={{
          padding: "16px 20px",
          borderBottom: "1px solid lightgrey",
          backgroundColor: "rgba(255, 255, 255, 1)",
          "&:hover": {
            backgroundColor: "rgba(44, 44, 45, 0.06)",
            cursor: "pointer",
          },
          display:'flex',
          justifyContent:'space-between',
        }}
        onClick={() => handleMonitorSelect(monitor)}
      >
        <Box>
            <Box
            sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
            }}
            >
            <Typography
                variant="h6"
                sx={{
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "20px",
                color: "#000000",
                textDecoration: "none",
                "&:hover": {
                    color: "#4643D4",
                },
                }}
                component={Link}
                to={'/monitors/monitor-explorer/' + monitor.monitor_id}
            >
                {monitor.name}
            </Typography>
            </Box>
            <Box
            sx={{
                paddingTop: "4px",
            }}
            >
            <Typography
                variant="subtitle"
                sx={{
                fontSize: "14px",
                lineHeight: "18px",
                }}
            >
                {monitor.description}
            </Typography>
            </Box>
            <Box
            sx={{
                paddingTop: "16px",
                display: "flex",
                gap: "8px",
                alignItems: "center",
            }}
            >
            {/* {monitor.profile_picture} */}
            <Typography
                fontSize="14px"
                fontWeight="500"
                variant="body1"
            >Process Owner</Typography>
            <img src={ProfilePic} height='20px'/>
            <Box
                sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                }}
            >
                <Typography
                fontSize="14px"
                fontWeight="600"
                variant="body1"
                color='#4643D4'
                >
                {monitor.owner}
                </Typography>
            </Box>
            </Box>
        </Box>
      </Box>
    );
  };