import { Typography, Divider, Box, IconButton, Dialog, Button } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import TypedText from './typed-text';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CategoryIcon from '@mui/icons-material/Category';
import ReactMarkdown from 'react-markdown';

import AlertNotification from './alert';

import LoadingSpinner from '../../../../../components/loading-spinner';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';

const INTRO_TEXT = "Hey there!\nAsk me questions about your machine data!"


export default function MonitorChatBot({alerts, setAlerts, setAlertConfig, alertConfig, maxeInitStatus, maxeResponse, maxeResponseIdx, conversation, messages, idx}){    
    const TEMPERATURE_ANOMALIES_TEXT = `There have been several anomalies on the temperature sensor where it has fallen below the detected lower control limit of 314. Several downtimes in the past day have seen temperature drop below 314 several times in the hour prior to the downtime. For example
    •	Downtime 5462
        Start Time: 2024/11/12 12:22 PM
        End Time:  2024/11/12 12:48 PM
        Duration: 26 minutes
        Downtime Code: A1
    •	Downtime 5468
        Start Time: 2024/11/12 1:27 PM
        End Time:  2024/11/12 2:48 PM
        Duration: 1 hour 21 minutes
        Downtime Code: A2
    •	Downtime 5469
        Start Time: 2024/11/12 4:34 PM
        End Time:  2024/11/12 6:48 PM
        Duration: 2 hours 14 minutes
        Downtime Code: C01
    `
    
    const BotMessage = ({message, typeText, idx}) => {
        return (
            <Box
                sx={{
                    width:'100%',
                    display:'flex',
                    flexDirection:'column',
                    gap:'2px',
                    width:'95%'
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        justifyContent:'right',
                        alignItems:'center',
                        pb:'2px'
                    }}
                >
                    <IconButton sx={{p:0, m:0}}><CategoryIcon sx={{height:'18px', fill:'rgb(90, 90, 90, 0.3)', '&:hover':{fill:'rgba(90,90,90)'}}}/></IconButton>
                    <IconButton sx={{p:0, m:0}}><ContentCopyIcon sx={{height:'18px', fill:'rgb(90, 90, 90, 0.3)', '&:hover':{fill:'rgba(90,90,90)'}}}/></IconButton>
                </Box>
                <Box
                    sx={{
                        background: 'linear-gradient(154.32deg, #FCFCFD 15.25%, #F6F5F9 93.61%)',
                        borderRadius: '8px',
                        border:'1px solid rgba(0, 0, 0, 0.15)',
                        pl:'10px',
                        pr:'10px',
                    }}
                >
                    {
                        typeText &&
                        <TypedText text={message} idx={idx}/>
                    }
                    {
                        !typeText &&
                        <ReactMarkdown>{message}</ReactMarkdown>
                    }
                </Box>
            </Box>
        )
    }

    const UserMessage = ({message}) => {
        return (
            <Box
                sx={{
                    width:'100%',
                    display:'flex',
                    alignItems:'center'
                }}
            >
                <Box
                    sx={{
                        width:'10%'
                    }}
                >
                </Box>
                <Box
                    sx={{
                        background: 'rgb(36, 37, 42)',
                        borderRadius: '8px',
                        pl:'10px',
                        pr:'10px',
                        pt:'15px',
                        pb:'15px',
                        width:'90%'
                    }}
                >
                    <span style={{fontSize:'15px', color:'white'}}>{message}</span>
                </Box>

            </Box>
        )
    }

    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [maxeResponseIdx, conversation, alertConfig]);

    const handleAlertCreate = (alert) => {
        var _alerts = [...alerts];
        _alerts.push(alert);
        setAlerts([..._alerts]);
        setAlertConfig(null);
    }

    const AlertMessage = ({message}) => {
        const warningText= `The predicted probability of a downtime is 84%`;
        const issueText = `A downtime might happen right now due to several factors indicated by the prediction model. The importance of specific sensors to the prediction includes: \n * **Temperature (Sensor 2)**: Importance value of 0.135 (current value: 314, warning value: 314) \n \n There have been several anomalies on the temperature sensor where it has fallen below the detected lower control limit of 314. Several downtimes in the past day have seen temperature drop below 314 several times in the hour prior to the downtime. For example \n \n * Downtime 5462 \n \t * Start Time: 2024/11/12 12:22 PM \n \t * End Time:  2024/11/12 12:48 PM \n \t * Duration: 26 minutes \n \t * Downtime Code: A1 \n * Downtime 5468 \n \t * Start Time: 2024/11/12 1:27 PM \n \t * End Time:  2024/11/12 2:48 PM \n \t * Duration: 1 hour 21 minutes \n \t * Downtime Code: A2 \n * Downtime 5469 \n \t * Start Time: 2024/11/12 4:34 PM \n \t * End Time:  2024/11/12 6:48 PM \n \t * Duration: 2 hours 14 minutes \n \t * Downtime Code: C01 \n \n The root cause of these anomalies can be contributed to many factors including plant conditions and human error. Based on the sesor data provided, the top three more important features noted are:\n * Vibration Motor 1: 18.3%\n * Flow Rate Out 10.6%\n * Flow Rate In 9.2%\n \n I would focus on the Vibration Motor 1. There is high correlation between high variance in the vibration motor and anomalies in the temperature sensor in recent data.`
        const resolutionText = `To address issues related to the Vibration Senor, follow these steps based on the information from the maintenance manual: \n 1.	Calibration: \n * Regularly calibrate the control limit sensor impacting the level of vibration on the machine. In conditions of high vibration, decrease the pressure from valve A.  \n 2.	Cleaning: \n * Keep the sensor free from dirt, dust, and other contaminants that may affect its performance. If necessary, clean the sensor according to standard procedures to remove any debris. \n \n Based on the data provided, the recent average value for pressure is consistently around 128. Given the historical data and its importance in predictive modeling, it is recommended to maintain pressure around this target value to ensure optimal performance and to avoid deviations that may lead to anomalies or issues in production efficiency. \n \n The optimization model provided recommends decreasing the throughput by 50 rpm over 20 minutes to achieve this.`

        const AlertSection = ({color, name, text}) => {
            return (
                <Box
                    sx={{
                        backgroundColor:'white',
                        margin:'10px',
                        border:'1px solid rgba(0,0,0,0.1)',
                        borderRadius:'8px',
                        boxShadow:1
                    }}
                >
                    <Box
                        sx={{
                            height:'5px',
                            backgroundColor:color,
                            borderRadius:'8px',
                            marginBottom:'10px'
                        }}
                    />
                    <Typography fontWeight='bold' fontSize='12px' paddingLeft='10px' marginBottom='10px'>{name}</Typography>
                    <Divider/>
                    <Box
                        sx={{
                            pl:'10px',
                            pr:'10px',
                        }}
                    >
                        <ReactMarkdown>
                            {text}
                        </ReactMarkdown>
                    </Box>

                </Box>
            )
        }
        
        return (
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                }}
            >
            <Box
                sx={{
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    gap:'10px',
                }}
            >
                <Box
                    sx={{
                        background: 'linear-gradient(154.32deg, #FCFCFD 15.25%, #F6F5F9 93.61%)',
                        borderRadius: '8px',
                        border:'1px solid rgba(0, 0, 0, 0.15)',
                        width:'90%'
                    }}
                >
                    <Box
                        sx={{
                            background:'rgba(0,0,0,0.3)',
                            color:'white',
                            display:'flex',
                            gap:'10px',
                            padding:'10px'
                        }}
                    >
                        
                        <AccessAlarmsIcon />
                        <Typography fontSize='16px'>Time Sensitive</Typography>
                    </Box>
                    <AlertSection color='orange' name='Warning' text={warningText} />
                    <AlertSection color='yellow' name='Root Cause' text={issueText} />
                    <AlertSection color='green' name='Solution' text={resolutionText} />
                </Box>
                <Box
                sx={{
                    width:'10%',
                    alignItems:'center',
                    justifyContent:'end'
                }}
                >
                </Box>
            </Box>
            </Box>
        )
    }

    const AlertMessageV2 = ({message}) => {
        const warningText= `The predicted probability of a downtime is 84%.`;
    
        const issueText = `**Temperature (Sensor 2)** \n * Total Anomalies (Last Hour): 6`
        // const issueText = `* **Temperature (Sensor 2)**: Importance value of 0.135 (current value: 314, warning value: 314)`

        const AlertSection = ({color, name, text}) => {
            return (
                <Box
                    sx={{
                        backgroundColor:'white',
                        margin:'10px',
                        border:'1px solid rgba(0,0,0,0.1)',
                        borderRadius:'8px',
                        boxShadow:1
                    }}
                >
                    <Box
                        sx={{
                            height:'5px',
                            backgroundColor:color,
                            borderRadius:'8px',
                            marginBottom:'10px'
                        }}
                    />
                    <Typography fontWeight='bold' fontSize='12px' paddingLeft='10px' marginBottom='10px'>{name}</Typography>
                    <Divider/>
                    <Box
                        sx={{
                            pl:'10px',
                            pr:'10px',
                        }}
                    >
                        <ReactMarkdown>
                            {text}
                        </ReactMarkdown>
                    </Box>

                </Box>
            )
        }

        const FollowUpBox = ({followUp}) => {
            return (
                <Box
                    sx={{
                        background:'black',
                        '&:hover':{
                            background:'darkgrey',
                        },
                        borderRadius:'10px',
                        // display:'flex',
                        // alignItems:'center',
                        color:'white',
                        padding:'2px',
                        pr:'4px',
                        pl:'4px'
                    }}
                >
                    <Typography fontSize='12.5px'>{followUp}</Typography>
                </Box>
            )
        }

        return (
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    width:'90%'
                }}
            >
            <Box
                sx={{
                    display:'flex',
                    justifyContent:'right',
                    alignItems:'center',
                    padding:'10px',
                }}
            >
                <IconButton sx={{p:0, m:0}}><CategoryIcon sx={{height:'18px', fill:'rgb(90, 90, 90)'}}/></IconButton>
                <IconButton sx={{p:0, m:0}}><ContentCopyIcon sx={{height:'18px', fill:'rgb(90, 90, 90)'}}/></IconButton>
            </Box>
            <Box
                sx={{
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    gap:'10px',
                }}
            >
                <Box
                    sx={{
                        background: 'linear-gradient(154.32deg, #FCFCFD 15.25%, #F6F5F9 93.61%)',
                        borderRadius: '8px',
                        border:'1px solid rgba(0, 0, 0, 0.15)',
                    }}
                >
                    <Box
                        sx={{
                            // borderTop:'8px solid orange',
                            // background:'white',
                            display:'flex',
                            gap:'5px',
                            padding:'10px'
                        }}
                    >
                        <AccessAlarmsIcon sx={{height:'20px'}}/>
                        <Typography fontWeight='bold' fontSize='14px'>Time Sensitive</Typography>
                    </Box>
                    <AlertSection color='orange' name={'Warning'} text={warningText}/>
                    <AlertSection color='red' name={'Issues'} text={issueText}/>
                    <Button>

                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    gap:'5px',
                    display:'flex',
                    flexDirection:'column',
                    overflow:'wrap',
                    padding:'10px'
                }}
            >
                <FollowUpBox followUp={'Summarize the recent temperature anomalies'}/>
                <FollowUpBox followUp={'How is the downtime prediction probability calculated?'}/>
            </Box>
            </Box>
        )
    }

    return (
        !maxeInitStatus ?
        <Box
            sx={{
                height:'70vh',
                width:'100%',
                alignItems:'center',
                justifyContent:'center',
                display:'flex',
                flexDirection:'column',
                gap:'20px'
            }}
        >
            <Typography fontWeight='bold' variant='overline' fontSize='16px'>Initializing...</Typography>
            <LoadingSpinner isLoading={true}/>
        </Box>
        :
        <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                gap:'15px',
                overflow:'scroll',
            }}
        >
            <BotMessage message={INTRO_TEXT} typeText={false}/>
            {/*
            <UserMessage message={'Summarize the predicted stamping press downtime'}/>
            <BotMessage message={`The predicted probability of a downtime is 84%. The main factor a downtime might happen right now due indicated by the prediction model: \n \n * **Temperature (Sensor 2)**: \n \t * Importance value of 0.135 \n \t * Anomaly Count (Last Hour): 3`} typeText={false}/>
            <UserMessage message={'Summarize the temperature anomalies'}/>
            <BotMessage message={'There have been several anomalies on the temperature sensor where it has fallen below the detected lower control limit of 314. Several downtimes in the past day have seen temperature drop below 314 several times in the hour prior to the downtime. For example \n \n * Downtime 5462 \n \t * Start Time: 2024/11/12 12:22 PM \n \t * End Time:  2024/11/12 12:48 PM \n \t * Duration: 26 minutes \n \t * Downtime Code: A1 \n * Downtime 5468 \n \t * Start Time: 2024/11/12 1:27 PM \n \t * End Time:  2024/11/12 2:48 PM \n \t * Duration: 1 hour 21 minutes \n \t * Downtime Code: A2 \n * Downtime 5469 \n \t * Start Time: 2024/11/12 4:34 PM \n \t * End Time:  2024/11/12 6:48 PM \n \t * Duration: 2 hours 14 minutes \n \t * Downtime Code: C01'} typeText={false}/>
            <UserMessage message={'What is the root cause of these anomalies?'}/>
            <BotMessage message={`The root cause of these anomalies can be contributed to many factors including plant conditions and human error. Based on the sesor data provided, the features noted are: \n \n * Flow Rate In 24.3% \n \n * Vibration Motor 19.2% \n \n I would focus on the Flow Rate In. There is high correlation between low values in flow rate and anomalies in the temperature sensor in recent data.`} typeText={false}/>
            <UserMessage message={`How can I increase the flow rate?`}/>
            <BotMessage message={`To address issues related to the Vibration Senor, follow these steps based on the information from the maintenance manual: \n \n 1.	Calibration: \n \n * Regularly calibrate the control limit sensor impacting the flow rate of the machine. In conditions of low flow rate, decrease the pressure on valve A.  \n \n 2.	Cleaning: \n \n * Keep the sensor free from dirt, dust, and other contaminants that may affect its performance. If necessary, clean the sensor according to standard procedures to remove any debris.`} typeText={false}/>
            <UserMessage message={`What should I change the pressure value to?`}/>
            <BotMessage message={`Based on the data provided, the recent average value for pressure is consistently around 128. Given the historical data and its importance in predictive modeling, it is recommended to maintain pressure around this target value to ensure optimal performance and to avoid deviations that may lead to anomalies or issues in production efficiency. \n \n The optimization model provided recommends decreasing the throughput by **50 RPM over 20 minutes** to achieve this.`} typeText={false}/>
            */}
            {conversation.map((message) => (
                (message.display === 1 && message.role === 'user') ? <UserMessage message={message.content}/> : (message.display === 1) ? <BotMessage message={message.content} typeText={false}/> : <Box></Box>
            ))}
            {
                (maxeResponseIdx > -1) &&
                <BotMessage message={maxeResponse.content} typeText={true} idx={maxeResponseIdx} />
            }
            {
                alertConfig &&
                <Dialog sx={{p:0, m:0}} open={Boolean(alertConfig)}>
                    <AlertNotification setAlertConfig={setAlertConfig} alert={alertConfig} onGenerateAlert={handleAlertCreate}/>
                </Dialog>
            }
            <div ref={messagesEndRef} />
        </Box>
    )
}