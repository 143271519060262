import { useState, useRef } from 'react';

import { Box, IconButton, Tooltip, Popper, Typography } from "@mui/material";
import AiExplainButton from "./ai-explain-button";
import { Visibility as VisibilityIcon, VisibilityRounded } from '@mui/icons-material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function EventCard({event, color, visibility, setVisibility, sendMaxeHiddenQuery}){
    const visible = visibility.events[event.event_id];

    const [boxRef, setBoxRef] = useState(null);
    const open = Boolean(boxRef);

    const handleVisibility = () => {
        var config = {...visibility};
        config.events[event.event_id] = !config.events[event.event_id];
        setVisibility({...config});
    }

    return(
        <Box
            sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                backgroundColor:'white',
                padding:'10px',
                border: visible ? '1px solid rgba(70, 67, 212, 0.5)' : '1px solid rgba(0,0,0, 0.1)',
                '&:hover':{
                    border: visible ? '1px solid rgba(70, 67, 212, 0.5)' : '1px solid rgba(0,0,0, 0.3)',
                    boxShadow:1
                },
                cursor:'pointer',
                borderRadius:'8px'
            }}
            onMouseEnter={(event) => setBoxRef(event.currentTarget)}
            onMouseLeave={() => setBoxRef(null)}
        >
            <Box
                sx={{
                    display:'flex',
                    gap:'5px',
                    maxWidth:'180px'
                }}
            >
                <Box sx={{height:'18px', width:'18px', borderRadius:'6px', backgroundColor:color}}/>
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        gap:'5px',
                        width:'162px'
                    }}
                >
                    <Typography fontSize='13px'>{event.name}</Typography>
                    <Typography fontSize='12px'>{event.description}</Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'end',
                    gap:'5px',
                    cursor:'pointer'
                }}
            >
                <IconButton onClick={handleVisibility} sx={{m:0, p:0}}>
                {
                    visible ? <VisibilityIcon sx={{height:'15px', fill:'black'}}/> : <VisibilityOffIcon sx={{height:'15px', fill:'#5A5A5A'}}/>
                }
                </IconButton>
            </Box>
            <Popper
                open={open}
                placement={'right-start'}
                anchorEl={boxRef}
            >
                <Tooltip title="AI Explain">
                <IconButton sx={{m:0, p:0}} onClick={() => sendMaxeHiddenQuery(`Summarize data for event_${event.event_id}`)}>
                        <AiExplainButton/>
                    </IconButton>
                </Tooltip>
            </Popper>
        </Box>
    )
}