import { useState, useEffect } from "react"
import { Box, Typography, Button } from "@mui/material"

import PlayCircleIcon from "@mui/icons-material/PlayCircle";

import TimeRangeSelector from "./time-range";
import TargetSection from "./target";
import InputsSection from "./inputs";
import RcaPanel from "./rca-panel";


export default function ModelTestingConfiguration({dataColors, model, inputVisibility, setInputVisibility, resourceRca, resourceRcaExamples, metricImportance, resourceImportance, target, inputs, startDate, endDate, setStartDate, setEndDate, setZoomStartDate, setZoomEndDate, getResults}){
    const [selectedInput, setSelectedInput] = useState(null);
    const Title = ({}) => {
        return (
        <Box
            sx={{
                height: "50px",
                display: "flex",
                backgroundColor: "white",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
                borderBottom: "1px solid lightgray",
            }}
          >
            <Typography fontWeight="bold" variant="h8">
              Configuration
            </Typography>
            <Button
              onClick={getResults}
              startIcon={<PlayCircleIcon />}
              variant="text"
              sx={{ textTransform: "none" }}
            >
              Run
            </Button>
          </Box>
        )
    };
    
    return (
        <Box
            sx={{
                borderRight:'1px solid lightgrey',
                overflow:'scroll',
                width:'100%',
                height:'100%'
            }}
        >
          {
            selectedInput ?
            <RcaPanel visibility={inputVisibility} setVisibility={setInputVisibility} selectedInput={selectedInput} setStartDate={setZoomStartDate} setEndDate={setZoomEndDate} setSelectedInput={setSelectedInput} dataColors={dataColors} resourceImportance={resourceImportance} metricImportance={metricImportance} rca={resourceRca} rcaExamples={resourceRcaExamples}/> :
            <Box>
              <Title/>
              <TimeRangeSelector startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}/>
              <TargetSection model={model} dataColors={dataColors} target={target}/>
              {
                  inputs.length > 0 &&
                  <InputsSection selectedInput={selectedInput} setSelectedInput={setSelectedInput} dataColors={dataColors} setStartDate={setZoomStartDate} setEndDate={setZoomEndDate} inputVisibility={inputVisibility} setInputVisibility={setInputVisibility} resourceRca={resourceRca} resourceRcaExamples={resourceRcaExamples} metricImportance={metricImportance} resourceImportance={resourceImportance} inputs={inputs}/>
              }
            </Box>
          }
        </Box>
    )
};