export function formatDateTime(inputString) {
    // Parse the input date string
    const date = new Date(inputString);
  
    // Options for date formatting
    const dateOptions = {
      month: "long",
      day: "2-digit",
      year: "numeric",
    };
  
    // Format the date part
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
  
    // Options for time formatting
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
  
    // Format the time part
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);
  
    // Combine date and time with the desired format
    return `${formattedDate} at ${formattedTime}`;
  }