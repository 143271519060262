import React, { useEffect, useState } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

export default function MaxeThinkingSpinner() {
    const [currentText, setCurrentText] = useState('Querying data...');

    const textOptions = [
        'Querying data...',
        'Analyzing models...',
        'Searching documents...',
        'Generating response...',
    ];

    // Rotate text every 2 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentText((prevText) => {
                const nextIndex =
                    (textOptions.indexOf(prevText) + 1) % textOptions.length;
                return textOptions[nextIndex];
            });
        }, 2000); // Change every 2 seconds

        // Clear the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
            }}
        >
            <CircularProgress size='20px'/>
            <Typography fontSize='14px' color='#4F4F4F'>{currentText}</Typography>
        </Box>
    );
}
