import { useEffect, useState } from 'react';

import { Box, TableCell, TableRow, Typography, Divider, Table, TableContainer, TableHead, TableBody, IconButton } from "@mui/material";
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import MetricImportanceGraph from './metric-importance-graph';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

import dayjs from 'dayjs';

export default function RCARow({warnings, setStartDate, setEndDate, metricImportance, rca, rcaExamples}){
    const [metrics, setMetrics] = useState([]);

    const [selectedMetric, setSelectedMetric] = useState(null);
    const [sortedExamples, setSortedExamples] = useState([]);

    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
    const [currentValue, setCurrentValue] = useState(null);

    useEffect(() => {
        if(warnings){
            const _metrics = warnings.map((w) => w.metric);
            setMetrics(_metrics);
            if(_metrics.length > 0){
                setSelectedMetric(_metrics[0])
            }
        }
    }, [warnings])

    useEffect(() => {
        var value = null;
        if(selectedMetric){
            warnings.forEach((w) => {
                if(w.metric === selectedMetric){
                    value = w.current_value;
                }
            })
        }
        setCurrentValue(value);
    }, [selectedMetric])


    useEffect(() => {
        if(rcaExamples && selectedMetric){
            var _sorted = rcaExamples[selectedMetric];
            console.log(_sorted);
            _sorted.sort((a,b) => new Date(b.time) - new Date(a.time));
            console.log(_sorted);
            setSortedExamples(_sorted);
        }
    }, [rcaExamples, selectedMetric])

    const handleAnalysisZoom = (date) => {
        const zoomDate = dayjs(date);
        setStartDate(zoomDate.subtract(8, 'hour'))
        setEndDate(zoomDate.add(8, 'hour'))
    }
    

    return (
        <TableRow col sx={{p:0, backgroundColor:'rgba(255,255,255,0.1)', boxShadow: '0 0 10px 5px rgba(0, 123, 150, 0.5)'}}>
            <TableCell colSpan={3}>
                <Box
                    sx={{
                        display:'flex',
                        alignItems:'center',
                        borderBottom: '1px solid black'
                    }}
                >
                    <EmojiObjectsIcon sx={{fill:'black', height:'15px'}}/>
                    <Typography color='black' fontWeight='bold' fontSize='12px' variant='overline'>Detected Issue</Typography>
                </Box>
                <MetricImportanceGraph setSelectedMetric={setSelectedMetric} metrics={metrics} selectedMetric={selectedMetric} importance={metricImportance}/>
                {
                    selectedMetric &&
                    <Box>
                        {
                            <Box
                                sx={{
                                    display:'flex',
                                    flexDirection:'column',
                                    gap:'20px',
                                    width:'100%'
                                }}
                            >
                                <Box
                                    sx={{
                                        width:'50%'
                                    }}
                                >
                                    <Box>
                                        <Typography color='black' fontWeight='bold' fontSize='10px' variant='overline'>Current Value</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            backgroundColor:'black',
                                            borderRadius:'8px',
                                            padding:'5px',
                                            pl:'10px',
                                            pr:'10px',
                                        }}
                                    >
                                        {
                                            currentValue &&
                                            <Typography fontSize='10px' color='white' fontWeight='bold'>{parseFloat(currentValue).toLocaleString('en-US')}</Typography>
                                        }
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        width:'50%'
                                    }}
                                >
                                    <Box>
                                        <Typography color='black' fontWeight='bold' fontSize='10px' variant='overline'>Warning Range</Typography>
                                    </Box>
                                    <Box
                                    sx={{
                                        display:'flex',
                                        alignItems:'center',
                                        gap:'5px',
                                        marginBottom:'20px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor:'black',
                                            borderRadius:'8px',
                                            padding:'5px',
                                            pl:'10px',
                                            pr:'10px',
                                            width:'40%',
                                            display:'flex',
                                            alignItems:'center',
                                            justifyContent:'center'
                                        }}
                                    >
                                        <Typography fontSize='10px' color='white' fontWeight='bold'>{(parseFloat(rca[selectedMetric]['warning_mean']) - parseFloat(rca[selectedMetric]['warning_std'])*0.2).toLocaleString('en-US')}</Typography>
                                    </Box>
                                        <Typography>to</Typography>
                                    <Box
                                        sx={{
                                            backgroundColor:'black',
                                            borderRadius:'8px',
                                            padding:'5px',
                                            pl:'10px',
                                            pr:'10px',
                                            width:'40%',
                                            display:'flex',
                                            alignItems:'center',
                                            justifyContent:'center'
                                        }}
                                    >
                                        <Typography fontSize='10px' color='white' fontWeight='bold'>{(parseFloat(rca[selectedMetric]['warning_mean']) + parseFloat(rca[selectedMetric]['warning_std'])*0.2).toLocaleString('en-US')}</Typography>
                                    </Box>
                                </Box>
                                </Box>
                            </Box>
                        }
                    </Box>
                }
            </TableCell>
        </TableRow>
    )
}