import { useState, useEffect } from "react";

import { Box, Divider, Snackbar } from "@mui/material";

import MonitorHeader from "./components/monitor-header";
import MonitorOverview from "./components/overview";
import MonitorChatBot from "./components/chatbot/index.js";
import OpenMaxeLogo from "./assets/open-maxe-logo.png";
import MonitorWorkspace from "./components/workspace";

import { useParams } from "react-router-dom";
import { getMonitor, getMonitorData, getMonitorDocument, getMaxeInitStatus, initializeMaxe, getMaxeResponse } from "../../../api";

import dayjs from "dayjs";
import MonitorDocuments from "./components/documents";

const CURRENT_TIME = '2024-11-29T13:00:00';

export default function MonitorExplorerV2({}){
    const {monitorId} = useParams('monitorId');

    const [tab, setTab] = useState('Overview');

    const [startDate, setStartDate] = useState(dayjs(CURRENT_TIME).subtract(1, 'day'))
    const [endDate, setEndDate]= useState(dayjs(CURRENT_TIME));
    const [monitor, setMonitor] = useState({
        monitor_id:null,
        name:null,
        description:null,
        sensors:[],
        events:[],
        deployments:[]
    });
    const [monitorData, setMonitorData] = useState({
        sensors:null,
        events:null,
        deployments:null
    })

    const [conversation, setConversation] = useState([]);
    const [maxeResponse, setMaxeResponse] = useState(null);
    const [maxeResponseIdx, setMaxeResponseIdx] = useState(-1);
    const [userQuery, setUserQuery] = useState('');

    const [maxeInitStatus, setMaxeInitStatus] = useState(false);

    useEffect(() => {
        const getStatus = async () => {
            const status = await getMaxeInitStatus(monitorId);
            setMaxeInitStatus(status['initialized'])
            if(!status['initialized']){
                initMaxe();
            }
        }
        getStatus();
    }, [])

    const initMaxe = async () => {
        const end = endDate;
        const start = endDate.subtract(1, 'day');
        const result = await initializeMaxe(monitorId, start, end);
        if(result['success']){
            setMaxeInitStatus(true);
        }
    }

    const handleTextFieldEnter = async (event) => {
        if (event.key === 'Enter') {
            sendMaxeQuery(userQuery);
        }
    }

    useEffect(() => {
        const getData = async () => {
            const result = await getMonitorData(monitorId, startDate, endDate);
            setMonitorData({...result});
        }
        getData();
    }, [startDate, endDate])

    useEffect(() => {
        const _getMonitor = async () => {
            const result = await getMonitor(monitorId, startDate, endDate);
            setMonitor(result);
        }
        _getMonitor();
    }, [])

    const [openMaxe, setOpenMaxe] = useState(true);
    const [maxeShrunk, setMaxeShrunk] = useState(false);

    const handleOpenMaxe = () => {
        setOpenMaxe(true);
        setMaxeShrunk(true);
    }

    const [blobUrl, setBlobUrl] = useState(null);
    useEffect(() => {
        const getDocument = async () => {
            const document = await getMonitorDocument(monitorId);
            setBlobUrl(document);
        }
        getDocument();
    }, []);

    const [maxeThinking, setMaxeThinking] = useState(false);
    const sendMaxeQuery = async (query) => {
        setUserQuery('');
        setMaxeThinking(true);
        var _previousConversation = [...conversation];
        var _conversation = [...conversation];
        _conversation.push({
            role:'user',
            content:query,
            display:1
        });
        setConversation([..._conversation]);
        const response = await getMaxeResponse(monitorId, query, _previousConversation);
        setMaxeThinking(false);
        if(response.type === 'message'){
            setMaxeResponse(response);
            const messageLength = response['content'].length;
            const timeoutId = setTimeout(() => {
                const interval = setInterval(() => {
                    setMaxeResponseIdx((prevIdx) => {
                        if (prevIdx >= messageLength) {
                          clearInterval(interval);  // Clear interval when max length is reached
                          var _response = response;
                          _response['display'] = 1;
                          _conversation.push(_response);
                          setConversation([..._conversation]);
                          setMaxeResponse(null);
                          return -1;
                        }
                        return prevIdx + 5;  // Otherwise, increment the index
                    });
                }, 1); // 10000 milliseconds = 10 seconds
                
                // Clean up the interval when the component unmounts
                return () => clearInterval(interval);
            }, 2000)
    
            return () => clearTimeout(timeoutId);
        }
    }

    const sendMaxeHiddenQuery = async (query) => {        
        setMaxeThinking(true);
        var _conversation = [...conversation];
        _conversation.push({
            role:'user',
            content:query,
            display:-1
        });
        const response = await getMaxeResponse(monitorId, query, conversation);
        setConversation([..._conversation]);

        setMaxeResponse(response);
        const messageLength = response['content'].length;
        const timeoutId = setTimeout(() => {
            const interval = setInterval(() => {
                setMaxeResponseIdx((prevIdx) => {
                    setMaxeThinking(false);
                    if (prevIdx >= messageLength) {
                      clearInterval(interval);  // Clear interval when max length is reached
                      var _response = response;
                      _response['display'] = 1;
                      _conversation.push(_response);
                      setConversation([..._conversation]);
                      setMaxeResponse(null);
                      return -1;
                    }
                    return prevIdx + 5;  // Otherwise, increment the index
                });
            }, 1); // 10000 milliseconds = 10 seconds
            
            // Clean up the interval when the component unmounts
            return () => clearInterval(interval);
        }, 2000)

        return () => clearTimeout(timeoutId);
    }

    const [visibility, setVisibility] = useState(null);
    useEffect(() => {
        var _visibility = {sensors:{}, events:{}, deployments:{}};
        if(monitor.sensors){
            monitor.sensors.forEach((s) => {
                _visibility.sensors[s.sensor_id] = false;
            })
        }
        if(monitor.events){
            monitor.events.forEach((e) => {
                _visibility.events[e.event_id] = false;
            })
        }
        if(monitor.deployments){
            monitor.deployments.forEach((d) => {
                _visibility.deployments[d.deployment_id] = false;
            })
        }
        setVisibility(_visibility);
    }, [monitor])

    const [inputPageNumber, setInputPageNumber] = useState(1);

    return (
        <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                width:'100%',
                overflowX:'hidden'
            }}
        >
            <MonitorHeader setInputPageNumber={setInputPageNumber} monitor={monitor} selectedTab={tab} setSelectedTab={setTab}/>
            <Divider/>
            <Box
                sx={{
                    display:'flex',
                }}
            >
                {
                    !openMaxe &&
                    <Snackbar
                        anchorOrigin={{ vertical:'top', horizontal:'right' }}
                        open={true}
                        onClick={handleOpenMaxe}
                        sx={{
                            marginTop:'17px',
                            transform:'translate(35%, 0%)'
                        }}
                    >
                        <img src={OpenMaxeLogo} height='50px'/>
                    </Snackbar>
                }
                <Box
                    sx={{
                        width:(openMaxe && maxeShrunk) ? 'calc(100% - 300px)' : (openMaxe && !maxeShrunk) ? 'calc(100% - 500px)' : '100%',
                        border:'1px solid rgba(47,47,47,0.2)',
                        height:'94vh',
                    }}
                >
                    {
                        tab === 'Overview' ?
                        <MonitorOverview sendMaxeHiddenQuery={sendMaxeHiddenQuery} shrunk={!maxeShrunk} startDate={startDate} endDate={endDate} monitorData={monitorData} monitor={monitor}/>
                        : tab === 'Workspace' ? <MonitorWorkspace visibility={visibility} setVisibility={setVisibility} sendMaxeHiddenQuery={sendMaxeHiddenQuery} openMaxe={openMaxe} maxeShrunk={maxeShrunk} startDate={startDate} endDate={endDate} monitorData={monitorData} monitor={monitor}/> :
                        <MonitorDocuments inputPageNumber={inputPageNumber} blobUrl={blobUrl} monitor={monitor} maxeShrunk={maxeShrunk} openMaxe={openMaxe}/>
                    }
                </Box>
                <Box sx={{width:'5px', backgroundColor:'rgba(47,47,47,0.1)'}}/>
                <Box
                    sx={{
                        width: (openMaxe && maxeShrunk) ? '300px' : (openMaxe && !maxeShrunk) ? '500px' : '0px',
                        border:'1px solid rgba(47,47,47,0.2)'
                    }}
                >
                    <MonitorChatBot
                        maxeThinking={maxeThinking}
                        setMainTab={setTab}
                        visibility={visibility}
                        setVisibility={setVisibility}
                        sendMaxeHiddenQuery={sendMaxeHiddenQuery}
                        monitor={monitor}
                        maxeInitStatus={maxeInitStatus}
                        maxeResponse={maxeResponse}
                        maxeResponseIdx={maxeResponseIdx}
                        conversation={conversation}
                        open={openMaxe}
                        setOpen={setOpenMaxe}
                        shrunk={maxeShrunk}
                        setShrunk={setMaxeShrunk}
                        sendMaxeQuery={sendMaxeQuery}
                        setUserQuery={setUserQuery}
                        userQuery={userQuery}
                        handleTextFieldEnter={handleTextFieldEnter}
                        setInputPageNumber={setInputPageNumber}
                    />

                </Box>
            </Box>
        </Box>
    )
}