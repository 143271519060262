export function parseDate(date) {
    const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const parts = formattedDate.split(",");
    const customFormattedDate = `${parts[0]} ${parts[1]}, ${parts[2]}`;

    return customFormattedDate;
};
