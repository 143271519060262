import { HeaderCell, SensorRow } from "./helpers";
import {
    TableContainer,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
} from "@mui/material";

export default function SensorsTable({filteredSensors, selectSensor, selectedSensor, groupColorsMap}) {
    return (
        <TableContainer
            sx={{
            height: "90vh",
            overflow: "scroll",
            }}
        >
            <Table>
            <TableHead sx={{ backgroundColor: "rgba(247, 247, 247, 1)" }}>
                <TableRow>
                <TableCell />
                {["Name", "Tag ID", "Group", "Masuring Point"].map((col) => (
                    <HeaderCell col={col} />
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {filteredSensors.map((sensor) => (
                    <SensorRow sensor={sensor} selectSensor={selectSensor} selectedSensor={selectedSensor} groupColorsMap={groupColorsMap}/>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
    )
}