import * as React from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import MenuDrawer from "../../components/menu-drawer";

import AddIcon from "@mui/icons-material/Add";
import { Typography, Divider, Box, Button, TextField } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import LoginIcon from '@mui/icons-material/Login';

import { API_URL } from "../../api";

const parseDate = (date) => {
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const parts = formattedDate.split(",");
  const customFormattedDate = `${parts[0]} ${parts[1]}, ${parts[2]}`;

  return customFormattedDate;
};




export default function LoginPage({setToken}) {
  const location = useLocation();
  const navigate = useNavigate();

  // Get the previous location from the state
  const from = location.state?.from?.pathname || "/"

  const [username, setUsername] = React.useState(null);
  const [password, setPassword] = React.useState(null);

  const submitLoginInfo = async () => {
    const data = {
      username: username,
      password: password,
    }

    try {
      const response = await fetch(API_URL + '/api/token', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      });
        const result = await response.json();
        if(!result.msg){
          setToken(result.access_token);
          navigate(from, { replace: true });
        }
        console.log('Success:', result);
    } catch (error) {
        console.error('Error:', error);
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            minHeight: "100vh",
            alignItems:'center',
            justifyContent:'center',
            background:'black'
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent:'center',
              padding: "60px",
            }}
          >
            <Typography
              sx={{ p: 0, m: 0 }}
              fontWeight="bold"
              fontFamily="copperplate"
              fontSize="60px"
            >
              <span style={{ p: 0, m: 0, color: "#1D175B" }}>Praxis</span>
              <span style={{ p: 0, m: 0, color: "#FF7D1F" }}>AI</span>
            </Typography>
            <Box
              sx={{
                margin: "30px",
              }}
            >
              <Box
                sx={{
                  padding: "20px",
                  paddingTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems:'center'
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      height: "20px",
                      pl: "2px",
                      pr: "2px",
                      display: "flex",
                      borderRadius: "3px",
                      marginTop: "2px",
                    }}
                  >
                    <Typography color="white">
                      Username
                    </Typography>
                  </Box>
                  <Box>
                    <TextField sx={{ 
                      '& .MuiOutlinedInput-root': {
                        'fieldset': {
                          borderColor: 'transparent', // Optional: Customize the border on hover
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent', // Optional: Customize the border on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent', // Remove the blue outline
                          boxShadow: 'none', // Remove any box shadow if present
                        },
                      },
                      background:'white'
                    }} 
                    autoComplete='off'
                    size='small'
                    onChange={(event) => setUsername(event.target.value)}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      height: "20px",
                      display: "flex",
                      borderRadius: "3px",
                      marginTop: "2px",
                      pr: "2px",
                      pl: "2px",
                    }}
                  >
                    <Typography color="white">
                      Password
                    </Typography>
                  </Box>
                  <Box>
                    <TextField sx={{ 
                        '& .MuiOutlinedInput-root': {
                          'fieldset': {
                            borderColor: 'transparent', // Optional: Customize the border on hover
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent', // Optional: Customize the border on hover
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent', // Remove the blue outline
                            boxShadow: 'none', // Remove any box shadow if present
                          },
                        },
                        background:'white'
                      }}
                      autoComplete='off'
                      size='small'
                      type='password'
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </Box>
                </Box>
                <Button
                  variant='contained'
                  sx={{textTransform:'none'}}
                  disabled={!username || !password}
                  onClick={submitLoginInfo}
                >
                    Sign In
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
