import {
    Typography,
    TableRow,
    TableCell,
    Box
  } from '@mui/material';

export default function SensorRow({sensor, selectedSensors, selectSensor}){
    var isSelected = false;
    if((selectedSensors.map((s) => (s.sensor_id))).includes(sensor.sensor_id)){
      isSelected = true;
    }
    return (
      <Box
        sx={{
          '&:hover': {
            backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.08)' : 'rgba(134, 134, 134, 0.08)',
          },
          backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.08)' : 'white',
          display:'flex',
          cursor:'pointer',
          display:'flex',
          flexDirection:'column',
          gap:'5px',
          padding:'20px',
          border:'1px solid lightgrey'
        }}
        onClick={() => selectSensor(sensor)}
      >
        <Typography fontWeight='bold' variant='body1' fontSize='0.9rem'>{sensor.name} ({sensor.tag_id})</Typography>
        <Typography variant='body1' fontSize='0.8rem'>{sensor.description}</Typography>
      </Box>
    )
  }