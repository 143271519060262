import { useEffect, useState } from 'react';

import { Box, Typography, Divider, TableContainer, TableHead, TableBody, TableCell } from "@mui/material";
import VariableCard from "./variable-card";
import VariableRow from "./variable-row";
import InputCard from './input-card'
import RCARow from './rca-row';

export default function InputsSection({warnings, dataColors, setStartDate, selectedInput, setSelectedInput, setEndDate, inputVisibility, setInputVisibility, resourceRca, resourceRcaExamples, resourceImportance, metricImportance, inputs, model}){
    const [sortedInputs, setSortedInputs] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [sortDirection, setSortDirection] = useState(1);
    const handleSortDirection = () => {
        const _dir = sortDirection;
        if(!_dir || _dir === -1){
            setSortDirection(1);
        }else{
            setSortDirection(-1);
        }
    }
    useEffect(() => {
        if((inputs.length > 0) && warnings){
            var _inputs = inputs;
            (sortDirection === 1) ? _inputs.sort((a,b) => warnings[b.key].length - warnings[a.key].length) : _inputs.sort((a,b) => warnings[a.key].length - warnings[b.key].length) 
            setSortedInputs([..._inputs]);
        }
    }, [inputs, sortDirection, warnings])

    const handleInsightsButton = (config) => {
        if(selectedInput && selectedInput.key === config.key){
            setSelectedInput(null);
        }else{
            setSelectedInput(config);
        }
    }

    useEffect(() => {
        var _rows = [];
        console.log(metricImportance);
        sortedInputs.forEach((i, idx) => {
            _rows.push(
                <VariableRow
                    dataColors={dataColors}
                    visibility={inputVisibility}
                    setVisibility={setInputVisibility}
                    config={i}
                    idx={1 + idx}
                    resourceImportance={resourceImportance}
                    handleInsightsButton={handleInsightsButton}
                />
            )
            if(resourceRca && resourceRcaExamples && selectedInput && (i.key === selectedInput.key)){
                _rows.push(
                    <RCARow
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        metricImportance={metricImportance[selectedInput.key]}
                        rca={resourceRca[selectedInput.key]}
                        rcaExamples={resourceRcaExamples[selectedInput.key]}
                    />
                )
            }
        })
        setTableRows(_rows);
    }, [sortedInputs, selectedInput, inputVisibility, resourceRca, resourceRcaExamples])

    return (
        <Box
            sx={{
            borderBottom: "1px solid lightgrey",
            backgroundColor: "rgba(247, 247, 247, 1)",
            }}
        >
            <Box
            sx={{
                backgroundColor: "white",
                height: "48px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
                gap: "20px",
                justifyContent:'space-between'
            }}
            >
            <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
                Inputs
            </Typography>
            <Box
                sx={{
                    display:'flex',
                    padding:'5px 10px',
                    border:'1px solid rgba(47,47,47,0.3)',
                    backgroundColor:'white',
                    '&:hover':{
                        background:'1px solid rgba(47,47,47,0.3)',
                        border:'1px solid rgba(47,47,47,0.5)',
                    },
                    alignItems:'center',
                    gap:'5px',
                    borderRadius:'5px',
                    cursor:'pointer'
                }}
                onClick={handleSortDirection}
            >
                {
                    sortDirection === 1 ?
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.00004 11.3332L5.66671 8.6665H3.66671V0.666504H2.33337V8.6665H0.333374L3.00004 11.3332ZM6.33337 3.33317H12.3334V4.6665H6.33337V3.33317ZM6.33337 5.99984H11V7.33317H6.33337V5.99984ZM6.33337 0.666504H13.6667V1.99984H6.33337V0.666504ZM6.33337 8.6665H9.66671V9.99984H6.33337V8.6665Z" fill="black"/>
                    </svg> :
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 2.66634L9.33329 5.33301H11.3333V13.333H12.6666V5.33301H14.6666L12 2.66634ZM8.66663 10.6663H2.66663V9.33301H8.66663V10.6663ZM8.66663 7.99967H3.99996V6.66634H8.66663V7.99967ZM8.66663 13.333H1.33329V11.9997H8.66663V13.333ZM8.66663 5.33301H5.33329V3.99967H8.66663V5.33301Z" fill="black"/>
                    </svg>
                }
                <Typography fontSize='14px' color='rgb(47,47,47)'>
                    Warnings
                </Typography>
            </Box>
            </Box>
            <Divider />
            { 
                sortedInputs.map((input) => (
                    <Box
                        sx={{p:'4px'}}
                    >
                        <InputCard warnings={warnings} metricImportance={metricImportance} handleInsightsButton={handleInsightsButton} resourceImportance={resourceImportance} visibility={inputVisibility} setVisibility={setInputVisibility} config={input} dataColors={dataColors}/>
                    </Box>
                ))
            }
        </Box>
    );
}