import { Box, Typography, Divider } from "@mui/material";
import VariableCard from "./variable-card";

export default function TargetSection({target, model}){
    return (
        <Box
            sx={{
            borderBottom: "1px solid lightgrey",
            backgroundColor: "rgba(247, 247, 247, 1)",
            }}
        >
            <Box
            sx={{
                backgroundColor: "white",
                height: "48px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
                gap: "20px",
            }}
            >
            <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
                Target
            </Typography>
            </Box>
            <Divider />
            {
                target.map((t) => (
                    <VariableCard
                        config={t}
                        idx={0}
                    />
                ))
            }
        </Box>
    );
}