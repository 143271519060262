import React from 'react';
import { Box, Typography, Button, Card, CardContent, List, ListItem, ListItemText, CardHeader, IconButton, Grid } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel'

export default function AlertConfig({ alert }){
  return (
    <Box sx={{ maxWidth: 400, backgroundColor:'white', borderRadius:'8px', margin: '1em' }}>
        <Box
            sx={{
                padding:'20px'
            }}
        >
          <Grid sx={{border: '1px solid lightgrey'}} container>
              <Grid sx={{p:'10px', border:'1px solid lightgrey'}} item xs={6}>
                    <Typography fontSize='14px' variant="overline">
                        Summary
                    </Typography>
              </Grid>
              <Grid sx={{border:'1px solid lightgrey', p:'10px'}}  item xs={6}>
                    <Typography variant="body1" sx={{ marginTop: 1, marginBottom: 1 }}>
                        {alert.summary}
                    </Typography>
              </Grid>
              {/* <Grid sx={{border:'1px solid lightgrey', p:'10px'}}  item xs={6}>
                    <Typography fontSize='14px' variant="overline">
                        Resources
                    </Typography>
              </Grid>
              <Grid sx={{border:'1px solid lightgrey', p:'10px'}}  item xs={6}>
                    <Typography variant="body1" sx={{ marginTop: 1, marginBottom: 1 }}>
                        {alert.resources}
                    </Typography>
              </Grid> */}
              <Grid sx={{border:'1px solid lightgrey', p:'10px'}}  item xs={6}>
                    <Typography fontSize='14px' variant="overline">
                        Critical Values
                    </Typography>
              </Grid>
              <Grid sx={{border:'1px solid lightgrey', p:'10px'}}  item xs={6}>
                    <Typography variant="body1" sx={{ marginTop: 1, marginBottom: 1 }}>
                        {alert.critical_values}
                    </Typography>
              </Grid>

          </Grid>
        </Box>
    </Box>
  );
};
