import { HeaderCell, EventRow } from "./helpers";
import {
    TableContainer,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
} from "@mui/material";

export default function EventsTable({filteredEvents, selectEvent, selectedEvent}) {
    return (
        <TableContainer>
            <Table>
            <TableHead sx={{ backgroundColor: "rgba(247, 247, 247, 1)" }}>
                <TableRow>
                <TableCell />
                {["Name", "Description", "Source Type"].map((col) => (
                    <HeaderCell col={col} />
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {filteredEvents.map((event) => (
                    <EventRow event={event} selectEvent={selectEvent} selectedEvent={selectedEvent}/>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
    )
}