import { Box, Typography, Divider } from "@mui/material";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ForwardIcon from '@mui/icons-material/Forward';


export default function TrainingTimeRange({startDate, endDate, setStartDate, setEndDate}){
    return (
        <Box
        sx={{
            border: '1px solid rgba(0, 0, 0, 0.1)',
            backgroundColor:'white',
            marginTop:'20px'
        }}
        >
            <Box
            sx={{
                padding:'15px',
                paddingLeft:'20px',
                display:'flex',
                gap:'10px',
                alignItems:'center'
            }}
            >
                <CalendarMonthIcon/>
                <Typography variant='h7' fontSize='0.9rem' fontWeight='bold'>Training time range</Typography>
            </Box>
            <Divider/>
            <Box
                sx={{
                    display:'flex',
                    gap:'20px',
                    padding:'20px',
                    alignItems:'center'
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        width:'100%',
                        gap:'5px'
                    }}
                >
                    <Typography variant='caption' fontWeight='bold'>Start date & time</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                        />
                    </LocalizationProvider>
                </Box>
                <ForwardIcon/>
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        width:'100%',
                        gap:'5px'
                    }}
                >
                    <Typography variant='caption' fontWeight='bold'>End date & time</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
        </Box>
    )
}