import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale, zoomPlugin);

const OptimizationTSGraph = ({
  actuals,
  optimizations,
  inputs,
  labels,
  title,
  sensorColors
}) => {
  const [options, setOptions] = React.useState(null);
  const [chartData, setChartData] = React.useState(null);
  const chartRef = React.useRef(null);
  React.useEffect(() => {  
    var data = {
      labels: labels,
      datasets: [
        {
          data: actuals,
          fill: false,
          borderColor: sensorColors ? sensorColors[title] : 'black',
          tension: 0.1,
          pointRadius: 0, // Hide the circles at data points
        },...inputs.map((input, idx) => (
          {
            label: `Input: ${input.title}`,
            hidden: !input.display,
            data: input.values,
            borderColor: sensorColors ? sensorColors[input.title] : 'black',
            tension: 0.1,
            pointRadius: 0, // Hide the circles at data points
            yAxisID: `y${idx+1}`,
          }
        ))
      ],
    };

    optimizations.forEach((input, idx) => {
        var yaxisIdx = null;
        if(input.title !== title){
            inputs.forEach((_input, _inputIdx) => {
                if(_input.title === input.title){
                    yaxisIdx = _inputIdx + 1;
                }
            })
        }
        
        data.datasets.push(
            {
              label: `Optimized: ${input.title}`,
              data: input.values,
              borderColor: sensorColors ? sensorColors[input.title] : 'black',
              tension: 0.1,
              pointRadius: 0, // Hide the circles at data points
              yAxisID: yaxisIdx ? `y${yaxisIdx}` : 'y',
              borderDash: [5, 5],
            }
        )
    })
  
    var options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          display: false,
        },
        title: {
          display: false,
          text: title,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy',
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
          },
        },
      },
      onClick: (event) => {
        if(chartRef){
          const chart = chartRef.current;
          if (!chart) return;
    
          const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
    
          if(chart.isZoomedOrPanned()){
            chart.resetZoom();
          }else if (points.length) {
            const idx = points[0].element.$context.parsed.x;
            const xMin = Math.max(chart.getInitialScaleBounds().x.min, idx - 10); // Adjust as needed
            const xMax = Math.min(chart.getInitialScaleBounds().x.max, idx + 10); // Adjust as needed
            chart.zoomScale('x', { min: xMin, max: xMax });
          }
        }
      },
      scales: {
        x: {
          display: true,
          title: {
            display: false,
            text: 'time',
          },
          ticks: {
            color: 'rgba(87,87,87,0.8)', // Change the x-axis ticks color
            callback: function(value, index, values) {
              const label = this.getLabelForValue(value);
              return index % 5 === 0 ? label.slice(0,11) : '';
            },
          },
        },
        y: {
          type: 'linear',
          display: true,
          position: 'left',
          border: {
            color: sensorColors ? sensorColors[title] : 'black'
        },
        },
      },
    };
  
    inputs.forEach((input, idx) => {
        options.scales[`y${idx + 1}`] = {
            type: 'linear',
            display: input.display,
            position: 'left',
            border: {
                color: sensorColors ? sensorColors[input.title] : 'black'
            },
        }
    })

    setChartData({...data});
    setOptions({...options});
  }, [actuals, inputs, optimizations, labels, title, sensorColors])

  return (
    <div style={{height:'620px', width:'100%'}}>
      {
        (title && chartData && options) &&
        <Line ref={chartRef} data={chartData} options={options} />
      }
    </div>
  );
};

export default OptimizationTSGraph;