import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from "@mui/material";

import ModelHeader from '../components/model-header';
import ModelTestingConfiguration from './components/configuration';
import AnomalyDetectionTSGraph from './components/anomaly-testing-graph';
import PredictiveTSGraph from './components/predictive-testing-graph';
import AlertModal from './components/alert-modal';

import { getModel, getTrainingDataset, createModelDeployment, getAnomalyModelDeployment, getPredictiveModelDeployment, getPredictiveModelPredictions, getPredictiveModelFeatureImportance, getPredictiveFeatureMetricRCA } from '../../../api';

import dayjs from 'dayjs';

import { COLORS } from './constants';
import DeployModal from './components/deploy-modal';

// const CURRENT_TIME = '2024-06-23T23:00:00';
const CURRENT_TIME = '2024-11-16T23:00:00';

export default function ModelSandboxV3({}) {
    const { modelId } = useParams("modelId");
    const [model, setModel] = useState({
        model_id:null,
        name:null,
    });
    const [trainingSet, setTrainingSet] = useState(null)
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [zoomStartDate, setZoomStartDate] = useState(dayjs());
    const [zoomEndDate, setZoomEndDate] = useState(dayjs());

    useEffect(() => {
        const end = dayjs(CURRENT_TIME);
        const start = end.subtract(7, 'day');
        setStartDate(start);
        setEndDate(end);
    }, []);

    const [target, setTarget] = useState([]);
    const [inputs, setInputs] = useState([]);

    const [testActuals, setTestActuals] = useState([]);
    const [testAnomalies, setTestAnomalies] = useState([]);
    const [testPredictions, setTestPredictions] = useState([]);

    const [warnings, setWarnings] = useState(null);

    const [resourceImportance, setResourceImportance] = useState(null);
    const [metricImportance, setMetricImportance] = useState(null);

    const [resourceRca, setResourceRca] = useState(null);
    const [resourceRcaExamples, setResourceRcaExamples] = useState(null);

    const [inputVisibility, setInputVisibility] = useState({});

    const [dataColors, setDataColors] = useState({});

    const [deploy, setDeploy] = useState(false);

    const [alert, setAlert] = useState({message:'', severity:''});
    const [hasAlert, setHasAlert] = useState(false);

    useEffect(() => {
        const _getModel = async () => {
            const _model = await getModel(modelId);
            setModel(_model);
        }
        _getModel();
    }, [modelId])

    useEffect(() => {
        const _getTrainingSet = async () => {
            const _trainingSet = await getTrainingDataset(modelId);
            setTrainingSet(_trainingSet);
        }
        _getTrainingSet();
    }, [modelId])

    useEffect(() => {
        var _target = [];
        var _inputs = [];
        var _inputVisibility = {};
        var _dataColors = {};
        if(trainingSet){
            trainingSet.sensor_config.map((config, idx) => {
                const dataType = config['data_type'];
                const idCol = `${dataType}_id`;
                const key = `${dataType}_${config[idCol]}`;
                config['key'] = key;
                _inputVisibility[key] = false;
                _dataColors[key] = COLORS[idx];
                config.variable_type === 'target' ? _target.push(config) : _inputs.push(config)
            })
        }
        setDataColors({..._dataColors});
        setInputVisibility({..._inputVisibility});
        setTarget(_target);
        setInputs(_inputs);
    }, [trainingSet])

    const getResults = async () => {
        if(model.model_objective === 'Anomaly Detection'){
            const {actuals, anomalies} = await getAnomalyModelDeployment(model, startDate, endDate);
            setZoomStartDate(startDate);
            setZoomEndDate(endDate);
            setTestActuals(actuals);
            setTestAnomalies(anomalies);
        }else if(model.model_objective === 'Predictive'){
            const {actuals, predictions, warnings} = await getPredictiveModelDeployment(model, startDate, endDate);
            setTestActuals(actuals);
            setTestPredictions(predictions);
            setWarnings(warnings);
            setZoomStartDate(startDate);
            setZoomEndDate(endDate);
        }
    }

    useEffect(() => {
        const getFeatureImportance = async () => {
            const { metric_importance, resource_importance } = await getPredictiveModelFeatureImportance(model);
            setResourceImportance(resource_importance);
            setMetricImportance(metric_importance);
        }
        const getRca = async () => {
            const { rca, rca_examples } = await getPredictiveFeatureMetricRCA(model);
            setResourceRca(rca);
            setResourceRcaExamples(rca_examples);
        }
        if(model.model_id){
            getRca();
            getFeatureImportance();
        }
    }, [model])

    useEffect(() => {
        if(model.model_id){
            getResults();
        }
    }, [model])

    const handleCreateDeployment = async () => {
        setDeploy(false);
        setAlert({
            message:'Creating deployment...',
            severity:'warning'
        })
        setHasAlert(true);
        const result = await createModelDeployment(model);
        if(result['success']){
            setHasAlert(true);
            setHasAlert(false);
            setAlert({
                message:'Deployment created!',
                severity:'success'
            })
            setHasAlert(true);
        }else{
            setHasAlert(true);
            setHasAlert(false);
            setAlert({
                message:'Deployment creation failed',
                severity:'failure'
            })
            setHasAlert(true);
        }
        const _model = await getModel(modelId);
        setModel(_model);
    }

    const MetricCards = () => {
        return (
            model.model_objective === 'Anomaly Detection' ?
            <Box
                sx={{
                    display:'flex',
                    marginBottom:'10px'
                }}
            >
                <Box
                    sx={{ padding:'5px', borderRadius:'10px', pl:'10px', pr: '10px', display:'flex', alignItems:'center', gap:'10px', backgroundColor:'#F7F7F7', border:'1px solid rgba(1,1,1,.15)'}}
                >
                    <Typography color='black' fontSize='14px'># Anomalies</Typography>
                    <Typography color='black' fontSize='18px' fontWeight='bold'>{testAnomalies.length}</Typography>
                </Box>
            </Box> :
            <Box
                sx={{
                    display:'flex'
                }}
            >
                <Box
                    sx={{ padding:'5px', borderRadius:'10px', pl:'10px', pr: '10px', display:'flex', alignItems:'center', gap:'10px', backgroundColor:'#F7F7F7', border:'1px solid rgba(1,1,1,.15)'}}
                >
                    <Typography color='black' fontSize='14px'>% Chance {target.name}</Typography>
                    <Typography color='black' fontSize='18px' fontWeight='bold'>{testPredictions[testPredictions.length - 1].probability*100}</Typography>
                </Box>
            </Box> 
        )
    }


    return (
        <Box>
            <ModelHeader model={model} tab={'Sandbox'}/>
            <AlertModal hasAlert={hasAlert} setHasAlert={setHasAlert} alertMessage={alert}/>
            <DeployModal open={deploy} setOpen={setDeploy} handleCreateDeployment={handleCreateDeployment}/>
            <Box sx={{display:'flex'}}>
                <Box sx={{
                    width:'400px',
                    height:"calc(100vh - 44px)",
                }}>
                    <ModelTestingConfiguration model={model} warnings={warnings} currentTime={CURRENT_TIME} dataColors={dataColors} inputVisibility={inputVisibility} setInputVisibility={setInputVisibility} resourceRca={resourceRca} resourceRcaExamples={resourceRcaExamples} metricImportance={metricImportance} resourceImportance={resourceImportance} target={target} inputs={inputs} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} setZoomEndDate={setZoomEndDate} setZoomStartDate={setZoomStartDate} getResults={getResults} setDeploy={setDeploy}/>
                </Box>
                <Box
                    sx={{
                        width:'calc(100vw - 500px)',
                        padding:'20px'
                    }}
                >
                    {
                        ((testPredictions.length > 0) || (testAnomalies.length > 0))
                        && <MetricCards/>
                    }
                    {
                        model.model_objective === 'Anomaly Detection' ?
                        <AnomalyDetectionTSGraph dataColors={dataColors} inputVisibility={inputVisibility} actuals={testActuals} anomalies={testAnomalies} startDate={startDate} endDate={endDate}/> :
                        <PredictiveTSGraph target={target} dataColors={dataColors} zoomStartDate={zoomStartDate} zoomEndDate={zoomEndDate} inputs={inputs} visibility={inputVisibility} predictionData={testPredictions} targetEventData={testActuals} startDate={startDate} endDate={endDate}/>
                    }
                </Box>
            </Box>
        </Box>
    );
};