import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Divider,
  Modal,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StormIcon from "@mui/icons-material/Storm";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';

import { useNavigate } from "react-router-dom";

import SensorSelectionModal from './sensor-selection-modal'
import FileUpload from "./file-upload";

import ProfilePicture from '../profile-picture-john.webp'

import { createModel, createMonitor,  getTrainingDataset, createTrainingDataset, getSensors, getEvents, getModels } from "../../../../api";
import { ArrowDropDown, ArrowForwardIcon } from "@mui/icons-material";

export default function CreateModal({ open, handleModalState }) {
  const navigate = useNavigate();

  const options = {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const now = new Date();
  const formattedDate = now
    .toLocaleDateString("en-US", options)
    .replace(",", "")
    .split(",")[0];
  const time = now
    .toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .replace(" ", "");
  const [name, setName] = React.useState(
    `New Monitor - ${formattedDate} at ${time}`
  );
  const handleNameChange = (newName) => {
    setName(newName);
  };

  const [desc, setDesc] = React.useState(null);
  const handleDescChange = (newDesc) => {
    setDesc(newDesc);
  };

  const [file, setFile] = useState(null);

  const [sensorList, setSensorList] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [deploymentList, setDeploymentList] = useState([]);
  useEffect(() => {
      const _getSensors = async () => {
        const sensors = await getSensors();
        setSensorList([...sensors]);
      };
      _getSensors();
  }, [])

  useEffect(() => {
      const _getEvents = async () => {
          const events = await getEvents();
          setEventsList([...events]);
      }
      _getEvents();
  }, [])

  useEffect(() => {
      const _getModels = async () => {
          var deployments = [];
          const models = await getModels();
          models.forEach((model) => {
              if(model.is_deployed === 'true'){
                  model['deployment_id'] = model['model_id'];
                  deployments.push(model);
              }
          })
          setDeploymentList(deployments);
      }
      _getModels();
  }, [])

  const [sensorAnchor, setSensorAnchor] = useState(null);
  const [eventAnchor, setEventAnchor] = useState(null);
  const [deploymentAnchor, setDeploymentAnchor] = useState(null);
  const handleSensorImportModal = (event) => {
    setSensorAnchor(sensorAnchor ? null : event.currentTarget)
  }
  const handleEventImportModal = (event) => {
    setEventAnchor(eventAnchor ? null : event.currentTarget)
  }
  const handleDeploymentImportModal = (event) => {
    setDeploymentAnchor(deploymentAnchor ? null : event.currentTarget)
  }

  const sensorModalOpen = Boolean(sensorAnchor);
  const eventModalOpen = Boolean(eventAnchor);
  const deploymentModalOpen = Boolean(deploymentAnchor);

  const getSensorMetadata = (sensor, dataType) => {
    const sensorImportData = {
        ...sensor,
        'data_type': dataType,
        'variable_type': null,
        'interpolation_strategy': null,
        'null_strategy': null,
        'fill_value': null,
        'transforms': [],
        'sensor_type': null,
    }
    return sensorImportData
};

const [selectedSensors, setSelectedSensors] = useState([]);
const [selectedEvents, setSelectedEvents] = useState([]);
const [selectedDeployments, setSelectedDeployments] = useState([]);
const [reccomendedSensors, setReccomendedSensors] = useState([]);
const [reccomendedEvents, setReccomendedEvents] = useState([]);

const handleSensorSelection = async (sensors, dataType) => {
    var _selectedSensors = [...selectedSensors];
    var _selectedEvents = [...selectedEvents];
    var _selectedDeployments = [...selectedDeployments];
    sensors.forEach((sensor) => {
        var sensorImport = getSensorMetadata(sensor, dataType);
        const idCol = `${dataType}_id`;
        const key = `${dataType}_${sensorImport[idCol]}`;
        sensorImport['key'] = key;
        if(dataType === 'sensor'){
          _selectedSensors.push(sensorImport);
        }else if(dataType === 'event'){
          _selectedEvents.push(sensorImport);
        }else{
          _selectedDeployments.push(sensorImport);
        }
    })
    setSelectedSensors(_selectedSensors);
    setSelectedEvents(_selectedEvents);
    setSelectedDeployments(_selectedDeployments);
}

useEffect(() => {
  var _reccomendedSensors = [];
  var _reccomendedEvents = [];
  const getDeploymentResources = async () => {
    var deploymentId = selectedDeployments[selectedDeployments.length - 1]['deployment_id'];
    const result = await getTrainingDataset(deploymentId);
    result.sensor_config.forEach((resource) => {
      if(resource.data_type === 'sensor'){
        if(!selectedSensors.map((s) => (s.sensor_id)).includes(resource.sensor_id)){
          _reccomendedSensors.push(resource);
        }
      }else if(resource.data_type === 'event'){
        if(!selectedEvents.map((e) => (e.event_id)).includes(resource.event_id)){
          _reccomendedEvents.push(resource);
        }
      }
    })
    setReccomendedSensors(_reccomendedSensors);
    setReccomendedEvents(_reccomendedEvents);
  }
  if(selectedDeployments.length > 0){
    getDeploymentResources();
  }
}, [selectedDeployments])

const handleSensorDelete = async (event, resource, dataType) => {
  event.stopPropagation();
  var _selectedSensors = [];
  var _selectedEvents = [];
  var _selectedDeployments = [];
  if(dataType === 'sensor'){
    selectedSensors.forEach((sensor) => {
      if(sensor.sensor_id != resource.sensor_id){
        _selectedSensors.push(sensor);
      }
    })
    setSelectedSensors(_selectedSensors);
  }else if(dataType === 'event'){
    selectedEvents.forEach((sensor) => {
      if(sensor.sensor_id != resource.sensor_id){
        _selectedEvents.push(sensor);
      }
    })
    setSelectedEvents(_selectedEvents);
  }else{
    selectedDeployments.forEach((sensor) => {
      if(sensor.sensor_id != resource.sensor_id){
        _selectedDeployments.push(sensor);
      }
    })
    setSelectedDeployments(_selectedDeployments);
  }
}

useEffect(() => {
  var _sensorList = [];
  var _eventsList = [];
  var _deploymentsList = [];
  sensorList.forEach((sensor) => {
    if(!selectedSensors.map((s) => (s.sensor_id)).includes(sensor.sensor_id)){
      _sensorList.push(sensor);
    }
  })
  eventsList.forEach((event) => {
    if(!selectedEvents.map((e) => (e.event_id)).includes(event.event_id)){
      _eventsList.push(event);
    }
  })
  deploymentList.forEach((deployment) => {
    if(!selectedDeployments.map((d) => (d.deployment_id)).includes(deployment.deployment_id)){
      _deploymentsList.push(deployment);
    }
  })
  setSensorList(_sensorList);
  setEventsList(_eventsList);
  setDeploymentList(_deploymentsList);
}, [selectedSensors, selectedEvents, selectedDeployments])

const SensorTag = ({sensor}) => {
  return (
    <Box
      sx={{
        backgroundColor:'rgb(40,40,40)',
        color:'white',
        padding:'5px',
        pl:'10px',
        pr:'10px',
        borderRadius:'8px',
        fontSize:'10px',
        color:'white',
        display:'flex',
        alignItems:'center',
        gap:'4px',
        cursor:'pointer',
      }}
    >
      <IconButton onClick={(event) => handleSensorDelete(event, sensor, 'sensor')} sx={{m:0, p:0}}>
        <CancelIcon sx={{height:'14px', fill:'white'}}/>
      </IconButton>
      <Typography>
        {sensor.name} ({sensor.tag_id})
      </Typography>
    </Box>
  )
}

const EventTag = ({event}) => {
  return (
    <Box
      sx={{
        backgroundColor:'rgb(40,40,40)',
        color:'white',
        padding:'5px',
        pl:'10px',
        pr:'10px',
        borderRadius:'8px',
        fontSize:'10px',
        color:'white',
        display:'flex',
        alignItems:'center',
        gap:'4px',
        cursor:'pointer',
      }}
    >
      <IconButton onClick={(event) => handleSensorDelete(event, event, 'event')} sx={{m:0, p:0}}>
        <CancelIcon sx={{fill:'white'}}/>
      </IconButton>
      <Typography>
        {event.name}
      </Typography>
    </Box>
  )
}

const DeploymentTag = ({deployment}) => {
  return (
    <Box
      sx={{
        backgroundColor:'rgb(40,40,40)',
        color:'white',
        padding:'5px',
        pl:'10px',
        pr:'10px',
        borderRadius:'8px',
        fontSize:'10px',
        color:'white',
        display:'flex',
        alignItems:'center',
        gap:'4px',
        cursor:'pointer',
      }}
    >
      <IconButton onClick={(event) => handleSensorDelete(event, deployment, 'deployment')} sx={{m:0, p:0}}>
        <CancelIcon sx={{fill:'white'}}/>
      </IconButton>
      <Typography>
        {deployment.name}
      </Typography>
    </Box>
  )
}

const handleCreateMonitor = async () => {
  const result = await createMonitor(name, desc, selectedSensors, selectedEvents, selectedDeployments, file);
  if(result['success']){
    navigate('/monitors/monitor-explorer-v2/' + result['monitor_id'])
  }
}

const handleImportReccomendedSensors = () => {
  if(reccomendedSensors.length > 0){
    handleSensorSelection(reccomendedSensors, 'sensor')
    setReccomendedSensors([]);
  }
}

const handleImportReccomendedEvents = () => {
  if(reccomendedEvents.length > 0){
    handleSensorSelection(reccomendedEvents, 'event')
    setReccomendedEvents([]);
  }
}

const [tab, setTab] = useState(1);

const NextSection = ({idx, handleNext}) => {
  const handleNextClick = () => {
    setTab((prev) => (prev + 1));
    handleNext();
  }

  return (
    <Box
      sx={{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        paddingTop:'14px'
      }}
    >
          {
            idx === 1 ?
            <Button
              sx={{
                padding:'10px 20px',
                textTransform: "none",
                borderColor: "rgba(55, 70, 91, 0.32)",
                color: "black",
                width: "50px",
                "&:hover": {
                  borderColor: "rgba(55, 70, 91, 0.32)",
                  backgroundColor: "rgba(55, 70, 91, 0.05)", // Slight background change on hover
                },
              }}
              variant="outlined"
              onClick={() => handleModalState(false)}
            >
              Cancel
            </Button> :
            <Button
              sx={{
                padding:'10px 20px',
                textTransform: "none",
                borderColor: "rgba(55, 70, 91, 0.32)",
                color: "black",
                width: "50px",
                "&:hover": {
                  borderColor: "rgba(55, 70, 91, 0.32)",
                  backgroundColor: "rgba(55, 70, 91, 0.05)", // Slight background change on hover
                },
              }}
              variant="outlined"
              onClick={() => setTab((prev) => (prev - 1))}
            >
              Back
            </Button>
          }
          {
            idx === 3 ?
            <Button
              sx={{
                backgroundColor: "rgba(33, 150, 83, 1)",
                color: "white",
                textTransform: "none",
                padding: '10px 20px',
                "&:hover": {
                  backgroundColor: "rgba(33, 150, 83, 0.95)", // Hover background color
                },
              }}
              variant="contained"
              onClick={handleCreateMonitor}
            >
              Create monitor
            </Button> :
            <Button
                sx={{
                    background: 'linear-gradient(90deg, #7975FF, #5853F5)',
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '8px',
                    textTransform: 'none',
                    '&:hover': {
                    background: 'linear-gradient(90deg, #5853F5, #7975FF)', // Reverse gradient on hover
                    },
                }}
                onClick={handleNextClick}
            >
                Next
            </Button>
          }
    </Box>
  )

}

const DetailsTab = ({}) => {
  const [tabName, setTabName] = useState(name);
  const [tabDesc, setTabDesc] = useState(desc);

  const handleNext = () => {
    setName(tabName);
    setDesc(tabDesc);
  }

  return (
    <Box
      sx={{
        padding:'24px',
        pt:'0px',
        display:'flex',
        gap:'20px',
        flexDirection:'column'
      }}
    >
      <Box
        sx={{
          display:'flex',
          gap:'12px',
          flexDirection:'column'
        }}
      >
        <Typography color='#24252A' fontWeight={550} variant='h3' fontSize='26px'>Create a new Monitor</Typography>
        <Typography color='#24252A' fontWeight={500} variant='h3' fontSize='16px'>A Monitor allows real time optimization and monitoring of configured resources</Typography>
      </Box>
      <Box
        sx={{
          paddingBottom: "15px",
          paddingTop: "5px",
          display: "flex",
          gap: "5px",
          flexDirection: "column",
        }}
      >
        <Typography color="rgba(79, 79, 79, 1)">
          Name<span style={{ color: "rgba(255,20,20)" }}>*</span>
        </Typography>
        <TextField
          autoComplete="off"
          onChange={(e) => setTabName(e.target.value)}
          value={tabName}
          placeholder="Enter name..."
          size="small"
          sx={{ width: "100%" }}
          variant="outlined"
        />
      </Box>
      <Box
        sx={{
          paddingBottom: "15px",
          paddingTop: "5px",
          display: "flex",
          gap: "5px",
          flexDirection: "column",
        }}
      >
        <Typography color="rgba(79, 79, 79, 1)">
          Description<span style={{ color: "rgba(255,20,20)" }}>*</span>
        </Typography>
        <TextField
          autoComplete="off"
          onChange={(e) => setTabDesc(e.target.value)}
          value={tabDesc}
          placeholder="Enter description..."
          size="small"
          sx={{ width: "100%" }}
          variant="outlined"
        />
      </Box>
      <Box
        sx={{
          paddingBottom: "15px",
          paddingTop: "5px",
          display: "flex",
          gap: "5px",
          flexDirection: "column",
        }}
      >
        <Typography color="rgba(79, 79, 79, 1)">
          Owner<span style={{ color: "rgba(255,20,20)" }}>*</span>
        </Typography>
        <Box
          sx={{
            backgroundColor:'#F7F7F7',
            padding:'8px 16px',
            borderRadius:'4px',
            border:'1px solid rgba(47,47,47,0.4)',
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
            cursor:'pointer',
            '&:hover':{
              border:'1px solid rgba(47,47,47,0.6)',
            }
          }}
        >
          <Box
            sx={{
              display:'flex',
              alignItems:'center',
              gap:'10px'
            }}
          >
            <img src={ProfilePicture} height='25px'/>
            <Typography fontSize='17px'>Alex</Typography>
          </Box>
          <IconButton
            sx={{m:0, p:0}}
          >
            <ArrowDropDown sx={{fill:'#24252A'}}/>
          </IconButton>
        </Box>
      </Box>
      <NextSection handleNext={handleNext} idx={1}/>
    </Box>
  )
}
 

const DataTab = ({}) => {

  const ResourceSelector = ({selectedResources, resourceType}) => {
    const [show, setShow] = useState(false); 

    return (
      <Box>
        <Box
          sx={{
            border:'1px solid rgba(47,47,47,0.4)',
            '&:hover':{
              border:'1px solid rgba(47,47,47,0.8)',
              boxShadow:1
            },
            borderRadius:'4px',
            minHeight:'30px',
            display:'flex',
            flexWrap:'wrap',
            alignItems:'center',
            padding:'5px',
            gap:'5px'
          }}
          onClick={resourceType === 'sensor' ? handleSensorImportModal : resourceType === 'event' ? handleEventImportModal : handleDeploymentImportModal}
        >
            {
              selectedResources.length > 0 ?
              selectedResources.map((resource) => (
                resourceType === 'sensor' ?
                <SensorTag sensor={resource}/> :
                resourceType === 'event' ?
                <EventTag event={resource}/>
                : <DeploymentTag deployment={resource}/>
              ))
              :
              <Typography sx={{padding:'8px 16px'}} fontSize='15px' color='rgba(47,47,47,0.6)'>
                Add {resourceType}s
              </Typography>
            }
        </Box>
        {
          (
            (resourceType === 'sensor' && sensorModalOpen) ?
            <SensorSelectionModal selectedTab={'Sensors'} handleSensorSelection={handleSensorSelection} deploymentList={deploymentList} eventsList={eventsList} sensorList={sensorList} handleImportModal={handleSensorImportModal} />
            : (resourceType === 'event' && eventModalOpen) ?
            <SensorSelectionModal selectedTab={'Events'} handleSensorSelection={handleSensorSelection} deploymentList={deploymentList} eventsList={eventsList} sensorList={sensorList} handleImportModal={handleEventImportModal} />
            : (resourceType === 'deployment' && deploymentModalOpen) ?
            <SensorSelectionModal selectedTab={'Deployments'} handleSensorSelection={handleSensorSelection} deploymentList={deploymentList} eventsList={eventsList} sensorList={sensorList} handleImportModal={handleDeploymentImportModal} />
            : <Box></Box>
          )

        }
      </Box>
    )
  }

  return (
    <Box
      sx={{
        padding:'24px',
        pt:'0px',
        display:'flex',
        gap:'20px',
        flexDirection:'column'
      }}
    >
      <Box
        sx={{
          display:'flex',
          gap:'12px',
          flexDirection:'column'
        }}
      >
        <Typography color='#24252A' fontWeight={550} variant='h3' fontSize='26px'>Configure Monitor data</Typography>
        <Typography color='#24252A' fontWeight={500} variant='h3' fontSize='16px'>A Monitor allows real time optimization and monitoring of configured resources</Typography>
      </Box>
      <Box
        sx={{
          paddingBottom: "15px",
          paddingTop: "5px",
          display: "flex",
          gap: "5px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center'
          }}
        >
          <Typography color="rgba(79, 79, 79, 1)">
            Sensors to monitor
          </Typography>
          {
            (reccomendedSensors.length > 0) &&
            <Button
              sx={{
                backgroundColor: '#4643D4',
                color: "white",
                textTransform: "none",
                "&:hover": {
                  boxShadow:1 // Hover background color
                },
              }}
              variant="contained"
              onClick={handleImportReccomendedSensors}
            >
              Import {reccomendedSensors.length} Reccomended Sensor(s)
            </Button>
          }
        </Box>
        <ResourceSelector
          selectedResources={selectedSensors}
          resourceType={'sensor'}
        />
      </Box>
      <Box
        sx={{
          paddingBottom: "15px",
          paddingTop: "5px",
          display: "flex",
          gap: "5px",
          flexDirection: "column",
        }}
      >
        <Typography color="rgba(79, 79, 79, 1)">
          Events
        </Typography>
        <ResourceSelector
          selectedResources={selectedEvents}
          resourceType={'event'}
        />
      </Box>
      <Box
        sx={{
          paddingBottom: "15px",
          paddingTop: "5px",
          display: "flex",
          gap: "5px",
          flexDirection: "column",
        }}
      >
        <Typography color="rgba(79, 79, 79, 1)">
          Deployments
        </Typography>
        <ResourceSelector
          selectedResources={selectedDeployments}
          resourceType={'deployment'}
        />
      </Box>
      <NextSection handleNext={() => setTab((prev) => (prev + 1))} idx={2}/>
    </Box>
  )
}

const DocumentTab = ({}) => {
  return (
    <Box
      sx={{
        padding:'24px',
        pt:'0px',
        display:'flex',
        gap:'20px',
        flexDirection:'column'
      }}
    >
      <Box
        sx={{
          display:'flex',
          gap:'12px',
          flexDirection:'column'
        }}
      >
        <Typography color='#24252A' fontWeight={550} variant='h3' fontSize='26px'>Create a new Monitor</Typography>
        <Typography color='#24252A' fontWeight={500} variant='h3' fontSize='16px'>A Monitor allows real time optimization and monitoring of configured resources</Typography>
      </Box>
      <Box
        sx={{
          paddingBottom: "15px",
          paddingTop: "25px",
          display: "flex",
          gap: "5px",
          flexDirection: "column",
        }}
      >
        <FileUpload file={file} setFile={setFile}/>
      </Box>
      <NextSection idx={3}/>
    </Box>
  )
}




const ProgressBox = ({idx}) => {
  return (
    <Box
      sx={{
        height:'6px',
        width:'80px',
        borderRadius:'8px',
        backgroundColor: tab >= idx ? '#4643D4' : '#D9D9D9'
      }}
    />
  )
}

  return (
    <Modal
      open={open}
      onClose={() => handleModalState(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "800px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: 24,
          maxHeight:'90vh',
          overflow:'scroll'
        }}
      >
        <Box
          sx={{
            display:'flex',
            padding:'24px',
            justifyContent:'space-between',
          }}
        >
          <Box
            sx={{
              display:'flex',
              gap:'5px'
            }}
          >
            {
              [1,2,3].map((val) => (
                <ProgressBox idx={val}/>
              ))
            }
          </Box>
          <IconButton
            sx={{
              margin: 0,
              padding: 0,
              transform:'translateY(-25%)'
            }}
          >
            <CancelIcon onClick={() => handleModalState(false)} />
          </IconButton>
        </Box>
        {
          tab === 1 ? <DetailsTab handleNameChange={handleNameChange}/>
          : tab === 2 ? <DataTab/>
          : <DocumentTab/>
        }
      </Box>
    </Modal>
  );
}
