import {
  Alert,
  AlertTitle,
  IconButton,
  Divider,
  Typography,
  FormControl,
  Select,
  MenuItem, 
  Switch,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import { Modal } from '@mui/base';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DeleteIcon from '@mui/icons-material/Delete';

import SensorsIcon from '@mui/icons-material/Sensors';
import EventIcon from '@mui/icons-material/Event';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

const COLORS = [
  'rgba(39, 174, 96, 1)',
  'rgba(41, 128, 185, 1)',
  'rgba(231, 76, 60, 1)',
  'rgba(243, 156, 18, 1)',
  'rgba(142, 68, 173, 1)',
  'rgba(26, 188, 156, 1)',
  'rgba(241, 196, 15, 1)',
  'rgba(52, 152, 219, 1)',
  'rgba(211, 84, 0, 1)',
  'rgba(127, 140, 141, 1)',
  'rgba(255, 99, 132, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(255, 159, 64, 0.5)',
  'rgba(244, 67, 54, 0.5)',
  'rgba(33, 150, 243, 0.5)',
  'rgba(76, 175, 80, 0.5)',
  'rgba(156, 39, 176, 0.5)',
  'rgba(63, 81, 181, 0.5)',
  'rgba(255, 87, 34, 0.5)',
  'rgba(205, 220, 57, 0.5)',
  'rgba(121, 85, 72, 0.5)',
  'rgba(96, 125, 139, 0.5)',
  'rgba(255, 235, 59, 0.5)',
  'rgba(139, 195, 74, 0.5)',
  'rgba(233, 30, 99, 0.5)',
  'rgba(103, 58, 183, 0.5)',
  'rgba(0, 150, 136, 0.5)',
]

export default function VariableCard({
  config,
  idx,
  model,
  trainingSet,
  setTrainingSet,
  visibility,
  setVisibility,
  handleDelete,
  sensorColors
}){
  const idCol = `${config['data_type']}_id`
  const handleInputCreate = (sensorId, trainingSet, setTrainingSet) => {
    var sensorImports = [];
    trainingSet['sensor_config'].forEach((sensorImport) => {
        if(sensorImport[idCol] == sensorId){
          sensorImport['variable_type'] = 'input';
        }
        sensorImports.push(sensorImport);
    })
    var config = trainingSet;
    config['sensor_config'] = sensorImports;
    setTrainingSet({...config});
  };

  const handleTargetCreate = (sensorId, trainingSet, setTrainingSet) => {
      var sensorImports = [];
      trainingSet['sensor_config'].forEach((sensorImport) => {
          if(sensorImport[idCol] == sensorId){
            sensorImport['variable_type'] = 'target';
          }else if(sensorImport['variable_type'] && sensorImport['variable_type'] == 'target'){
            sensorImport['variable_type'] = null;
          }
          sensorImports.push(sensorImport);
      })
      var config = trainingSet;
      config['sensor_config'] = sensorImports;
      setTrainingSet({...config});
  };

  const handleOptimizationVariableType = (sensorId, sensorType, trainingSet, setTrainingSet) => {
      var sensorConfig = trainingSet['sensor_config'];
      var newConfig = [];
      sensorConfig.forEach((sensor) => {
          if(sensor[idCol] === sensorId){
          sensor['sensor_type'] = sensorType;
          newConfig.push(sensor);
          }else{
          newConfig.push(sensor);
          }
      })
      var config = {...trainingSet};
      config['sensor_config'] = newConfig;
      setTrainingSet({...config});
  };

  const handleToggleVisibility = (sensor, status, visibility, setVisibility) => {
      var _visibility = {...visibility};
      const idCol = `${sensor['data_type']}_id`
      _visibility[`${sensor['data_type']}_${sensor[idCol]}`] = !_visibility[`${sensor['data_type']}_${sensor[idCol]}`];
      setVisibility({..._visibility});
  };


  const handleNullStrategy = (sensorId, nullStrategy, trainingSet, setTrainingSet) => {
    var sensorConfig = trainingSet['sensor_config'];
    var newConfig = [];
    sensorConfig.forEach((sensor) => {
        if(sensor[idCol] === sensorId){
          sensor['null_strategy'] = nullStrategy;
          sensor['fill_value'] = null;
          sensor['interpolation_strategy'] = null;
          newConfig.push(sensor);
        }else{
          newConfig.push(sensor);
        }
    })
    var config = {...trainingSet};
    config['sensor_config'] = newConfig;
    setTrainingSet({...config});
};

const handleFillValue = (sensorId, fillValue, trainingSet, setTrainingSet) => {
  var sensorConfig = trainingSet['sensor_config'];
  var newConfig = [];
  sensorConfig.forEach((sensor) => {
      if(sensor[idCol] === sensorId){
        sensor['fill_value'] = fillValue;
        newConfig.push(sensor);
      }else{
        newConfig.push(sensor);
      }
  })
  var config = {...trainingSet};
  config['sensor_config'] = newConfig;
  setTrainingSet({...config});
};

const handleInterpolationStrategy = (sensorId, interpolationStrategy, trainingSet, setTrainingSet) => {
  var sensorConfig = trainingSet['sensor_config'];
  var newConfig = [];
  sensorConfig.forEach((sensor) => {
      if(sensor.sensor_id === sensorId){
        sensor['interpolation_strategy'] = interpolationStrategy;
        newConfig.push(sensor);
      }else{
        newConfig.push(sensor);
      }
  })
  var config = {...trainingSet};
  config['sensor_config'] = newConfig;
  setTrainingSet({...config});
};

    return (
    <Box
      sx={{
        padding:'20px',
        backgroundColor:'white',
        borderRadius:'8px',
        border:'1px solid rgba(0, 0, 0, 0.15)',
        boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.07)',
        // maxWidth:'440px'
      }}
    >
      <Box
      sx={{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
      }}
      >
      <Box
        sx={{
          display:'flex',
          alignItems:'center',
          gap:'10px'
        }}
      >
        <Box
          sx={{
            // width:'20px',
            // height:'20px',
            borderRadius:'5px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            padding:'5px',
            backgroundColor:sensorColors[`${config.data_type}_${config[config.data_type + '_id']}`]
          }}
          >
            {
              config.data_type === 'sensor' ? <SensorsIcon sx={{height:'20px', fill:'white'}}/> :
              config.data_type === 'event' ? <EventIcon sx={{height:'20px', fill:'white'}}/> :
              <RocketLaunchIcon sx={{height:'20px', fill:'white'}}/> 
            }

          </Box>
          <Box
            sx={{
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
              margin:'10px',
              wordBreak:'break-word'
            }}
          >
            {
              config.data_type === 'sensor' ? (
                <Typography fontSize='0.9rem' sx={{p:0, m:0}} variant='overline'>{config['name']} ({config['tag_id']})</Typography>
              ) :
              <Typography fontSize='0.9rem' sx={{p:0, m:0}} variant='overline'>{config['name']}</Typography>
            }
            <Typography fontSize='0.8rem' sx={{p:0, m:0}}  variant='body1'>{config['description']}</Typography>
          </Box>
      </Box>
      <Box
        sx={{
          display:'flex',
          alignItems:'center',
          gap:'18px'
        }}
      >
        {
          visibility[`${config['data_type']}_${config[idCol]}`] &&
          <IconButton onClick={() => handleToggleVisibility(config, false, visibility, setVisibility)} sx={{m:0,p:0}}>
              <VisibilityIcon/>  
          </IconButton>
        }
        {
          !visibility[`${config['data_type']}_${config[idCol]}`] &&
          <IconButton onClick={() => handleToggleVisibility(config, true, visibility, setVisibility)} sx={{m:0,p:0}}>
              <VisibilityOffIcon/>
          </IconButton>
        }
        <IconButton onClick={() => handleDelete(config)} sx={{m:0, p:0}}>
          <DeleteIcon/>
        </IconButton>
      </Box>

      </Box>
      <Box
        sx={{
          border:'1px solid rgba(0, 0, 0, 0.15)',
          display:'flex',
          justifyContent:'space-between',
          alignItems:'center',
          marginTop:'10px',
          height:'40px',
        }}
      >
        <Box
          sx={{
            display:'flex',
            alignItems:'center',
            width:'50%',
            padding:'10px',
            gap:'10px',
            '&:hover': {
              backgroundColor: 'rgba(247, 247, 247, 0.5)',
            },
          }}
          onClick={() => handleInputCreate(config[idCol], trainingSet, setTrainingSet)}
        >
          {
            config.variable_type === 'input' &&
            <RadioButtonCheckedIcon sx={{fill:'rgba(70, 67, 212, 1)'}}/>
          }
          {
            config.variable_type !== 'input' &&
            <RadioButtonUncheckedIcon sx={{fill:'rgba(0, 0, 0, 0.25)'}}/>
          }
          <Typography fontSize='0.9rem' color={config.variable_type === 'input' ? 'rgba(70, 67, 212, 1)' : 'black'}>Input</Typography>
        </Box>
        <Divider flexItem orientation='vertical'/>
        <Box
          sx={{
            display:'flex',
            alignItems:'center',
            width:'50%',
            padding:'10px',
            gap:'10px',
            '&:hover': {
              backgroundColor: 'rgba(247, 247, 247, 0.5)',
            },
          }}
          onClick={() => handleTargetCreate(config[idCol], trainingSet, setTrainingSet)}
        >
          {
            config.variable_type === 'target' &&
            <RadioButtonCheckedIcon sx={{fill:'rgba(70, 67, 212, 1)'}}/>
          }
          {
            config.variable_type !== 'target' &&
            <RadioButtonUncheckedIcon sx={{fill:'rgba(0, 0, 0, 0.25)'}}/>
          }
          <Typography fontSize='0.9rem' color={config.variable_type === 'target' ? 'rgba(70, 67, 212, 1)' : 'black'}>Target</Typography>
        </Box>
      </Box>
      {
        (config.variable_type === 'input' && config.data_type === 'sensor') &&
        <Box
          sx={{
            borderLeft:'1px solid rgba(0, 0, 0, 0.15)',
            alignItems:'center',
            marginTop:'15px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '5px',
          }}
        >
          <Typography paddingBottom='5px' fontSize='0.72rem' fontWeight='bold' >Null Value Strategy</Typography>
          <FormControl sx={{width:'100%'}} size='small'>
            <Select
              value={config['null_strategy']}
              onChange={(event) => handleNullStrategy(config[idCol], event.target.value, trainingSet, setTrainingSet)}
            >
              <MenuItem value={'fill'}>Fill</MenuItem>
              <MenuItem value={'interpolate'}>Interpolate</MenuItem>
            </Select>
          </FormControl>
        </Box>
      }
      {
        (config.variable_type === 'input' && config.null_strategy === 'interpolate' && config.data_type === 'sensor') &&
        <Box
          sx={{
            borderLeft:'1px solid rgba(0, 0, 0, 0.15)',
            alignItems:'center',
            marginTop:'15px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '5px',
          }}
        >
          <Typography paddingBottom='5px' fontSize='0.72rem' fontWeight='bold' >Interpolation Strategy</Typography>
          <FormControl sx={{width:'100%'}} size='small'>
            <Select
              defaultValue={'linear'}
              value={config['interpolation_strategy']}
              onChange={(event) => handleInterpolationStrategy(config[idCol], event.target.value, trainingSet, setTrainingSet)}
            >
              <MenuItem value={'cubic'}>Cubic</MenuItem>
              <MenuItem value={'linear'}>Linear</MenuItem>
              <MenuItem value={'nearest'}>Nearest</MenuItem>
              <MenuItem value={'quadratic'}>Quadratic</MenuItem>
              <MenuItem value={'zero'}>Step</MenuItem>
              <MenuItem value={'time'}>Time</MenuItem>
            </Select>
          </FormControl>
        </Box>
      }
      {
        (config.variable_type === 'input' && config.null_strategy === 'fill' && config.data_type === 'sensor') &&
        <Box
          sx={{
            borderLeft:'1px solid rgba(0, 0, 0, 0.15)',
            alignItems:'center',
            marginTop:'15px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '5px',
          }}
        >
          <Typography paddingBottom='5px' fontSize='0.72rem' fontWeight='bold' >Fill Value</Typography>
          <TextField type='number' value={config.fill_value} onChange={(event) => handleFillValue(config[idCol], event.target.value, trainingSet, setTrainingSet)}/>
        </Box>
      }
      {
        ((model.model_objective === 'Optimization') && (config.variable_type === 'input')) &&
        <Box
          sx={{
            borderLeft:'1px solid rgba(0, 0, 0, 0.15)',
            alignItems:'center',
            marginTop:'15px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '5px',
          }}
        >
          <Typography paddingBottom='5px' fontSize='0.72rem' fontWeight='bold' >Sensor Type</Typography>
          <FormControl sx={{width:'100%'}} size='small'>
            <Select
              defaultValue={'control'}
              value={config.sensor_type}
              onChange={(event) => handleOptimizationVariableType(config[idCol], event.target.value, trainingSet, setTrainingSet)}
            >
              <MenuItem value={'control'}>Control</MenuItem>
              <MenuItem value={'measured_value'}>Measured Value</MenuItem>
            </Select>
          </FormControl>
        </Box>
      }
    </Box>
    )
  }