import React, { useState } from 'react';
import { Box, IconButton, Typography } from "@mui/material";
import { Document, Page, pdfjs } from 'react-pdf';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PageSelector from './page-selector'
import SearchIcon from "@mui/icons-material/Search";
// import ManualPDF from '../assets/maintenance-manual.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;



const PDFViewer = ({blobUrl, fileName, inputPageNumber}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(inputPageNumber ? inputPageNumber : 1);

  // When a PDF is loaded, update the number of pages
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = (error) => {
    console.log(error); // Set the error message when the PDF fails to load
  };



  return (
      <Box
        sx={{
          display:'flex',
          width:'100%',
          justifyContent:'center',
          flexDirection:'column',
          alignItems:'center',
          marginLeft:'20px',
          gap:'20px'
        }}
      >
        <Box
            sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between',
                backgroundColor:'white',
                boxShadow:2,
                width:'100%',
                padding:'10px'
            }}
        >
          <Typography fontSize='13.5px' fontWeight='bold'>{fileName}</Typography>
          <Box
              sx={{
                  padding:'15px',
                  justifyContent:'space-between',
                  alignItems:'center',
                  display:'flex',
                  cursor:'pointer',
                  backgroundColor:'white',
                  gap:'20px'
              }}
          >
              <Box
                  sx={{
                      display:'flex',
                      gap:'5px',
                      width:'200px',
                      height:'35px',
                      border:'1px solid rgba(0,0,0,0.2)',
                      alignItems:'center',
                      borderRadius:'8px',
                  }}
              >
                  <SearchIcon sx={{ml:'10px', fill: '#828282'}}/>
                  <Typography fontSize='15px' color='#828282'>Search document</Typography>
              </Box>
              <PageSelector pageNumber={pageNumber} setPageNumber={setPageNumber}/>
          </Box>
        </Box>
        <Box
            sx={{
                maxHeight:'75vh',
                width:'100%',
                overflow:'scroll',
                display:'flex',
                flexDirection:'column',
                gap:'10px',
                border:'1px solid lightgrey',
                alignItems:'center',
                position:'relative'
            }}
        >
          <Document
              file={blobUrl} // PDF file path or URL
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
          >
              <Page pageNumber={pageNumber} renderTextLayer={false} />
          </Document>
          {
            inputPageNumber === 4 &&
            <Box sx={{
              top:'120px',
              width: '500px', // Adjust the width of the box
              height: '335px', // Adjust the height of the box
              border: '1px solid rgb(75, 64, 221)',
              backgroundColor:'rgba(75, 64, 221, 0.2)',
              position:'absolute'
            }}/>
          }
        </Box>
      </Box>
  );
};

export default PDFViewer;