import { useState, useEffect } from "react";

import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material"

import SensorsIcon from "@mui/icons-material/Sensors"
import EventIcon from '@mui/icons-material/Event';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import AddIcon from "@mui/icons-material/Add"
import SearchIcon from "@mui/icons-material/Search"
import FilterButton from "./filter-button";

import { COLORS } from '../../constants'

import SensorCard from "./sensor-card";
import EventCard from "./event-card";
import DeploymentCard from "./deployment-card";
import TSGraph from "./ts-graph";


export default function MonitorWorkspace({visibility, setVisibility, sendMaxeHiddenQuery, maxeShrunk, openMaxe, monitorData, monitor, startDate, endDate}){
    const handleTabClick = (name) => {
        if(name === tab){
            setOpenDataSection((prev) => (!prev));
        }else{
            setOpenDataSection(true);
            setTab(name);
        }
    }

    const TabButton = ({name}) => {
        const icon = name === 'Sensors' ? <SensorsIcon/> : name === 'Events' ? <EventIcon/> : <RocketLaunchIcon/>;
        return (
            <Tooltip title={name} placement="right">
                <Box
                    sx={{
                        padding:'5px',
                        borderRadius:'5px',
                        '&:hover':{
                            backgroundColor:'rgba(55,70,91,0.2)'
                        },
                        backgroundColor: tab === name ? 'rgba(55,70,91,0.1)' : 'white'
                    }}
                    onClick={() => handleTabClick(name)}
                >
                    {icon}
                </Box>
            </Tooltip>
        )
    }

    const [tab, setTab] = useState('Sensors')
    const [openDataSection, setOpenDataSection] = useState(false);

    const [graphWidth, setGraphWidth] = useState('70vw');
    useEffect(() => {
        var diff = 10;
        if(openMaxe && maxeShrunk){
            diff += 300;
        }else if(openMaxe && !maxeShrunk){
            diff += 500
        }
        if(openDataSection){
            diff += 240
        }
        setGraphWidth(`calc(92vw - ${diff}px)`)
    }, [maxeShrunk, openMaxe, openDataSection])

    return (
        <Box
            sx={{
                display:'flex',
                width:'100%',
                height:'100%',
                overflowX:'hidden'
            }}
        >
            <Box
                sx={{
                    backgroundColor:'white',
                    display:'flex',
                    flexDirection:'column',
                    gap:'5px',
                    padding:'5px',
                }}
            >
                {
                    ['Sensors', 'Events', 'Deployments'].map((tabName) => (
                        <TabButton name={tabName}/>
                    ))
                }
            </Box>
            <Box
                sx={{
                    backgroundColor:'#F7F7F7',
                    display:'flex',
                    flexDirection:'column',
                    gap:'5px',
                    border:'1px solid rgba(0,0,0,0.15)'
                }}
            >
                {
                    openDataSection &&
                    <Box>
                    <Box
                        sx={{
                            padding:'16px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'space-between',
                            backgroundColor:'white'
                        }}
                    >
                        <Typography fontSize='15px' fontWeight='bold'>{tab}</Typography>
                        <IconButton sx={{m:0, p:0}}>
                            <AddIcon sx={{fill:'#4643D4'}}/>
                        </IconButton>
                    </Box>
                    <Divider/>
                    <Box
                        sx={{
                            padding:'16px',
                            justifyContent:'space-between',
                            alignItems:'center',
                            display:'flex',
                            cursor:'pointer',
                            backgroundColor:'white'
                        }}
                    >
                        <Box
                            sx={{
                                display:'flex',
                                gap:'5px',
                            }}
                        >
                            <SearchIcon sx={{fill: '#828282'}}/>
                            <Typography fontSize='15px' color='#828282'>Search...</Typography>
                        </Box>
                        <FilterButton/>
                    </Box>
                    <Divider/>
                    <Box
                        sx={{
                            backgroundColor:'#F7F7F7',
                            display:'flex',
                            flexDirection:'column',
                            gap:'5px',
                            padding:'5px',
                            maxHeight:'75vh',
                            overflow:'scroll'
                        }}
                    >
                        {
                            tab === 'Sensors' ?
                            monitor.sensors.map((sensor, idx) => (
                                <SensorCard sendMaxeHiddenQuery={sendMaxeHiddenQuery} sensor={sensor} color={COLORS[idx]} visibility={visibility} setVisibility={setVisibility}/>
                            )) :
                            tab === 'Events' ?
                            monitor.events.map((event, idx) => (
                                <EventCard sendMaxeHiddenQuery={sendMaxeHiddenQuery} event={event} color={COLORS[monitor.sensors.length + idx]} visibility={visibility} setVisibility={setVisibility}/>
                            )) :
                            monitor.deployments.map((deployment, idx) => (
                                <DeploymentCard sendMaxeHiddenQuery={sendMaxeHiddenQuery} deployment={deployment} color={COLORS[monitor.sensors.length + monitor.events.length + idx]} visibility={visibility} setVisibility={setVisibility}/>
                            ))
                        }
                    </Box>
                    </Box>
                }
            </Box>
            <Box
                sx={{
                    maxWidth:'100%',
                    padding:'5px',
                    // width: (openDataSection && maxeShrunk) ? 'calc(70vw - 220px)' : (openDataSection && !maxeShrunk) ? 'calc(70vw - 220px)' : '70vw'
                    width: graphWidth
                }}
            >
                <TSGraph 
                    sensors={monitor.sensors}
                    sensorData={monitorData.sensors}
                    events={monitor.events}
                    eventData={monitorData.events}
                    deployments={monitor.deployments}
                    deploymentData={monitorData.deployments}
                    startDate={startDate}
                    endDate={endDate}
                    visibility={visibility}
                />
            </Box>
        </Box>
    )
}