export const STEP_MAP = {
    'start': 0,
    'init': 0,
    'analyze': 1,
    'arch-tune': 2,
    'train': 3,
    'fine-tune': 4,
    'complete': 5, 
    'success': 5,
    'failure': 5,
};