import {
    Alert,
    AlertTitle,
    Box,
    IconButton,
    Divider,
    Typography,
    TextField,
    Button,
    TableRow,
    TableCell,
} from "@mui/material";

import { COLORS } from "../constants";

import { Visibility as VisibilityIcon, VisibilityOff } from '@mui/icons-material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InsightsIcon from '@mui/icons-material/Insights';
import { useEffect, useState } from "react";

import SensorsIcon from '@mui/icons-material/Sensors';
import EventIcon from '@mui/icons-material/Event';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import WarningIcon from '@mui/icons-material/Warning';
import FavoriteIcon from '@mui/icons-material/Favorite';


export default function VariableRow({warnings, dataColors, visibility, setVisibility, config, idx, resourceImportance, handleInsightsButton }){
    const dataType = config['data_type'];
    const idCol = `${dataType}_id`;
    const key = `${dataType}_${config[idCol]}`;

    const color = dataColors[key];

    const importance = (resourceImportance ? resourceImportance[key] : 0) * 100;

    const handleVisibility = () => {
      var _visibility = {...visibility};
      _visibility[key] = !_visibility[key];
      setVisibility({..._visibility});
    }

    const [visibile, setVisibile] = useState(false);
    useEffect(() => {
      const _visibile = visibility[key];
      setVisibile(_visibile);
      console.log('hi');
    }, [visibility])

    return (
      <TableRow
        sx={{
          width:'100%'
        }}
      >
        <TableCell>
            <IconButton onClick={handleVisibility} sx={{ m: 0, p: 0 }}>
              {
                visibile ? 
                <VisibilityIcon/> :
                <VisibilityOffIcon/> 
              }
            </IconButton>
        </TableCell>
        <TableCell>
        <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
          <Box
            sx={{
              // width:'20px',
              // height:'20px',
              borderRadius:'5px',
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              padding:'2px',
              backgroundColor:color
            }}
            >
              {
                config.data_type === 'sensor' ? <SensorsIcon sx={{height:'20px', fill:'white'}}/> :
                config.data_type === 'event' ? <EventIcon sx={{height:'20px', fill:'white'}}/> :
                <RocketLaunchIcon sx={{height:'20px', fill:'white'}}/> 
              }

            </Box>
            <Box>
              {
                config.data_type === 'sensor' ? 
                <Typography                
                  variant="overline"
                  color={"black"}
                >
                  {config.name} ({config.tag_id})
                </Typography> :
                <Typography                
                  fontSize='12px'
                  color={"black"}
                >
                  {config.name}
                </Typography>
              }
              <Typography
                fontSize='12px'
                color={"black"}
              >
                {config.description}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{display:'flex', alignItems:'center', gap:'15px'}}>
            <IconButton onClick={() => handleInsightsButton(config)} sx={{ m: 0, p: 0 }}>
              {
                (warnings && (warnings[key].length > 0)) ?
                <WarningIcon sx={{fill:'orange'}}/> :
                <FavoriteIcon sx={{fill:'green'}} />
              }
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    );
  };