import Box from "@mui/material/Box";
import {
  Grid,
  Divider,
  Typography,
  Button,
  LinearProgress,
  TextField,
  ListItemButton,
  circularProgressClasses,
} from "@mui/material";
import {
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";

import CustomCheckBox from "../custom-check-box";

export function HeaderCell ({ col }) {
    return (
        <TableCell
        sx={{
            padding: 0,
        }}
        >
        <Typography fontSize="0.8rem" variant="body1">
            {col}
        </Typography>
        </TableCell>
    );
};

export function EventRow({ event, selectEvent, selectedEvent }){
    var isSelected = false;
    if (event.event_id == selectedEvent.event_id) {
      isSelected = true;
    }

    var eventColor = "black";

    return (
      <TableRow
        sx={{
          "&:hover": {
            cursor: "pointer",
            backgroundColor: isSelected
              ? "rgba(70, 67, 212, 0.08)"
              : "rgba(134, 134, 134, 0.08)",
          },
          backgroundColor: isSelected ? "rgba(70, 67, 212, 0.08)" : "white",
        }}
      >
        <TableCell
          sx={{
            p: '10px',
            paddingTop: "16px",
            paddingBottom: "16px",
          }}
        >
            <CustomCheckBox sx={{p:0, m:0}} />
        </TableCell>
        <TableCell
          sx={{
            p: 0,
            paddingTop: "16px",
            paddingBottom: "16px",
            maxWidth:'200px',
          }}
          onClick={() => selectEvent(event)}
        >
            <Typography
              variant="body1"
              lineHeight="20px"
              fontSize="14px"
              fontWeight="600"
            >
              {event.name}
            </Typography>
        </TableCell>
        <TableCell
          onClick={() => selectEvent(event)}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              maxWidth:'200px',
            }}
          >
            <Typography
              variant="body1"
              lineHeight="20px"
              fontSize="14px"
              fontWeight="400"
            >
              {event.description}
            </Typography>
          </Box>
        </TableCell>
        <TableCell sx={{ p: 0 }} onClick={() => selectEvent(event)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0px",
              maxWidth:'150px',
              overflow:'wrap',
              wordWrap: 'break-word',
            }}
          >
            <Typography fontSize="14px">{event.source_type.charAt(0).toUpperCase() + event.source_type.slice(1)}</Typography>
          </Box>
        </TableCell>
      </TableRow>
    );
  };