import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import levenshtein from 'fast-levenshtein';

import { Box, Divider, Modal, Typography, IconButton } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import EastIcon from '@mui/icons-material/East';
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';

import SensorSelector from './sensor-selector';
import AltSensorTSGraph from './alt-sensor-graph';

export default function SensorReplacementModal({type, config, color, open, handleModalState, getAltSensor}) {
    const [selectedSensor, setSelectedSensor] = React.useState(null);

    const handleSave = (config, selectedSensor) => {
        getAltSensor(config, selectedSensor)
        handleModalState(false);
    }

    const VariableCard = () => {
        return (
            <Box
                sx={{
                    width:'120px',
                    height:'30px',
                    border:'1px solid lightgrey',
                    borderRadius:'8px',
                    paddingLeft:'20px',
                    display:'flex',
                    alignItems:'center',
                }}
            >
                <Box>
                    <Typography fontSize='1rem' color='grey' >{config.name}</Typography>
                </Box>
            </Box>
        )
    }

    const [similarity, setSimilarity] = React.useState(null);
    React.useEffect(() => {
        if(selectedSensor){
            const name = config.name;
            const selectedName = selectedSensor.name;
            const score = 1 - (levenshtein.get(name, selectedName) / Math.max(name.length, selectedName.length));
            setSimilarity(score.toFixed(3));
        }
    }, [selectedSensor])

    const getSelectedSensor = (sensor) => {
        setSelectedSensor(sensor);
    }

    return (
        <Modal
        open={open}
        onClose={() => handleModalState(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height:'500px',
                    width:'550px',
                    backgroundColor:'white',
                    borderRadius:'8px',
                    boxShadow:24,
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'space-between'
                    }}
                >
                    <Box>
                    <Box
                    sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        paddingLeft:'30px',
                        paddingRight:'30px',
                        paddingBottom:'15px',
                        paddingTop:'15px',
                    }}
                    >
                        <Typography fontWeight='bold'>Alternate {type} configuration</Typography>
                        <Box
                            sx={{
                                display:'flex',
                                gap:'10px'
                            }}
                        >
                            <IconButton
                                sx={{
                                    margin:0,
                                    padding:0
                                }}
                            >
                                <CancelIcon onClick={() => handleModalState(false)}/>
                            </IconButton>
                            <Divider flexItem orientation='vertical'/>
                            <IconButton
                                sx={{
                                    margin:0,
                                    padding:0
                                }}
                                disabled={!selectedSensor}
                            >
                                <SaveIcon onClick={() => handleSave(config, selectedSensor)}/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider/>
                    <Box
                        sx={{
                            display:'flex'
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    padding:'30px',
                                    paddingBottom:'0px',
                                    paddingTop:'20px',
                                    display:'flex',
                                    gap:'5px',
                                    flexDirection:'column'
                                }}
                            >
                                <Typography color='rgba(79, 79, 79, 1)'>Select alternate sensor</Typography>
                            </Box>
                            <Box
                                sx={{
                                    margin:'10px',
                                    padding:'10px',
                                    paddingTop:'0px',
                                    paddingBottom:'0px',
                                    paddingLeft:'20px',
                                    display:'flex',
                                    gap:'20px',
                                    flexDirection:'row',
                                    alignItems:'center',
                                }}
                            >
                                <VariableCard/>
                                <EastIcon sx={{fill:'grey'}}/>
                                <SensorSelector getSelectedSensor={getSelectedSensor}/>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                margin:'10px',
                                pr:'20px',
                                pl:'20px',
                                display:'flex',
                                alignItems:'center',
                                flexDirection:'column',
                                backgroundColor:'lightgrey',
                                borderRadius:'8px'
                            }}
                        >
                            <Typography variant='overline'>Similarity</Typography>
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress value={similarity*100} variant="determinate"/>
                            <Box
                                sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                }}
                            >
                                <Typography variant="caption" component="div" color="text.secondary">
                                {`${Math.round(100*similarity)}%`}
                                </Typography>
                            </Box>
                            </Box>
                        </Box>
                    </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        border:'1px solid lightgrey',
                        margin:'10px'
                    }}
                >
                    <AltSensorTSGraph color={color} sensor={config} altSensor={selectedSensor}/>
                </Box>
            </Box>
        </Modal>
    );
}
