import { useState, useEffect } from 'react';

const PATHS = {
    path1: {
        section1: {
            point1:{
                x: 0,
                y: 20
            },
            point2:{
                x:5,
                y:25
            },
            point3:{
                x:15,
                y:30
            }
        },
        section2: {
            point1:{
                x: 15,
                y: 30
            },
            point2:{
                x:25,
                y:35
            },
            point3:{
                x:35,
                y:30
            }
        },
        section3: {
            point1:{
                x: 35,
                y: 30
            },
            point2:{
                x:40,
                y:25
            },
            point3:{
                x:40,
                y:10
            }
        },
        section4: {
            point1:{
                x: 40,
                y: 10
            },
            point2:{
                x:35,
                y:5
            },
            point3:{
                x:25,
                y:0
            }
        },
        section5: {
            point1:{
                x: 25,
                y: 0
            },
            point2:{
                x:15,
                y:-5
            },
            point3:{
                x:0,
                y:0
            }
        },
    },
    path2: {
        section1: {
            point1:{
                x: 0,
                y: 20
            },
            point2:{
                x:5,
                y:25
            },
            point3:{
                x:15,
                y:30
            }
        },
        section2: {
            point1:{
                x: 15,
                y: 30
            },
            point2:{
                x:25,
                y:35
            },
            point3:{
                x:35,
                y:30
            }
        },
        section3: {
            point1:{
                x: 35,
                y: 30
            },
            point2:{
                x:40,
                y:25
            },
            point3:{
                x:40,
                y:10
            }
        },
        section4: {
            point1:{
                x: 40,
                y: 10
            },
            point2:{
                x:35,
                y:5
            },
            point3:{
                x:25,
                y:0
            }
        },
        section5: {
            point1:{
                x: 25,
                y: 0
            },
            point2:{
                x:15,
                y:-5
            },
            point3:{
                x:0,
                y:0
            }
        },
    },
    path3: {
        section1: {
            point1:{
                x: 0,
                y: 20
            },
            point2:{
                x:5,
                y:25
            },
            point3:{
                x:15,
                y:30
            }
        },
        section2: {
            point1:{
                x: 15,
                y: 30
            },
            point2:{
                x:25,
                y:35
            },
            point3:{
                x:35,
                y:30
            }
        },
        section3: {
            point1:{
                x: 35,
                y: 30
            },
            point2:{
                x:40,
                y:25
            },
            point3:{
                x:40,
                y:10
            }
        },
        section4: {
            point1:{
                x: 40,
                y: 10
            },
            point2:{
                x:35,
                y:5
            },
            point3:{
                x:25,
                y:0
            }
        },
        section5: {
            point1:{
                x: 25,
                y: 0
            },
            point2:{
                x:15,
                y:-5
            },
            point3:{
                x:0,
                y:0
            }
        },
    },
    path4: {
        section1: {
            point1:{
                x: 0,
                y: 20
            },
            point2:{
                x:5,
                y:25
            },
            point3:{
                x:15,
                y:30
            }
        },
        section2: {
            point1:{
                x: 15,
                y: 30
            },
            point2:{
                x:25,
                y:35
            },
            point3:{
                x:35,
                y:30
            }
        },
        section3: {
            point1:{
                x: 35,
                y: 30
            },
            point2:{
                x:40,
                y:25
            },
            point3:{
                x:40,
                y:10
            }
        },
        section4: {
            point1:{
                x: 40,
                y: 10
            },
            point2:{
                x:35,
                y:5
            },
            point3:{
                x:25,
                y:0
            }
        },
        section5: {
            point1:{
                x: 25,
                y: 0
            },
            point2:{
                x:15,
                y:-5
            },
            point3:{
                x:0,
                y:0
            }
        },
    },
}

export default function MaxeLogo({active}){
    const convertPathToString = (path) => {
        var pathString = `M ${path.section1.point1.x} ${path.section1.point1.y} C`;
        for(const section of Object.keys(path)){
            for(const point of Object.keys(path[section])){
                pathString = pathString + ` ${path[section][point].x} ${path[section][point].y}`;
            }
        }
        pathString = pathString + 'z';
        return pathString;
    }

    const [paths, setPaths] = useState({...PATHS})

    function getRandomInt(min, max) {
        min = Math.ceil(min); // Round up to the nearest integer
        max = Math.floor(max); // Round down to the nearest integer
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        if(active){
            const interval = setInterval(() => {
                var currPaths = {...PATHS};
                var directions = {...PATHS};
                for(const path of Object.keys(currPaths)){
                    for(const section of Object.keys(currPaths[path])){
                        for(const point of Object.keys(currPaths[path][section])){
                            if(point === 'point1'){
                                const direction = 'direction' in directions[path][section][point] ? directions[path][section][point].direction : 1;
                                currPaths[path][section][point].x += direction*getRandomInt(1,10);
                                currPaths[path][section][point].y += direction*getRandomInt(1,10);
                                directions[path][section][point]['direction'] = direction;
                                directions[path][section][point]['direction'] *= -1;
                            }
                        }
                    }
                }
                setPaths(currPaths);
            }, 250)
            return () => clearInterval(interval);
        }else{
            setPaths({
                path1: {
                    section1: {
                        point1:{
                            x: 0,
                            y: 20
                        },
                        point2:{
                            x:5,
                            y:25
                        },
                        point3:{
                            x:15,
                            y:30
                        }
                    },
                    section2: {
                        point1:{
                            x: 15,
                            y: 30
                        },
                        point2:{
                            x:25,
                            y:35
                        },
                        point3:{
                            x:35,
                            y:30
                        }
                    },
                    section3: {
                        point1:{
                            x: 35,
                            y: 30
                        },
                        point2:{
                            x:40,
                            y:25
                        },
                        point3:{
                            x:40,
                            y:10
                        }
                    },
                    section4: {
                        point1:{
                            x: 40,
                            y: 10
                        },
                        point2:{
                            x:35,
                            y:5
                        },
                        point3:{
                            x:25,
                            y:0
                        }
                    },
                    section5: {
                        point1:{
                            x: 25,
                            y: 0
                        },
                        point2:{
                            x:15,
                            y:-5
                        },
                        point3:{
                            x:0,
                            y:0
                        }
                    },
                },
                path2: {
                    section1: {
                        point1:{
                            x: 0,
                            y: 20
                        },
                        point2:{
                            x:5,
                            y:25
                        },
                        point3:{
                            x:15,
                            y:30
                        }
                    },
                    section2: {
                        point1:{
                            x: 15,
                            y: 30
                        },
                        point2:{
                            x:25,
                            y:35
                        },
                        point3:{
                            x:35,
                            y:30
                        }
                    },
                    section3: {
                        point1:{
                            x: 35,
                            y: 30
                        },
                        point2:{
                            x:40,
                            y:25
                        },
                        point3:{
                            x:40,
                            y:10
                        }
                    },
                    section4: {
                        point1:{
                            x: 40,
                            y: 10
                        },
                        point2:{
                            x:35,
                            y:5
                        },
                        point3:{
                            x:25,
                            y:0
                        }
                    },
                    section5: {
                        point1:{
                            x: 25,
                            y: 0
                        },
                        point2:{
                            x:15,
                            y:-5
                        },
                        point3:{
                            x:0,
                            y:0
                        }
                    },
                },
                path3: {
                    section1: {
                        point1:{
                            x: 0,
                            y: 20
                        },
                        point2:{
                            x:5,
                            y:25
                        },
                        point3:{
                            x:15,
                            y:30
                        }
                    },
                    section2: {
                        point1:{
                            x: 15,
                            y: 30
                        },
                        point2:{
                            x:25,
                            y:35
                        },
                        point3:{
                            x:35,
                            y:30
                        }
                    },
                    section3: {
                        point1:{
                            x: 35,
                            y: 30
                        },
                        point2:{
                            x:40,
                            y:25
                        },
                        point3:{
                            x:40,
                            y:10
                        }
                    },
                    section4: {
                        point1:{
                            x: 40,
                            y: 10
                        },
                        point2:{
                            x:35,
                            y:5
                        },
                        point3:{
                            x:25,
                            y:0
                        }
                    },
                    section5: {
                        point1:{
                            x: 25,
                            y: 0
                        },
                        point2:{
                            x:15,
                            y:-5
                        },
                        point3:{
                            x:0,
                            y:0
                        }
                    },
                },
                path4: {
                    section1: {
                        point1:{
                            x: 0,
                            y: 20
                        },
                        point2:{
                            x:5,
                            y:25
                        },
                        point3:{
                            x:15,
                            y:30
                        }
                    },
                    section2: {
                        point1:{
                            x: 15,
                            y: 30
                        },
                        point2:{
                            x:25,
                            y:35
                        },
                        point3:{
                            x:35,
                            y:30
                        }
                    },
                    section3: {
                        point1:{
                            x: 35,
                            y: 30
                        },
                        point2:{
                            x:40,
                            y:25
                        },
                        point3:{
                            x:40,
                            y:10
                        }
                    },
                    section4: {
                        point1:{
                            x: 40,
                            y: 10
                        },
                        point2:{
                            x:35,
                            y:5
                        },
                        point3:{
                            x:25,
                            y:0
                        }
                    },
                    section5: {
                        point1:{
                            x: 25,
                            y: 0
                        },
                        point2:{
                            x:15,
                            y:-5
                        },
                        point3:{
                            x:0,
                            y:0
                        }
                    },
                },
            });
        }
    }, [active])

    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
        >
            {/* Blob 1: White with opacity */}
            {/* <path
                d="M20 20c-18 -10 -18 -30 0 -33 18 -3 35 10 35 20 0 15 -18 26 -35 16z"
                fill="#FFFFFF"
                opacity="0.8"
            /> */}

            {/* Blob 2: Red with opacity */}
            {/* <path
                d="M20 20c-10 0 -22 -6 -18 -18 3 -12 18 -12 24 -6 10 7 14 19 -7 24z"
                fill="#FF1F00"
                opacity="0.8"
            /> */}

            <radialGradient id="gradient1" cx="50%" cy="50%" r="50%">
                <stop offset="0%" stopColor="rgba(255,255,255,0.5)" stopOpacity="0.4" />
                <stop offset="100%" stopColor="rgba(255,255,255,0.5)" stopOpacity="0.4" />
            </radialGradient>
            <path
                d={convertPathToString(paths.path1)}
                fill="url(#gradient1)"
                transform="rotate(-45, 20, 20)"
            />

            <radialGradient id="gradient3" cx="50%" cy="50%" r="50%">
                <stop offset="0%" stopColor="#80FFEE" stopOpacity="0.4" />
                <stop offset="100%" stopColor="#8090FF" stopOpacity="0.4" />
            </radialGradient>
            <path
                d={convertPathToString(paths.path2)}
                fill="url(#gradient3)"
                transform="rotate(135, 20, 20)"
            />

            <radialGradient id="gradient4" cx="50%" cy="50%" r="50%">
                <stop offset="0%" stopColor="#FFA57F" stopOpacity="0.4" />
                <stop offset="100%" stopColor="#A880FF" stopOpacity="0.4" />
            </radialGradient>
            <path
                d={convertPathToString(paths.path3)}
                fill="url(#gradient4)"
            />


            <radialGradient id="gradient2" cx="50%" cy="50%" r="50%">
                <stop offset="0%" stopColor="#FF1F00" stopOpacity="0.2" />
                <stop offset="100%" stopColor="#FF1F00" stopOpacity="0.2" />
            </radialGradient>
            <path
                d={convertPathToString(paths.path4)}
                fill="url(#gradient2)"
                transform="rotate(45, 20, 20)"
            />

        </svg>
      );
}
