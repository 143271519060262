import { Box, Divider, Typography } from "@mui/material"
import ShareIcon from "@mui/icons-material/Share";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import MonitorIcon from '../assets/monitor-icon.png'


export default function MonitorHeader({monitor, selectedTab, setInputPageNumber, setSelectedTab}){
    const Tab = ({name}) => {
        const handleClick = (name) => {
            setInputPageNumber(1);
            setSelectedTab(name)
        }
        const active = (name === selectedTab);
        return (
            <Box
                sx={{
                    padding:'10px 10px',
                    borderBottom: active ? '1px solid #4643D4' : 'none',
                    '&:hover':{
                        borderBottom:'1px solid #4643D4',
                        color: '#4643D4'
                    },
                    color: active ? '#4643D4' : 'black',
                    cursor:'pointer'
                }}
                onClick={() => handleClick(name)}
            >
                <Typography fontSize='13px'>
                    {name}
                </Typography>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                display:'flex',
                alignItems:'center',
                backgroundColor:'white',
                justifyContent:'space-between',
            }}
        >
            <Box
                sx={{
                    display:'flex',
                    alignItems:'center',
                    gap:'10px',
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        alignItems:'center',
                        gap:'10px',
                        pl:'10px'
                    }}
                >
                    <img src={MonitorIcon} height='20px'/>
                    <Typography fontSize='14.5px' fontWeight='bold'>{monitor.name}</Typography>
                </Box>
                <Divider flexItem orientation='vertical'/>
                <Box
                    sx={{
                        display:'flex',
                        alignItems:'center',
                        gap:'8px'
                    }}
                >
                    <Tab name={'Overview'}/>
                    <Tab name={'Workspace'}/>
                    <Tab name={'Documents'}/>
                </Box>
            </Box>
            <Box
                sx={{
                    display:'flex',
                    alignItems:'center',
                    gap:'10px',
                }}
            >
                <Divider flexItem orientation="vertical" />
                <ShareIcon sx={{padding:'8px'}} />
                <MoreHorizIcon sx={{padding:'8px'}} />
            </Box>
        </Box>
    )
}