import * as React from "react";
import Box from "@mui/material/Box";
import {
  Divider,
  Modal,
  Typography,
  Button,
  IconButton,
  TextField,
  Checkbox,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StormIcon from "@mui/icons-material/Storm";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";

import { useNavigate } from "react-router-dom";

export default function DeployModal({ open, setOpen, handleCreateDeployment }) {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "400px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: 24,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "24px",
                paddingRight: "24px",
                paddingBottom: "15px",
                paddingTop: "15px",
              }}
            >
              <Typography fontWeight="bold">Deploy model</Typography>
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                }}
              >
                <CancelIcon onClick={() => setOpen(false)} />
              </IconButton>
            </Box>
            <Divider />
            <Box
              sx={{
                padding:'20px',
                gap:'10px',
                display:'flex',
                flexDirection:'column'
              }}
            >
              <Typography>
                Deploying a model will run inference in real time as data is ingested. Deployments can be used as resources with monitors or other models.
              </Typography>
              <Typography variant='h7'>
                <span style={{fontWeight:'bold'}}>NOTE:</span> Running a model on live data will incur costs and require compute over time. Please aknowledge you have approval from your system administrator to create this deployment.
              </Typography>

            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            gap: "5px",
            padding: "20px 24px",
          }}
        >
          <Button
            sx={{
              textTransform: "none",
              borderColor: "rgba(55, 70, 91, 0.32)",
              color: "black",
              width: "50px",
              "&:hover": {
                borderColor: "rgba(55, 70, 91, 0.32)",
                backgroundColor: "rgba(55, 70, 91, 0.05)", // Slight background change on hover
              },
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "rgba(33, 150, 83, 1)",
              color: "white",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "rgba(33, 150, 83, 0.95)", // Hover background color
              },
            }}
            variant="contained"
            onClick={handleCreateDeployment}
          >
            Confirm deployment
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
