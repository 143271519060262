import {
    Alert,
    AlertTitle,
    Box,
    IconButton,
    Divider,
    Typography,
    TextField,
    Button,
} from "@mui/material";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import FindReplaceIcon from "@mui/icons-material/FindReplace";

import { COLORS } from "../constants";

export default function VariableCard({ config, idx, model, altSensorMap, resetAltSensor, handleModalState }){
  var name =
      model.model_type === "Targeted" ? config.name : config.name;
    var showReset = false;
    if (config.sensor_id in altSensorMap) {
      name = altSensorMap[config.sensor_id].name;
      showReset = true;
    }
    return (
      <Box
        sx={{
          margin: "8px",
          padding: "12px 16px",
          backgroundColor: "white",
          borderRadius: "4px",
          border: "1px solid rgba(0, 0, 0, 0.15)",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.07)",
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                borderRadius: "4px",
                backgroundColor: COLORS[idx],
              }}
            ></Box>
            <Typography
              color={showReset ? "rgba(75, 64, 221, 1)" : "black"}
              variant="overline"
            >
              {name}
            </Typography>
            {showReset && (
              <IconButton
                sx={{ m: 0, p: 0 }}
                onClick={() => resetAltSensor(config)}
              >
                <RotateLeftIcon />
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {(!showReset && model.model_type === "Generalized") && (
              <IconButton
                sx={{ m: 0, p: 0 }}
                onClick={() => handleModalState(true, config, COLORS[idx])}
              >
                <FindReplaceIcon />
              </IconButton>
            )}
            {/* <IconButton sx={{ m: 0, p: 0 }}>
              {config.variable_type === "input" && <VisibilityOffIcon />}
              {config.variable_type === "target" && <VisibilityIcon />}
            </IconButton> */}
          </Box>
        </Box>
      </Box>
    );
  };