import { useEffect, useState, useRef } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function MetricImportanceGraph({metrics, selectedMetric, setSelectedMetric, importance}){
    const [chartData, setChartData] = useState(null);
    const [options, setOptions] = useState(null);


    useEffect(() => {
        const _chartData = {
            labels: metrics,
            datasets: [
              {
                label: 'Feature Importance',
                data: metrics.map(m => parseFloat(importance[m])),
                backgroundColor: metrics.map(m => (selectedMetric && selectedMetric === m) ? 'rgba(75, 192, 192, 0.8)' : 'rgba(75, 192, 192, 0.2)'),
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
            ],
        };
        var _options = {
            indexAxis: 'y',
            scales: {
                x: {
                  beginAtZero: true,
                },
              },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'top',
                display: false,
              },
              title: {
                display: true,
                text: 'Flagged Metrics', // Title text
                font: {
                  size: 12, // Title font size
                },
                color:'black'
                }
            },
            onClick: (event, elements) => {
                if (elements.length > 0) {
                  const chart = event.chart;
                  const index = elements[0].index; // Get the index of the clicked bar
                  const label = metrics[index]; // Get the corresponding label
                  setSelectedMetric(label); // Update the state with the selected label
                }
            },
        }
        setChartData(_chartData);
        setOptions(_options)
    }, [metrics, importance, selectedMetric])


    return (
        <div style={{ width: '100%', marginTop:'20px', marginBottom:'20px', height: '120px' }}>
            {
                (importance && metrics.length > 0) &&
                <Bar data={chartData} options={options}/>
            }
        </div>
    )
}