import { useEffect, useState } from "react";

import { Box, Typography, IconButton, Badge } from "@mui/material";

import NotificationsIcon from '@mui/icons-material/Notifications';
import CircleIcon from "@mui/icons-material/Circle";

import ProfilePicture from '../../assets/profile-picture-john.webp'
import MonitorOverviewMenu from "./menu-tab";
import AlertNotificationCenter from "../alerts";

import EventsTable from "./events-table";
import DeploymentsTable from "./deployments-table";
import SensorsTable from "./sensors-table";

export default function MonitorOverview({shrunk, monitor, monitorData, startDate, endDate, sendMaxeHiddenQuery}){
    const [graphWidth, setGraphWidth] = useState('700px');

    useEffect(() => {
        if(!shrunk){
            setGraphWidth('900px');
        }else{
            setGraphWidth('700px');
        }
    }, [shrunk])


    const SeverityTag = ({level}) => {
        const backgroundColor = level === 'high' ? 'rgba(236, 34, 31, 0.1)' : level === 'healthy' ? 'rgba(33, 163, 95, 0.15)' : 'rgba(225, 174, 19, 0.15)'
        const textColor = level === 'high' ? '#900B09' : level === 'healthy' ? '#118046' : '#967100'
        const circleColor = level === 'high' ? '#EC221F' : level === 'healthy' ? '#118046' : '#E1AE13'
        return (
            <Box
                sx={{
                    backgroundColor:backgroundColor,
                    borderRadius:'24px',
                    display:'flex',
                    padding:'10px',
                    pt:'5px',
                    pb:'5px',
                    alignItems:'center',
                    gap:'5px',
                    cursor:'pointer',
                    '&:hover':{
                        backgroundColor:backgroundColor.replace('0.1', '0.2')
                    }
                }}
            >
                <CircleIcon sx={{fill:circleColor, fontSize:'10px', p:0, m:0}}/>
                <Typography fontSize='14px' color={textColor}>{level.charAt(0).toUpperCase() + level.slice(1)}</Typography>
            </Box>
        );
    }

    const [overviewTab, setOverviewTab] = useState('Events');

    return (
        <Box>
            <Box
                sx={{
                    padding:'24px',
                    paddingRight:'48px',
                    paddingLeft:'48px',
                    display:'flex',
                    flexDirection:'column',
                    gap:'18px',
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            gap:'8px'
                        }}
                    >
                        <Typography fontSize='18px' fontWeight='bold'>{monitor.name}</Typography>
                        <Typography fontSize='14px' color='#4F4F4F'>{monitor.description}</Typography>
                        <Box
                            sx={{
                                display:'flex',
                                gap:'6px',
                                alignItems:'center',
                                cursor:'pointer'
                            }}
                        >
                            <Typography color='#4F4F4F' fontSize='14px'>Owner</Typography>
                            <img src={ProfilePicture} height='20px'/>
                            <Typography fontSize='14px'>Alex</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            gap:'8px',
                            alignItems:'end'
                        }}
                    >
                        <AlertNotificationCenter sendMaxeHiddenQuery={sendMaxeHiddenQuery}/>
                        <SeverityTag level={'warning'}/>
                    </Box>
                </Box>
            </Box>
            <MonitorOverviewMenu monitor={monitor} activeTab={overviewTab} getActiveTab={setOverviewTab}/>
            <Box
                sx={{
                    p:'24px',
                    maxHeight:'60vh',
                    overflow:'scroll'
                }}
            >
                {
                    overviewTab === 'Events' ? 
                    <EventsTable graphWidth={graphWidth} startDate={startDate} endDate={endDate} eventsData={monitorData.events} events={monitor.events} sensors={monitor.sensors}/> :
                    overviewTab === 'Sensors' ?
                    <SensorsTable graphWidth={graphWidth} startDate={startDate} endDate={endDate} sensors={monitor.sensors} sensorsData={monitorData.sensors}/>
                    : <DeploymentsTable graphWidth={graphWidth}  startDate={startDate} endDate={endDate} deploymentsData={monitorData.deployments} deployments={monitor.deployments} events={monitor.events} sensors={monitor.sensors}/>
                }
            </Box>
            {/* {
                overviewTab ===
            } */}
        </Box>
    )
}
