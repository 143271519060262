import * as React from "react";
import Box from "@mui/material/Box";

import MenuDrawer from "./components/menu-drawer";

export default function PageHolder({
  children, removeToken
}) {
  return (
    <Box sx={{ display: "flex"}}>
      <MenuDrawer removeToken={removeToken} />
      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>
    </Box>
  );
}
