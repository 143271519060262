import React from 'react';
import { Box, Typography, Button, Card, CardContent, List, ListItem, ListItemText, CardHeader, IconButton, Grid } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel'

export default function AlertNotification({ alert, setAlertConfig, onGenerateAlert }){
  return (
    <Box sx={{ maxWidth: 400, backgroundColor:'white', borderRadius:'8px', margin: '1em' }}>
            <Box
                 sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    backgroundColor:'rgba(70,67,212,0.1)',
                    padding:'10px'
                }}
            >
                <Typography>
                    Alert Configuration
                </Typography>
            <IconButton
                sx={{p:0, m:0}}
                onClick={() => setAlertConfig(null)}
            >
                <CancelIcon sx={{fill:'black'}}/>
            </IconButton>
        </Box>
        <Box
            sx={{
                padding:'20px'
            }}
        >
          <Grid sx={{border: '1px solid lightgrey'}} container>
              <Grid sx={{p:'10px', border:'1px solid lightgrey'}} item xs={6}>
                    <Typography fontSize='14px' variant="overline">
                        Summary
                    </Typography>
              </Grid>
              <Grid sx={{border:'1px solid lightgrey', p:'10px'}}  item xs={6}>
                    <Typography variant="body1" sx={{ marginTop: 1, marginBottom: 1 }}>
                        {alert.summary}
                    </Typography>
              </Grid>
              <Grid sx={{border:'1px solid lightgrey', p:'10px'}}  item xs={6}>
                    <Typography fontSize='14px' variant="overline">
                        Resources
                    </Typography>
              </Grid>
              <Grid sx={{border:'1px solid lightgrey', p:'10px'}}  item xs={6}>
                    <Typography variant="body1" sx={{ marginTop: 1, marginBottom: 1 }}>
                        {alert.resources}
                    </Typography>
              </Grid>
              <Grid sx={{border:'1px solid lightgrey', p:'10px'}}  item xs={6}>
                    <Typography fontSize='14px' variant="overline">
                        Critical Values
                    </Typography>
              </Grid>
              <Grid sx={{border:'1px solid lightgrey', p:'10px'}}  item xs={6}>
                    <Typography variant="body1" sx={{ marginTop: 1, marginBottom: 1 }}>
                        {alert.critical_values}
                    </Typography>
              </Grid>

          </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:2, marginBottom:2 }}>
          <Button variant="contained" sx={{backgroundColor:'rgba(70,67,212,0.8)', '&:hover': {backgroundColor:'rgba(70,67,212,0.6)'}}} onClick={() => onGenerateAlert(alert)}>
            Generate Alert
          </Button>
        </Box>
    </Box>
  );
};
