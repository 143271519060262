import { Box, Typography, Divider } from "@mui/material";

import { useNavigate } from "react-router-dom";

import ShareIcon from "@mui/icons-material/Share";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import StreamIcon from "@mui/icons-material/Stream";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";


export default function ModelHeader({model, tab}){
  const navigate = useNavigate();
    const HeaderTab = ({name, link}) => {
        const active = name === tab;
        return (
            <Box
                sx={{
                boxShadow: active ? "0px 2px 0px rgba(70, 67, 212)" : 'none',
                color: active ? "rgba(70, 67, 212)" : 'black',
                "&:hover": {
                    color: "rgba(70, 67, 212)",
                    cursor: "pointer",
                },
                padding: "10px 0px",
                marginRight: "16px",
                }}
                onClick={() => navigate(link)}
            >
                <Typography fontSize="14px">
                    {name}
                </Typography>
            </Box>
        )
    }

    return (
        <Box
        className="topbar"
        sx={{
          backgroundColor: "white",
          boxShadow: "inset 0px -1px 0px 0px rgba(0, 0, 0, 0.15)",
          height: "44px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "10px",
            }}
          >
            <StreamIcon sx={{ p: 0, m: 0, fill: "rgb(130, 130, 130)" }} />
            <Typography
              sx={{ color: "rgba(130, 130, 130, 1)", fontSize: "14px" }}
            >
              Models
            </Typography>
          </Box>
          <Typography fontSize="1.4rem" color="rgba(130, 130, 130, 1)">
            /
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <FolderZipIcon
              sx={{ p: 0, m: 0, fill: "rgba(170, 0, 122, 1)" }}
            />
            <Typography
              sx={{ pt: "2px", fontWeight: "bold", fontSize: "14px" }}
            >
              {model.name}
            </Typography>
          </Box>
          <Divider flexItem orientation="vertical" />
          <Box
            sx={{
              display: "flex",
              paddingLeft: "10px",
            }}
          >
            <HeaderTab name={'Overview'} link={`/ml-factory/model-overview/${model.model_id}`}/>
            <HeaderTab name={'Testing'} link={`/ml-factory/model-testing/${model.model_id}`}/>
            <HeaderTab name={'Sandbox'} link={`/ml-factory/model-sandbox/${model.model_id}`}/>
            {
              model.is_deployed === 'true' &&
              <HeaderTab name={'Deployment'} link={`/ml-factory/model-deployment/${model.model_id}`}/>
            }
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            paddingRight: "20px",
          }}
        >
          <Box
            sx={{
              border: "1px solid rgba(55, 70, 91, 0.32)",
              padding: "3px",
              paddingLeft: "10px",
              paddingRight: "5px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              borderRadius: "4px",
            }}
          >
            <Typography fontSize="0.8rem" color="rgba(33, 43, 56, 1)">
              Version 1.0
            </Typography>
            <ArrowDropDownIcon sx={{ fill: "rgba(33, 43, 56, 1)" }} />
          </Box>
          <Divider flexItem orientation="vertical" />
          <ShareIcon />
          <MoreHorizIcon />
        </Box>
      </Box>
    )
}