import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import TimeFrameSelector from './time-frame-selector';
import DateSelector from './date-selector';

import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { Box, Divider, Typography, Button } from "@mui/material";

export default function DateConfig({graphStart, graphEnd, getStartDate, getEndDate}){
    const formatDate = (date) => {
        return date.toISOString().split('T')[0]
    }

    const formatTime = (date) => {
        return date.toISOString().split('T')[1].split('.')[0].substring(0,5)
    }


    // relative or time-range
    const [pickFormat, setPickFormat] = useState('time-range')

    // relative state variables
    const [timeFrame, setTimeFrame] = useState(7);
    const [timeFrameUnits, setTimeFrameUnits] = useState('day');

    // time-range state variables
    const [startDate, setStartDate] = useState(graphStart);
    const [endDate, setEndDate] = useState(graphEnd);

    // selector pop up variables
    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);

    const handleClick = (event) => {
        if(anchor){
            getStartDate(startDate);
            getEndDate(endDate);
        }
        setAnchor(anchor ? null : event.currentTarget);
    }

    useEffect(() => {
        if(pickFormat === 'relative'){
            const start = dayjs().subtract(timeFrame, timeFrameUnits);
            const end = dayjs();
            setStartDate(start);
            setEndDate(end);
        }
    }, [timeFrame, timeFrameUnits])

    return (
        <Box>
            <Box
                sx={{
                    display:'flex',
                    gap:'40px',
                    alignItems:'center',
                    justifyContent:'center',
                    padding:'20px',
                    border:'1px solid lightgrey',
                    cursor:'pointer',
                    borderRadius:'10px'
                }}
                onClick={handleClick}
            >
                <Box
                    sx={{
                        display:'flex',
                        gap:'4px',
                        alignItems:'center',
                        justifyContent:'center',
                    }}
                >
                    <ArrowDropDownIcon/>
                    <CalendarMonthIcon/>
                </Box>
                <Box>
                    <Box
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            gap:'20px',
                        }}
                    >
                        <Box
                            sx={{
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'center',
                                gap:'8px',
                            }}
                        >
                            <Typography>
                                {formatDate(startDate)}
                            </Typography>
                            <Typography>
                                {formatTime(startDate)}
                            </Typography>
                        </Box>
                        <Typography>to</Typography>
                        <Box
                            sx={{
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'center',
                                gap:'3px',
                            }}
                        >
                            <Typography>
                                {formatDate(endDate)}
                            </Typography>
                            <Typography>
                                {formatTime(endDate)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <BasePopup placement={'bottom'} open={open} anchor={anchor}>
                <Box
                sx={{
                    position: 'relative',
                    backgroundColor: 'white',
                    border: '1px solid rgba(0, 0, 0, 0.15)',
                    borderRadius: '8px',
                }}
                >
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                        }}
                    >
                        <Box
                            sx={{
                                display:'flex',
                                gap:'5px',
                                height:'20px',
                                padding:'5px'
                            }}
                        >
                            <Button
                                sx={{
                                    textTransform:'none',
                                }}
                                onClick={() => setPickFormat('time-frame')}
                                variant={pickFormat === 'time-frame' ? 'contained': 'text'}
                            >Time Frame</Button>
                            <Button
                                sx={{
                                    textTransform:'none',
                                }}
                                onClick={() => setPickFormat('relative')}
                                variant={pickFormat === 'relative' ? 'contained': 'text'}
                            >Relative</Button>
                        </Box>
                        <Divider orientation='horizontal' flexItem/>
                        <Box
                            sx={{
                                padding:'20px',
                                width:'400px',
                                height:'100px',
                                display:'flex',
                                alignItems:'center',
                            }}
                        >
                            {
                                pickFormat === 'relative' ?
                                <TimeFrameSelector
                                    timeFrame={timeFrame}
                                    timeFrameUnits={timeFrameUnits}
                                    setTimeFrame={setTimeFrame}
                                    setTimeFrameUnits={setTimeFrameUnits}
                                /> :
                                <DateSelector
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                />
                            }
                        </Box>
                    </Box>
                </Box>
            </BasePopup>
        </Box>
    )    
}
