import { Box, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function SensorsFilter({}){
    return (
        <Box
                sx={{
                height:'40px',
                display:'flex',
                backgroundColor:'white',
                justifyContent:'space-between',
                alignItems:'center',
                paddingLeft:'20px',
                paddingRight:'20px',
                }}
        >
            <Box
            sx={{
                width:'75%',
                borderRadius:'5px',
                display:'flex',
                alignItems:'center',
                justifyContent:'left',
                gap:'10px',
            }}
            >
                <SearchIcon  sx={{fill:'rgba(130, 130, 130, 0.9)'}}/>
                <Typography color='rgba(130, 130, 130, 1)'>
                Search...
                </Typography>
            </Box>
            <Box
            sx={{
                display:'flex',
                padding:'5px',
                backgroundColor:'rgba(55, 70, 91, 0.08)',
                alignItems:'center',
                borderRadius:'5px'
            }}
            >
            <FilterAltIcon sx={{fill:'none', stroke:'black'}}fontSize='1rem'/>
            <ArrowDropDownIcon fontSize='3rem'/>
            </Box>
        </Box>
    )
}