import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import "chartjs-adapter-dayjs-4";

import { Box } from "@mui/material";

import { getSensorConfigGraphData } from "../../../../../api";
import { COLORS } from "../../constants"

import dayjs from 'dayjs';

// Register the necessary chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin);

const TSGraph = ({graphWidth, color, sensorData, eventData, recordData, startDate, endDate}) => {
  const [hoverEvent, setHoverEvent] = React.useState(null);

  const [chartData, setChartData] = React.useState({
    datasets: [
    ]
  });
  const [options, setOptions] = React.useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        type: 'time', // Time series on the X axis
        min:startDate.toISOString(),
        max:endDate.toISOString()
      },
    },
  });

  React.useEffect(() => {
    if(recordData && sensorData && eventData){
      var __chartData = {
        datasets: [
          ...sensorData.map((data, idx) => (
            {
              data: data.map((d) => ({x:d.time, y:d.value})),
              fill: false,
              borderColor: color,
              borderWidth:1,
              tension: 0.1,
              yAxisID: `y${idx + 1}`,
              pointRadius:0,
            }
          )),
          ...recordData.map((record, idx) => (
            {
              label: record.title,
              data: record.values.map((r) => ({x:r.time, y:r.value})),
              pointRadius:4,
              backgroundColor: COLORS[idx + sensorData.length],
              type: 'scatter',
              showLine: false,
              yAxisID: `y${sensorData.length + idx + 1}`,
            }
          ))
        ]
      };

      var __options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
          title: {
            display: false,
          },
          annotation: {
            annotations: eventData.flatMap((event, idx) => (
              event.values.map((e) => (
                {
                  type: 'box', // Draw a rectangle
                  xMin: e.start_time, // Start time of the e
                  xMax: e.end_time, // End time of the event
                  backgroundColor: COLORS[idx + eventData.length + sensorData.length],
                  borderColor: COLORS[idx + eventData.length + sensorData.length],
                  
                  // borderWidth: 2,
                }
              ))
            )),
          },
        },
        scales: {
          x: {
            type: 'time', // Time series on the X axis
            min:startDate.toISOString(),
            max:endDate.toISOString()
          },
        },
        // onHover: (event, chartElement) => {
        //   const time = dayjs(event.chart.scales.x.getValueForPixel(event.x))
        //   var inBox = false;
        //   for(const event of eventData){
        //     const start = dayjs(event.start_time);
        //     const end = dayjs(event.end_time);
        //     if((time >= start) && (time <= end)){
        //       setHoverEvent(event);
        //       inBox = true;
        //       break;
        //     }
        //   }
        // },
      };

      sensorData.forEach((data, idx) => {
        __options.scales[`y${idx + 1}`] = {
          type: 'linear',
          position: 'left',
          border: {
              color: color
          },
        }
      })

      recordData.forEach((data, idx) => {
        __options.scales[`y${sensorData.length + idx + 1}`] = {
          type: 'linear',
          position: 'left',
          border: {
              color: COLORS[sensorData.length + idx]
          },
        }
      })

      setChartData({...__chartData});
      setOptions({...__options});
    }
  }, [recordData, sensorData, eventData, startDate, endDate])

  return (
    <Box style={{width:graphWidth, minHeight:'300px', alignItems:'center', display:'flex', justifyContent:'center'}}>
      <Line data={chartData} options={options} />
    </Box>
  );
};

export default TSGraph;
