import { useEffect, useState } from 'react';

import { Box, TableCell, TableRow, Typography, Divider, Table, TableContainer, TableHead, TableBody, IconButton } from "@mui/material";
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import MetricImportanceGraph from './metric-importance-graph';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

import dayjs from 'dayjs';

export default function RCARow({setStartDate, setEndDate, metricImportance, rca, rcaExamples}){
    const [metrics, setMetrics] = useState([]);

    const [selectedMetric, setSelectedMetric] = useState(null);
    const [sortedExamples, setSortedExamples] = useState([]);

    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    useEffect(() => {
        const _metrics = Object.keys(metricImportance);
        setMetrics(_metrics);
        if(_metrics.length > 0){
            setSelectedMetric(_metrics[0])
        }
    }, [metricImportance])

    useEffect(() => {
        if(rcaExamples && selectedMetric){
            var _sorted = rcaExamples[selectedMetric];
            console.log(_sorted);
            _sorted.sort((a,b) => new Date(b.time) - new Date(a.time));
            console.log(_sorted);
            setSortedExamples(_sorted);
        }
    }, [rcaExamples, selectedMetric])

    const handleAnalysisZoom = (date) => {
        const zoomDate = dayjs(date);
        setStartDate(zoomDate.subtract(8, 'hour'))
        setEndDate(zoomDate.add(8, 'hour'))
    }

    return (
        <TableRow col sx={{p:0, backgroundColor:'rgba(255,255,255,0.1)', boxShadow: '0 0 10px 5px rgba(0, 123, 150, 0.5)'}}>
            <TableCell colSpan={3}>
                <Box
                    sx={{
                        display:'flex',
                        alignItems:'center',
                        borderBottom: '1px solid black'
                    }}
                >
                    <EmojiObjectsIcon sx={{fill:'black', height:'15px'}}/>
                    <Typography color='black' fontWeight='bold' fontSize='12px' variant='overline'>Feature Analysis</Typography>
                </Box>
                <MetricImportanceGraph setSelectedMetric={setSelectedMetric} metrics={metrics} selectedMetric={selectedMetric} importance={metricImportance}/>
                {
                    selectedMetric &&
                    <Box>
                        <Box>
                            <Typography color='black' fontWeight='bold' fontSize='10px' variant='overline'>Metric Statistical Significance</Typography>
                        </Box>
                        <Box
                            sx={{
                                backgroundColor:rca[selectedMetric]['significant'] == '1' ? 'green' : 'red',
                                borderRadius:'8px',
                                padding:'5px',
                                pl:'10px',
                                pr:'10px',
                                marginBottom:'20px'
                            }}
                        >
                            <Typography fontSize='10px' color='black' fontWeight='bold'>{rca[selectedMetric]['significant'] == '1' ? 'True' : 'False'}</Typography>
                        </Box>
                        {
                            rca[selectedMetric]['significant'] == '1' &&
                            <Box>
                                <Box>
                                    <Typography color='black' fontWeight='bold' fontSize='10px' variant='overline'>Warning Range</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display:'flex',
                                        alignItems:'center',
                                        gap:'5px',
                                        marginBottom:'20px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor:'black',
                                            borderRadius:'8px',
                                            padding:'5px',
                                            pl:'10px',
                                            pr:'10px',
                                            width:'40%',
                                            display:'flex',
                                            alignItems:'center',
                                            justifyContent:'center'
                                        }}
                                    >
                                        <Typography fontSize='10px' color='white' fontWeight='bold'>{(parseFloat(rca[selectedMetric]['warning_mean']) - parseFloat(rca[selectedMetric]['warning_std'])*0.2).toLocaleString('en-US')}</Typography>
                                    </Box>
                                        <Typography>to</Typography>
                                    <Box
                                        sx={{
                                            backgroundColor:'black',
                                            borderRadius:'8px',
                                            padding:'5px',
                                            pl:'10px',
                                            pr:'10px',
                                            width:'40%',
                                            display:'flex',
                                            alignItems:'center',
                                            justifyContent:'center'
                                        }}
                                    >
                                        <Typography fontSize='10px' color='white' fontWeight='bold'>{(parseFloat(rca[selectedMetric]['warning_mean']) + parseFloat(rca[selectedMetric]['warning_std'])*0.2).toLocaleString('en-US')}</Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography color='black' fontWeight='bold' fontSize='10px' variant='overline'>Past Examples</Typography>
                                </Box>
                                <TableContainer sx={{maxHeight:'200px', overflow:'scroll'}}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableCell>
                                                Time
                                            </TableCell>
                                            <TableCell>
                                                Value
                                            </TableCell>
                                            <TableCell>
                                            </TableCell>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                sortedExamples.map((example, index) => (
                                                    <TableRow
                                                        onMouseEnter={() => setHoveredRowIndex(index + 1)}
                                                        onMouseLeave={() => setHoveredRowIndex(null)}
                                                    >
                                                        <TableCell>
                                                            <Typography variant='caption' sx={{color:'black'}}>
                                                                {example['time'].substring(1,19)}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant='caption' color='black'>
                                                                {parseFloat(example['value']).toFixed(5)}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                height:'50px',
                                                                width:'40px'
                                                            }}
                                                        >
                                                            {
                                                                (hoveredRowIndex && ((index + 1) === hoveredRowIndex)) &&
                                                                <IconButton
                                                                    onClick={() => handleAnalysisZoom(example['time'])}
                                                                ><TroubleshootIcon sx={{fill: 'black'}}/></IconButton>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        }
                    </Box>
                }
            </TableCell>
        </TableRow>
    )
}