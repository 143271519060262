import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import MemoryIcon from '@mui/icons-material/Memory';
import SpeedIcon from '@mui/icons-material/Speed';
import LinkIcon from '@mui/icons-material/Link';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { Line } from 'react-chartjs-2';
import ModelHeader from '../components/model-header';

import { getModel } from '../../../api';

// Dummy data for charts
const memoryUsageData = {
  labels: ['1h', '2h', '3h', '4h', '5h', '6h'],
  datasets: [
    {
      label: 'Memory Usage (%)',
      data: [60, 62, 64, 66, 70, 65],
      borderColor: 'rgba(75, 192, 192, 1)',
      fill: false,
    },
  ],
};

const computeData = {
  labels: ['1h', '2h', '3h', '4h', '5h', '6h'],
  datasets: [
    {
      label: 'Compute Utilization (%)',
      data: [30, 32, 35, 40, 42, 40],
      borderColor: 'rgba(255, 99, 132, 1)',
      fill: false,
    },
  ],
};

// Dummy data for tables
const apiUrls = [
  { endpoint: '/api/v1/predict', status: 'Operational' },
  { endpoint: '/api/v1/predictScore', status: 'Operational' },
];

const healthChecks = [
  { component: 'Database', status: 'Healthy' },
  { component: 'Model Service', status: 'Healthy' },
  { component: 'Cache', status: 'Degraded' },
];

const configurationDetails = [
  { key: 'Model Version', value: 'v1.0.1' },
  { key: 'Deployment Region', value: 'us-west-2' },
  { key: 'Auto-scaling', value: 'Enabled' },
];

// Tab Panel component
const TabPanel = ({ children, value, index }) => {
  return value === index ? <Box sx={{ p: 3 }}>{children}</Box> : null;
};

// Main Dashboard Component
const ModelDeploymentDashboard = ({}) => {
    const { modelId } = useParams("modelId");
    const [model, setModel] = useState({
        model_id:null,
        name:null,
    });
    useEffect(() => {
        const _getModel = async () => {
            const _model = await getModel(modelId);
            setModel(_model);
        }
        _getModel();
    }, [modelId])


  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
      <Box>
        <ModelHeader model={model} tab={'Deployment'}/>
        <Box sx={{ p: 3 }}>
        {/* Overview Section */}
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
            <Card>
                <CardContent>
                <Typography variant="h6">Health Status</Typography>
                <Typography variant="h4">Healthy</Typography>
                <CheckCircleIcon color="success" />
                </CardContent>
            </Card>
            </Grid>
            <Grid item xs={12} md={4}>
            <Card>
                <CardContent>
                <Typography variant="h6">Memory Usage</Typography>
                <Typography variant="h4">65%</Typography>
                <MemoryIcon color="primary" />
                </CardContent>
            </Card>
            </Grid>
            <Grid item xs={12} md={4}>
            <Card>
                <CardContent>
                <Typography variant="h6">Compute Utilization</Typography>
                <Typography variant="h4">40%</Typography>
                <SpeedIcon color="secondary" />
                </CardContent>
            </Card>
            </Grid>
        </Grid>

        {/* Performance Graphs */}
        <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
            Performance Metrics
            </Typography>
            <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Card>
                <CardContent>
                    <Typography variant="subtitle1">Memory Usage Over Time</Typography>
                    <Line data={memoryUsageData} />
                </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card>
                <CardContent>
                    <Typography variant="subtitle1">Compute Utilization Over Time</Typography>
                    <Line data={computeData} />
                </CardContent>
                </Card>
            </Grid>
            </Grid>
        </Box>

        {/* Detailed Information Tabs */}
        <Box sx={{ mt: 4 }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="API URLs" />
            <Tab label="Health Checks" />
            <Tab label="Configuration" />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
            <APIUrlsTable />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
            <HealthChecksTable />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
            <ConfigurationTable />
            </TabPanel>
        </Box>
        </Box>
      </Box>
  );
};

// Table Components

const APIUrlsTable = () => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Endpoint</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {apiUrls.map((url, index) => (
          <TableRow key={index}>
            <TableCell>{url.endpoint}</TableCell>
            <TableCell>{url.status}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const HealthChecksTable = () => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Component</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {healthChecks.map((check, index) => (
          <TableRow key={index}>
            <TableCell>{check.component}</TableCell>
            <TableCell>{check.status}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const ConfigurationTable = () => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Key</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {configurationDetails.map((config, index) => (
          <TableRow key={index}>
            <TableCell>{config.key}</TableCell>
            <TableCell>{config.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ModelDeploymentDashboard;
