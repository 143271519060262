import { Box, Typography} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";


export default function SensorFilters({}) {
    return (
        <Box
            sx={{
            display: "flex",
            gap: "8px",
            height: "30px",
            }}
        >
            <Box
            sx={{
                flexGrow: "1",
                border: "1px solid lightgrey",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                gap: "10px",
                paddingLeft: "10px",
                backgroundColor: "white",
                boxShadow: 1,
                cursor:'pointer'
            }}
            >
            <SearchIcon />
            <Typography>Search...</Typography>
            </Box>
        </Box>
    );
}