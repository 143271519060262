import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Table, TableHead, TableCell, TableBody, TableRow } from '@mui/material';
import "chartjs-adapter-dayjs-4";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
  Title,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Typography } from '@mui/material';

import dayjs from "dayjs";

// Register Chart.js components and plugins
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
  Title,
  annotationPlugin // Register the annotation plugin
);

const EventsTSGraph = ({events, startTime, endTime}) => {
  const [hoverEvent, setHoverEvent] = React.useState(events[0]);
  const data = {
    datasets: [
    //   {
    //     label: 'Sensor Data',
    //     data: [
    //       { x: '2024-10-01T00:00:00', y: 65 },
    //       { x: '2024-10-01T01:00:00', y: 59 },
    //       { x: '2024-10-01T02:00:00', y: 80 },
    //       { x: '2024-10-01T03:00:00', y: 81 },
    //       { x: '2024-10-01T04:00:00', y: 56 },
    //       { x: '2024-10-01T05:00:00', y: 55 },
    //     ],
    //     borderColor: 'rgba(75, 192, 192, 1)',
    //     backgroundColor: 'rgba(75, 192, 192, 0.2)',
    //     fill: false,
    //   },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'time', // Time series on the X axis
        time: {
          unit: 'day',
        },
        min:startTime.toISOString(),
        max:endTime.toISOString()
      },
      y: {
        // type: 'linear',
        display: false,
        min:0,
        max:100
        // position: 'left',
      },
    },
    plugins: {
      annotation: {
        annotations: events.map((event) => (
          {
            type: 'box', // Draw a rectangle
            xMin: event.start_time, // Start time of the event
            xMax: event.end_time, // End time of the event
            backgroundColor: (hoverEvent && hoverEvent.start_time === event.start_time) ? 'rgba(255, 99, 132, 0.4)' : 'rgba(255, 99, 132, 0.25)', // Light red background
            borderColor: (hoverEvent && hoverEvent.start_time === event.start_time) ? 'rgba(255, 99, 132, 1)' : 'rgba(255, 99, 132, 0.4)',
            borderWidth: 2,
          }
        )),
      },
    },
    onHover: (event, chartElement) => {
      const time = dayjs(event.chart.scales.x.getValueForPixel(event.x))
      var inBox = false;
      for(const event of events){
        const start = dayjs(event.start_time);
        const end = dayjs(event.end_time);
        if((time >= start) && (time <= end)){
          setHoverEvent(event);
          inBox = true;
          break;
        }
      }
    },
  };

  var _columns = Object.keys(events[0]).sort();
  var columns = ['start_time', 'end_time'];
  for(const col of _columns){
    if((col != 'start_time') & (col != 'end_time')){
      columns.push(col);
    }
  }

  return (
    <Box
      sx={{
        display:'flex',
        flexDirection:'column',
        gap:'10px',
        width:'100%'
      }}  
    >
      <div style={{width:'100%'}}>
        <Line data={data} options={options} />
      </div>
      <Box
        sx={{
            minHeight:'140px',
            overflow:'scroll',
            border:'1px solid lightgrey',
            borderRadius:'8px',
        }}
      >
        <Table
        >
            <TableHead
            sx={{
                backgroundColor:'lightgrey'
            }}
            >
            {
                columns.map((col) => (
                <TableCell
                    sx={{
                    border:'1px solid lightgrey'
                    }}
                >{col}</TableCell>
                ))
            }
            </TableHead>
            <TableBody>
            {
                hoverEvent &&
                <TableRow>
                {columns.map((col)=>(
                    <TableCell
                    sx={{
                        border:'1px solid lightgrey'
                    }}
                    >{hoverEvent[col]}</TableCell>
                ))}
                </TableRow>
            }
            </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default EventsTSGraph;
