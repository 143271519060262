import { useEffect, useState } from 'react';

import { Box, IconButton } from "@mui/material";
import { Table, TableCell, TableHead, Typography, TableBody, TableRow } from "@mui/material";

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import ThermostatIcon from "@mui/icons-material/Thermostat";
import SensorsIcon from "@mui/icons-material/Sensors"

import SparkLine from "./sparkline";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

import dayjs from "dayjs";
import TSGraph from './ts-graph';

import { GROUP_COLORS, COLORS } from '../../constants';

export default function SensorsTable({sensors, sensorsData, startDate, endDate}) {
    const [selected, setSelected] = useState(false);

    const [selectedSensor, setSelectedSensor] = useState(null);

    const HeaderCell = ({column, description}) => {
        return (
            <TableCell>
                <Typography
                    sx={{
                        fontWeight:'600',
                        fontSize:'11px'
                    }}
                >
                    {column}
                    {
                        description &&
                        <span style={{fontWeight:500}}> ({description})</span>
                    }
                </Typography>
            </TableCell>
        );
    }

    const SensorsCell = ({sensor, idx}) => {
        return (
            <TableCell
            >
                <Box
                    sx={{
                        display:'flex',
                        gap:'8px',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: COLORS[idx],
                            borderRadius:'5px',
                            pr:'2px',
                            pl:'2px',
                            // height:'30px',
                            // width:'30px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center'
                        }}
                    >
                        <SensorsIcon sx={{fill:'#FFF'}} />
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            gap:'5px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize:'14px',
                                fontWeight:'550'
                            }}
                        >{sensor.name}</Typography>
                        <Typography
                            sx={{
                                fontSize:'11px',
                                fontWeight:'450'
                            }}
                        >{sensor.description}
                        </Typography>
                    </Box>
                </Box>
            </TableCell>
        )
    }

    const LatestValueCell = ({sensor}) => {
        const latestValue = sensorsData[sensor.sensor_id].latest_value;
        return (
            <TableCell>
                <Typography>
                    {parseFloat(latestValue).toFixed(3)}
                </Typography>
            </TableCell>
        )
    }

    const AverageValueCell = ({sensor}) => {
        const average = sensorsData[sensor.sensor_id].average;
        return (
            <TableCell>
                <Typography>
                    {parseFloat(average).toFixed(3)}
                </Typography>
            </TableCell>
        )
    }

    const StdValueCell = ({sensor}) => {
        const std = sensorsData[sensor.sensor_id].std;
        return (
            <TableCell>
                <Typography>
                    {parseFloat(std).toFixed(3)}
                </Typography>
            </TableCell>
        )
    }
    

    const handleSensorSelect = (sensor) => {
        if(selectedSensor && (sensor.sensor_id === selectedSensor.sensor_id)){
            setSelectedSensor(null);
        }else{
            setSelectedSensor(sensor);
        }
    }


    const SensorRow = ({sensor, idx}) => {
        var selected = false;
        if(selectedSensor && selectedSensor.sensor_id === sensor.sensor_id){
            selected = true;
        }
        return (
            <TableRow 
                sx={{
                    backgroundColor: selected ? 'rgba(114, 110, 255, 0.08)' : 'white',
                    borderTop: '1px solid rgba(0, 0, 0, 0.15)',
                }}
                onClick={() => handleSensorSelect(sensor)}
            >
                <SensorsCell sensor={sensor} idx={idx}/>
                <LatestValueCell sensor={sensor}/>
                <AverageValueCell sensor={sensor}/>
                <StdValueCell sensor={sensor}/>
            </TableRow>
        );
    }

    const SensorGraph = ({idx}) => {
        return (
            <TableRow
                sx={{
                    borderTop: '1px solid rgba(0, 0, 0, 0.15)',
                }}
            >
                <TableCell
                    colSpan={5}
                >
                    <Box
                        sx={{
                            padding:'25px',
                            height:'300px'
                        }}
                    >
                        <TSGraph color={COLORS[idx]} sensorData={[sensorsData[selectedSensor.sensor_id].data]} eventData={[]} recordData={[]} startDate={startDate} endDate={endDate}/>
                        {/* <AnomalyDetectionTSGraph
                            actuals={anomalyInfo[selectedSensor.sensor_id].values}
                            anomalies={anomalyInfo[selectedSensor.sensor_id].anomaly_values}
                            labels={anomalyInfo[selectedSensor.sensor_id].labels}
                        /> */}
                    </Box>
                </TableCell>
            </TableRow>
        );
    }

    const [rows, setRows] = useState([]);
    useEffect(() => {
        if(!selectedSensor && sensorsData){
            const _rows = sensors.map((sensor, idx) => (
                <SensorRow sensor={sensor} idx={idx}/>
            ))
            setRows([..._rows]);
        }else if(selectedSensor && sensorsData){
            var _rows = [];
            sensors.forEach((sensor, idx) => {
                _rows.push(<SensorRow sensor={sensor} idx={idx}/>);
                if(sensor.sensor_id === selectedSensor.sensor_id){
                    _rows.push(<SensorGraph idx={idx}/>)
                }
            })
            setRows([..._rows]);
        }

    }, [sensors, sensorsData, selectedSensor])

    return (
        <Box
            sx={{
                borderTopLeftRadius:'10px',
                borderTopRightRadius:'10px',
                overflow:'hidden',
                border: '1px solid rgba(0, 0, 0, 0.15)'
            }}
        >
            <Table>
                <TableHead
                    sx={{
                        backgroundColor:'#F7F7F7',
                        fontWeight:'500',
                        fontSize:'11px',
                    }}
                >
                    <HeaderCell column={'Sensors'}/>
                    <HeaderCell column={'Latest Value'}/>
                    <HeaderCell column={'Average Value'}/>
                    <HeaderCell column={'Standard Deviation'}/>
                </TableHead>
                <TableBody>
                    {
                        rows.map((row) => (row))
                    }
                </TableBody>
            </Table>
        </Box>
    );
}