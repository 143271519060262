import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import dayjs from 'dayjs';

import { getSensorGraphData } from "../../../../api";

// Register the necessary chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const AltSensorTSGraph = ({sensor, altSensor, color}) => {
  const [sensorData, setSensorData] = React.useState([]);
  const [altSensorData, setAltSensorData] = React.useState([]);
  const [labels, setLabels] = React.useState([]);

  const [startDate, setStartDate] = React.useState(dayjs().subtract(7, 'day'));
  const [endDate, setEndDate] = React.useState(dayjs());

  const [chartData, setChartData] = React.useState(null);
  const [options, setOptions] = React.useState(null);

  React.useEffect(() => {
    const getSensorGraphData = async () => {
      const data = await getSensorGraphData(sensor, startDate, endDate);
      setSensorData(data['data']);
      setLabels(data['labels']);
    };
    getSensorGraphData();
  }, [sensor])

  React.useEffect(() => {
    const getSensorGraphData = async () => {
      const graphData = await getSensorGraphData(altSensor, startDate, endDate);
      setAltSensorData(graphData['data']);
    };
    getSensorGraphData();
  }, [altSensor])

  React.useEffect(() => {
    if(labels){
      var __chartData = {
        labels: labels,
        datasets: [
          {
            data: sensorData,
            fill: false,
            borderColor: color,
            tension: 0.1,
            pointRadius:0,
            yAxisID: 'y1',
          },
          {
            data: altSensorData,
            fill: false,
            borderColor: 'rgba(230, 151, 255, 1)',
            tension: 0.1,
            pointRadius:0,
            yAxisID: altSensorData.length ? 'y2' : 'y1',
          },
        ],
      };

      var __options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: false,
              text: 'time',
            },
            ticks: {
              color: 'rgb(120,120,120)', // Change the x-axis ticks color
              callback: function(value, index, values) {
                const label = this.getLabelForValue(value);
                return index % 10 === 0 ? label.slice(0,11) : '';
              },
            },
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'left',
            border:{
              color: color,
            }
          },
        },
      };

      if(altSensorData.length){
        __options['scales']['y2'] = {
          type: 'linear',
          display: true,
          position: 'left',
          border:{
            color: 'rgba(230, 151, 255, 1)',
          }
        }
      }

      setChartData({...__chartData});
      setOptions({...__options});
    }
  }, [sensorData, altSensorData, labels])


  return (
    <div style={{height:'300px', width:'90%'}}>
      {(chartData && options) && <Line data={chartData} options={options} />}
    </div>
  );
};

export default AltSensorTSGraph;
