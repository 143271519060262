export async function getRequest(
    url
) {
    const token = localStorage.getItem('token');
    const response = await fetch(
        url, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
              'Content-Type': 'application/json',
          },
    });

    // Check if the response is successful (status code 200-299)
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
    // Parse the JSON data
    const result = await response.json();

    return result;
}


export async function postRequest(
    url, formData
){
    const token = localStorage.getItem('token');
    const headers = {
        'Authorization': `Bearer ${token}`,
    }
    const response = await fetch(
        url, {
        method: 'POST',
        body: formData,
        headers:headers,
    });

    // Check if the response is successful (status code 200-299)
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
  
    // Parse the JSON data
    const result = await response.json();

    return result;
    
}
