import React, { useState } from 'react';
import { Box, Typography, Popover, List, ListItem, Checkbox, ListItemText, IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Filters = ({filterBoxList}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const handleBoxClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  return (
    <div>
      {/* Box that triggers the Popover */}
      <Box
        onClick={handleBoxClick}
        sx={{
          border: "1px solid lightgrey",
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "6px",
          paddingLeft: "10px",
          paddingRight: "10px",
          padding:'8px',
          boxShadow: 1,
          cursor: 'pointer',
        }}
      >
        <Typography fontSize="0.9rem">Filters</Typography>
        <ArrowDropDownIcon fontSize="1.2rem" />
      </Box>

      {/* Popover that contains the filter options */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List
            sx={{
                maxHeight:'200px'
            }}
        >
          {filterBoxList.map((filterBox) => (
            filterBox
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default Filters;
