import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale, zoomPlugin);

const AnomalyDetectionTSGraph = ({
  actuals,
  anomalies,
  startTime,
  endTime
}) => {
  const chartRef = React.useRef(null);

  const data = {
    datasets: [
      {
        data: anomalies.map((anomaly) => ({x:anomaly.time, y:anomaly.value})),
        pointRadius:10,
        backgroundColor: 'rgba(255,99,132,1)',
        type: 'scatter',
        showLine: false,
      },
      {
        data: actuals.map((actual) => ({x:actual.time, y:actual.value})),
        fill: false,
        borderColor: 'blue',
        tension: 0.1,
        pointRadius: 0, // Hide the circles at data points
      },
    ],
  };

  var options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
    onClick: (event) => {
      const chart = chartRef.current;
      if (!chart) return;

      const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);

      if(chart.isZoomedOrPanned()){
        chart.resetZoom();
      }else if (points.length) {
        const idx = points[0].element.$context.parsed.x;
        const xMin = Math.max(chart.getInitialScaleBounds().x.min, idx - 10); // Adjust as needed
        const xMax = Math.min(chart.getInitialScaleBounds().x.max, idx + 10); // Adjust as needed
        chart.zoomScale('x', { min: xMin, max: xMax });
      }
    },
    scales: {
        x: {
            type: 'time', // Time series on the X axis
            time: {
                unit: 'day',
            },
            min:startTime,
            max:endTime
        },
        y: {
            type: 'linear',
            display: true,
            position: 'left',
        },
    },
  };

  return (
    <div style={{height:'620px', width:'100%'}}>
      <Line ref={chartRef} data={data} options={options} />
    </div>
  );
};

export default AnomalyDetectionTSGraph;
