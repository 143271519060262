import React, { useEffect, useState, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import "chartjs-adapter-dayjs-4";
import zoomPlugin from 'chartjs-plugin-zoom';

import { COLORS } from "../../constants"


import dayjs from 'dayjs';

// Register the necessary chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin, zoomPlugin);

const TSGraph = ({
    sensors,
    sensorData,
    events,
    eventData,
    deployments,
    deploymentData,
    startDate,
    endDate,
    visibility
}) => {
  const [chartData, setChartData] = useState(null);
  const [options, setOptions] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if(deploymentData && eventData && sensorData && visibility){
      var _chartData = {
        datasets: [
        ]
      };

      var _options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'xy',
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'xy',
            },
          },
          title: {
            display: false,
          },
          annotation: {
            annotations: [
            ]
          },
        },
        scales: {
          x: {
            type: 'time', // Time series on the X axis
            // time: {
            //     unit: 'hour',
            // },
            min:startDate.toISOString(),
            max:endDate.toISOString(),
          },
          y : {
            type: 'linear',
            min:0,
            max:1,
            position: 'left',
            border: {
                color: 'grey',
            },
        }
        },
      };

      sensors.forEach((sensor, idx) => {
          if(visibility.sensors[sensor.sensor_id]){
              _chartData.datasets.push(
                  {
                      label: sensor.name,
                      data: sensorData[sensor.sensor_id].data.map((d) => ({x:d.time, y:d.value})),
                      fill: false,
                      borderColor: COLORS[idx],
                      tension: 0.1,
                      yAxisID: `y${idx + 1}`,
                      pointRadius:0,
                  }
              )
              _options.scales[`y${idx + 1}`] = {
                  type: 'linear',
                  position: 'left',
                  border: {
                      color: COLORS[idx],
                  },
              }
          }
      })


      events.forEach((event, idx) => {
          if(visibility.events[event.event_id]){
              const data = eventData[event.event_id].events_list
              _options.plugins.annotation.annotations.push(...data.map((e, i) => (
                  {
                      type: 'box', // Draw a rectangle
                      xMin: e.start_time, // Start time of the e
                      xMax: e.end_time, // End time of the event
                      backgroundColor: COLORS[sensors.length + idx],
                      borderColor: COLORS[sensors.length + idx]
                  }
              )))
            }
      });

      deployments.forEach((deployment, idx) => {
          if(visibility.deployments[deployment.deployment_id]){
              if(deployment.model_objective === 'Anomaly Detection'){
                const { anomalies, actuals } = deploymentData[deployment.model_id];
                  _chartData.datasets.push(
                    {
                        data: anomalies.map((anomaly) => ({x:anomaly.time, y:anomaly.value, score: anomaly.score})),
                        pointRadius:4,
                        backgroundColor: COLORS[sensors.length + events.length + idx],
                        type: 'scatter',
                        showLine: false,
                        yAxisID: `y${sensors.length + idx + 1}`,
                    }
                  )
                  // _chartData.datasets.push(
                  //   {
                  //       data: actuals.map((actual) => ({x:actual.time, y:actual.value})),
                  //       fill: false,
                  //       borderColor: COLORS[sensors.length + events.length + idx],
                  //       tension: 0.1,
                  //       pointRadius: 0, // Hide the circles at data points
                  //       yAxisID: `y${sensors.length + idx + 1}`,
                  //       label:'hello'
                  //     }
                  // )
                  _options.scales[`y${sensors.length + idx + 1}`] = {
                    type: 'linear',
                    // display: visibility[sensor.name],
                    position: 'left',
                    border: {
                        color: COLORS[sensors.length + events.length + idx],
                    },
                  }
    
              }else if(deployment.model_objective === 'Predictive'){
                  const { predictions, target, actuals } = deploymentData[deployment.model_id];
                  _options.plugins.annotation.annotations.concat(
                      ...actuals.map((e) => (
                          {
                            type: 'box', // Draw a rectangle
                            xMin: e.start_time, // Start time of the e
                            xMax: e.end_time, // End time of the event
                            backgroundColor: COLORS[idx + sensors.length + events.length],
                            borderColor: COLORS[idx + sensors.length + events.length],
                            yAxisId: `y${sensors.length + idx + 1}`,
                            // borderWidth: 2,
                          }
                        )),
                  )
                  _options.scales[`y${sensors.length + idx + 1}`] = {
                        type: 'linear',
                        // display: visibility[sensor.name],
                        position: 'left',
                        border: {
                            color: COLORS[sensors.length + events.length + idx],
                        },
                    }
                  _chartData.datasets.push(
                    {
                        label:'% Chance',
                        data: predictions.map((data, idx) => ({x:data.time, y:data.probability})),
                        fill: false,
                        borderColor: COLORS[idx + sensors.length + events.length],
                        tension: 0.1,
                        yAxisID: 'y',
                        pointBackgroundColor: predictions.map((data) => ((Math.round(data.probability) === Math.round(data.target)) ? 'green' : 'red'))
                    }
                  )
                  _chartData.datasets.push(
                    {
                        label:target.name,
                        borderColor:COLORS[idx + sensors.length + events.length],
                    },
                  )
                }
              }
            })
            console.log(_chartData);
            console.log(_options)

    setChartData({..._chartData});
    setOptions({..._options});
    }

  }, [sensors, events, deployments, visibility, eventData, sensorData, deploymentData, startDate, endDate])

  useEffect(() => {
    const handleDoubleClick = () => {
      if (chartRef.current) {
        chartRef.current.resetZoom();
      }
    };

    var canvas = null;
    if(chartRef && chartRef.current){
        var canvas = chartRef.current.canvas;
    }
    if (canvas) {
      canvas.addEventListener('dblclick', handleDoubleClick);
    }

    // Cleanup the event listener on unmount
    return () => {
      if (canvas) {
        canvas.removeEventListener('dblclick', handleDoubleClick);
      }
    };
  }, [chartData, options]);

  console.log(chartData);
  console.log(options);

  return (
    <div style={{height:'670px',width:'100%'}}>
      {
        (chartData && options) &&
        <Line ref={chartRef} data={chartData} options={options} />
      }
    </div>
  );
};

export default TSGraph;
