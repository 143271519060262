import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, Box, Typography } from '@mui/material';

const FileUpload = ({setFile, file}) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
        setFile(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <Box
    sx={{
        border: '2px dashed lightgray',
        borderRadius: 2,
        bgcolor: 'background.default',
        p: 4,
        textAlign: 'center',
        cursor: 'pointer',
    }}
    onClick={() => document.getElementById('file-input').click()}
    onDrop={handleDrop}
    onDragOver={handleDragOver}
    >
    <Input
        id="file-input"
        type="file"
        onChange={handleFileChange}
        inputProps={{ accept: '.jpg,.jpeg,.png,.pdf' }} // Specify allowed file types
        sx={{ display: 'none' }} // Hide the default file input
    />
    {file ? (
        <Typography>{file.name}</Typography>
    ) : (
        <Typography variant="body2">
        Drop file here or browse local files.
        </Typography>
    )}
    </Box>
  );
};

export default FileUpload;