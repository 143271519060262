import { Navigate, useNavigate, Link } from "react-router-dom";

import { Box, IconButton, Typography, Button, Divider } from "@mui/material";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EastIcon from '@mui/icons-material/East';
import StormIcon from '@mui/icons-material/Storm';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import ModelObjectiveIcon from '../../components/model-objective.icon'

export default function ModelBuilderHeader({setTab, tab, model, save}){
    const Tab = ({tabId, tabName, step}) => {
        const active = (tabId === tab);
        
        return (
            <Box
                sx={{
                display:'flex',
                gap:'10px',
                alignItems:'center',
                height:'100%',
                borderBottom: active ? '2px solid rgba(70, 67, 212, 1)' : 'none', 
                cursor:'pointer',
                '&:hover': {
                    borderBottom: !active ? '2px solid rgba(70, 67, 212, 0.1)' : '2px solid rgba(70, 67, 212, 1)',
                },
                }}
                onClick={() => setTab(tabId)}
            >
                <Box
                sx={{
                    height:'25px',
                    width:'25px',
                    borderRadius:'15px',
                    backgroundColor: active ? 'rgba(70, 67, 212, 1)' : 'rgba(240, 240, 240, 1)',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center'
                }}
                >
                    <Typography color={active ? 'white' : 'rgba(79, 79, 79, 1)'}>{step}</Typography>
                </Box>
                <Typography color={active ? 'rgba(70, 67, 212, 1)' : 'rgba(36, 37, 42, 1)'} variant='h7'>{tabName}</Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
            height:'70px',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            paddingLeft:'10px',
            paddingRight:'10px',
            }}
        >
            <Box
            sx={{
                display:'flex',
                gap:'10px',
                justifyContent:'center',
                alignItems:'center',
                paddingBottom:'10px'
            }}
            >
            <IconButton
                sx={{
                padding:0,
                margin:0,
                height:'50px',
                width:'50px',
                }}
            >
                <ArrowBackIcon/>
            </IconButton>
            <Box
                sx={{
                paddingTop:'20px'
                }}
            >
                <Typography fontWeight='bold' variant='h7'>{model.name}</Typography>
                <Box
                sx={{
                    display:'flex',
                    gap:'15px',
                    paddingTop:'5px',
                    alignItems:'center'
                }}
                >
                <Typography color='rgba(79, 79, 79, 1)' variant='body2'>Objective</Typography>
                <ModelObjectiveIcon model={model} iconSize='20px' textFontSize='14px'/>
                </Box>
            </Box>
            </Box>
            <Box
            sx={{
                display:'flex',
                gap:'25px',
                height:'100%',
                alignItems:'center'
            }}
            >
            <Tab tabId={'data'} tabName={'Training data'} step={'1'}/>
            <ChevronRightIcon/>
            <Tab tabId={'config'} tabName={'Model configuration'} step={'2'}/>
            </Box>
            <Box
            sx={{
                display:'flex',
                alignItems:'center',
                gap:'15px'
            }}
            >
            <Button
                sx={{
                    backgroundColor:'rgba(36, 37, 42, 1)',
                    textTransform:'none',
                    '&:hover': {
                    backgroundColor: 'rgba(36, 37, 42, 0.9)', // Hover background color
                    },
                    width:'60px'
                }}
                variant='contained'
                onClick={save}
            >
                Save
            </Button>
            <Divider flexItem orientation='vertical'/>
            <Button
                sx={{
                    backgroundColor:'rgba(70, 67, 212, 1)',
                    textTransform:'none',
                    '&:hover': {
                        backgroundColor: 'rgba(70, 67, 212, 0.9)', // Hover background color
                    },
                    width:'150px'
                }}
                variant='contained'
                endIcon={<EastIcon/>}
                component={Link}
                to={'/ml-factory/model-overview/' + model.model_id}
            >
                Train model
            </Button>
            </Box>
        </Box>
    )
}