import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import "chartjs-adapter-dayjs-4";

import { COLORS } from "../constants"

import dayjs from 'dayjs';

// Register the necessary chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin);

const RCATSGraph = ({sensorData, eventData, startDate, endDate}) => {
  const [hoverEvent, setHoverEvent] = React.useState(null);

  const [chartData, setChartData] = React.useState({
    datasets: [
    ]
  });
  const [options, setOptions] = React.useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        type: 'time', // Time series on the X axis
        time: {
            unit: 'day',
        },
        min:startDate.toISOString(),
        max:endDate.toISOString()
      },
    },
  });


  React.useEffect(() => {
    if(sensorData && eventData){
      var __chartData = {
        datasets: [
            {
              data: sensorData.map((d) => ({x: d.time, y: d.value})),
              fill: false,
              borderColor: COLORS[0],
              tension: 0.1,
              yAxisID: 'y1',
              pointRadius:0,
            }
        ]
      };

      var __options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
          title: {
            display: false,
          },
          annotation: {
            annotations: eventData.map((e) => (
                {
                  type: 'box', // Draw a rectangle
                  xMin: e.start_time, // Start time of the e
                  xMax: e.end_time, // End time of the event
                  backgroundColor: COLORS[1],
                  borderColor: COLORS[1]
                  
                  // borderWidth: 2,
                }
            )),
          },
        },
        scales: {
          x: {
            type: 'time', // Time series on the X axis
            min:startDate.toISOString(),
            max:endDate.toISOString(),
            time: {
                unit: 'day',
            },
          },
          y1:{
            type: 'linear',
            position: 'left',
            border: {
                color: COLORS[0]
            },   
          }
        },
        // onHover: (event, chartElement) => {
        //   const time = dayjs(event.chart.scales.x.getValueForPixel(event.x))
        //   var inBox = false;
        //   for(const event of eventData){
        //     const start = dayjs(event.start_time);
        //     const end = dayjs(event.end_time);
        //     if((time >= start) && (time <= end)){
        //       setHoverEvent(event);
        //       inBox = true;
        //       break;
        //     }
        //   }
        // },
      };

      setChartData({...__chartData});
      setOptions({...__options});
    }
  }, [sensorData, eventData, startDate, endDate])

  return (
    <div style={{height:'300px', width:'100%'}}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default RCATSGraph;
