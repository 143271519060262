import { Box, Typography } from "@mui/material"

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import ForwardIcon from '@mui/icons-material/Forward';

export default function DateSelector({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
}){
    return (
        <Box
            sx={{
                display:'flex',
                gap:'20px',
                alignItems:'center'
            }}
        >
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    width:'100%',
                    gap:'5px'
                }}
            >
                <Typography variant='caption' fontWeight='bold'>Start date & time</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        slotProps={{ textField: { size: "small" } }}
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      slotProps={{ textField: { size: "small" } }}
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                    />
                  </LocalizationProvider>
            </Box>
            <ForwardIcon/>
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    width:'100%',
                    gap:'5px'
                }}
            >
                <Typography variant='caption' fontWeight='bold'>End date & time</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        slotProps={{ textField: { size: "small" } }}
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      slotProps={{ textField: { size: "small" } }}
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                    />
                  </LocalizationProvider>
            </Box>
        </Box>
    );
}