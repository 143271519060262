import { useState, useEffect } from "react";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import { Box, Typography, IconButton, Divider } from "@mui/material";

import PDFViewer from "./pdf-viewer";

import { getMonitorDocument } from '../../../../../api'
import DocumentCard from "./document-card";

export default function MonitorDocuments({maxeShrunk, openMaxe, monitor, blobUrl, inputPageNumber}){
    const [sectionWidth, setSectionWidth] = useState('70vw');
    useEffect(() => {
        var diff = 260;
        if(openMaxe && maxeShrunk){
            diff += 300;
        }else if(openMaxe && !maxeShrunk){
            diff += 500
        }
        setSectionWidth(`calc(92vw - ${diff}px)`)
    }, [maxeShrunk, openMaxe])

    const [manuals, setManuals] = useState([]);

    useEffect(() => {
        const _manual = [{
            'name': monitor.document_filename,
            'date_uploaded': monitor.creation_time,
        }]
        setManuals(_manual);
    }, [monitor, blobUrl])

    const [selectedManual, setSelectedManual] = useState(null);
    const handleManualSelect = (manual) => {
        if(selectedManual && (manual.name === selectedManual.name)){
            setSelectedManual(null);
        }else{
            setSelectedManual(manual);
        }
    }

    return (
        <Box
            sx={{
                display:'flex',
                width:'100%',
                height:'100%',
                overflowX:'hidden'
            }}
        >
            <Box
                sx={{
                    backgroundColor:'#F7F7F7',
                    display:'flex',
                    flexDirection:'column',
                    border:'1px solid rgba(0,0,0,0.15)',
                    height:'100%',
                    minHeight:'95vh',
                }}
            >
                <Box>
                <Box
                    sx={{
                        padding:'15px',
                        justifyContent:'space-between',
                        alignItems:'center',
                        display:'flex',
                        cursor:'pointer',
                        backgroundColor:'white',
                        width:'230px'
                    }}
                >
                    <Box
                        sx={{
                            display:'flex',
                            gap:'5px',
                        }}
                    >
                        <SearchIcon sx={{fill: '#828282'}}/>
                        <Typography fontSize='15px' color='#828282'>Search...</Typography>
                    </Box>
                    <IconButton sx={{m:0, p:0}}>
                        <AddIcon sx={{fill:'#4B40DD'}}/>
                        <Typography color='#4B40DD' fontSize='14px'>
                            Upload
                        </Typography>
                    </IconButton>
                </Box>
                </Box>
                <Box
                    sx={{
                        width:'260px',
                        height:'100%',
                        backgroundColor:'white',
                    }}
                >
                        {
                            manuals.map((manual) => (
                                <DocumentCard manual={manual}/>
                            ))
                        }
                </Box>
            </Box>
            <Divider orientation='veritcal' flexItem/>
            <Box
                sx={{
                    maxWidth:'100%',
                    width: sectionWidth,
                }}
            >
                <PDFViewer fileName={monitor.document_filename} blobUrl={blobUrl} inputPageNumber={inputPageNumber}/>
            </Box>
        </Box>
    )
}