import { Box, Typography, Divider, TextField, FormControl, Select, MenuItem, InputLabel, IconButton } from "@mui/material";

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';



export default function InputParameters({model, setModel}){
    const handleContextWindow = (event) => {
        var config = {...model};
        config['context_window'] = event.target.value;
        setModel({...config});
    }

    const handlePredictionWindow = (event) => {
        var config = {...model};
        config['prediction_window'] = event.target.value;
        setModel({...config});
    }

    const handleContextWindowUnits = (event) => {
        var config = {...model};
        if(event.target.value){
            config['context_window_units'] = event.target.value;
        }else{
            config['context_window_units'] = null;
        }
        setModel({...config});
    };

    const handlePredictionWindowUnits = (event) => {
        var config = {...model};
        if(event.target.value){
            config['prediction_window_units'] = event.target.value;
        }else{
            config['prediction_window_units'] = null;
        }
        setModel({...config});
    };

    const handleSensitivity = (event) => {
        var config = {...model};
        config['sensitivity'] = event.target.value;
        setModel({...config});
    }

    const resetSensitvity = () => {
        var config = {...model};
        config['sensitivity'] = 'auto';
        setModel({...config});
    }
    
    const resetContextWindow = () => {
        var config = {...model};
        config['context_window'] = 'auto';
        setModel({...config});
    }

    const resetPredictionWindow = () => {
        var config = {...model};
        config['prediction_window'] = 'auto';
        setModel({...config});
    }
    
    return (
        <Box
        sx={{
            border: '1px solid rgba(0, 0, 0, 0.1)',
            backgroundColor:'white',
            marginTop:'20px'
        }}
        >
        <Box
        sx={{
            padding:'15px',
            paddingLeft:'20px',
            display:'flex',
            gap:'10px',
            alignItems:'center'
        }}
        >
            <ArrowCircleRightIcon sx={{fill:'rgba(47, 128, 237, 1)'}}/>
            <Typography variant='h7' fontSize='0.9rem' fontWeight='bold'>Model input parameters</Typography>
        </Box>
        <Divider/>
        <Box
            sx={{
                display:'flex',
                gap:'20px',
                padding:'20px',
                flexDirection:'column'
            }}
        >
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    width:'100%',
                    gap:'5px'
                }}
            >
                <Typography variant='h7' fontSize='0.9rem'>Context window</Typography>
                <Typography variant='caption'>The time frame of data used by the model to learn trends</Typography>
                {
                    model['context_window'] === 'auto' ?
                    <Select
                        labelId="select-label"
                        value={model['context_window']}
                        onChange={handleContextWindow}
                        fullWidth
                    >
                        <MenuItem value="auto">Auto</MenuItem>
                        <MenuItem value="input">Input</MenuItem>
                    </Select> :
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'row',
                            gap:'5px',
                            width:'60%'
                        }}
                    >
                        <TextField
                            defaultValue={1}
                            value={model['context_window']}
                            onChange={handleContextWindow}
                            type='number'
                            size='small'
                            sx={{
                                width:'100px'
                            }}
                        />
                        <FormControl sx={{width:'80%'}} size='small'>
                            <Select
                                defaultValue={'hour'}
                                value={model['context_window_units']}
                                onChange={handleContextWindowUnits}
                            >
                            <MenuItem value={'second'}>Second</MenuItem>
                            <MenuItem value={'minute'}>Minute</MenuItem>
                            <MenuItem value={'hour'}>Hour</MenuItem>
                            <MenuItem value={'day'}>Day</MenuItem>
                            <MenuItem value={'week'}>Week</MenuItem>
                            <MenuItem value={'month'}>Month</MenuItem>
                            </Select>
                        </FormControl>
                        <IconButton
                            sx={{m:0, p:0}}
                            onClick={resetContextWindow}
                        >
                            <CancelIcon/>
                        </IconButton>
                    </Box>
                }
            </Box>
            {
                model['model_objective'] == 'Predictive' &&
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        width:'100%',
                        gap:'5px'
                    }}
                >
                    <Typography variant='h7' fontSize='0.9rem'>Prediction window</Typography>
                    <Typography variant='caption'>The window of time the model will detect if the target event will occur</Typography>
                    {
                        model['prediction_window'] === 'auto' ?
                        <Select
                            labelId="select-label"
                            value={model['prediction_window']}
                            onChange={handlePredictionWindow}
                            fullWidth
                        >
                            <MenuItem value="auto">Auto</MenuItem>
                            <MenuItem value="input">Input</MenuItem>
                        </Select> :
                        <Box
                            sx={{
                                display:'flex',
                                flexDirection:'row',
                                gap:'5px',
                                width:'60%'
                            }}
                        >
                            <TextField
                                defaultValue={1}
                                value={model['prediction_window']}
                                onChange={handlePredictionWindow}
                                type='number'
                                size='small'
                                sx={{
                                    width:'100px'
                                }}
                            />
                            <FormControl sx={{width:'80%'}} size='small'>
                                <Select
                                    defaultValue={'hour'}
                                    value={model['prediction_window_units']}
                                    onChange={handlePredictionWindowUnits}
                                >
                                <MenuItem value={'second'}>Second</MenuItem>
                                <MenuItem value={'minute'}>Minute</MenuItem>
                                <MenuItem value={'hour'}>Hour</MenuItem>
                                <MenuItem value={'day'}>Day</MenuItem>
                                <MenuItem value={'week'}>Week</MenuItem>
                                <MenuItem value={'month'}>Month</MenuItem>
                                </Select>
                            </FormControl>
                            {/* <IconButton
                                sx={{m:0, p:0}}
                                onClick={resetPredictionWindow}
                            >
                                <CancelIcon/>
                            </IconButton> */}
                        </Box>
                    }
                </Box>
            }
            {
                model['model_objective'] == 'Anomaly Detection' &&
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        width:'100%',
                        gap:'5px'
                    }}
                >
                    <Typography variant='h7' fontSize='0.9rem'>Sensitivity (1 - 100)</Typography>
                    <Typography variant='caption'>Larger sensitivity values will lessen the threshold to flag anomalies</Typography>
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'row',
                            gap:'15px',
                        }}
                    >
                        {
                            model['sensitivity'] === 'auto' ?
                            <Select
                                labelId="select-label"
                                value={model['sensitivity']}
                                onChange={handleSensitivity}
                                fullWidth
                            >
                                <MenuItem value="auto">Auto</MenuItem>
                                <MenuItem value="input">Input</MenuItem>
                            </Select> :
                            <Box
                                sx={{
                                    display:'flex',
                                    alignItems:'center',
                                    gap:'10px'
                                }}
                            >
                                <TextField
                                    defaultValue={1}
                                    type='number'
                                    size='small'
                                    sx={{width:'100px'}}
                                    value={model['sensitivity']}
                                    onChange={handleSensitivity}
                                />
                                <IconButton
                                    sx={{m:0, p:0}}
                                    onClick={resetSensitvity}
                                >
                                    <CancelIcon/>
                                </IconButton>
                            </Box>
                        }
                    </Box>
                </Box>
            }
        </Box>
        </Box>
    )
}
