import { useEffect } from 'react';
import useToken from './use-token';

import { API_URL } from '../api';

const useHealthCheck = (onInvalidToken) => {
    const { token, removeToken, setToken } = useToken();

    useEffect(() => {
        const checkHealth = async () => {
        try {
            const response = await fetch(API_URL + "/api/tokenStatus", {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            });

            if (!response.ok) {
            removeToken();
            onInvalidToken();
            }
        } catch (error) {
            removeToken();
            onInvalidToken();
        }
        };

        checkHealth(); // Check immediately
        const intervalId = setInterval(checkHealth, 120000); // Check every 10 seconds

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [token, removeToken, onInvalidToken]);
};

export default useHealthCheck;