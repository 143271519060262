import * as React from "react";
import { SENSOR_ICONS, GROUP_COLORS } from "./constants";
import SensorsIcon from "@mui/icons-material/Sensors";


export function useSelectedSensorConfig(selectedSensor, groupColorsMap, setColor, setIcon) {
    React.useEffect(() => {
        if (selectedSensor) {
          var __sensorIcon = <SensorsIcon />;
          Object.keys(SENSOR_ICONS).forEach((type) => {
            if (selectedSensor.description.includes(type)) {
              __sensorIcon = SENSOR_ICONS[type];
            }
          });
          setIcon(__sensorIcon);
    
          var __sensorColor = "black";
          if(selectedSensor.tag_group_name && groupColorsMap[selectedSensor.tag_group_name]){
            __sensorColor = groupColorsMap[selectedSensor.tag_group_name];
          }
          setColor(__sensorColor);
        }
    }, [selectedSensor]);
}

export function useGroupColorsConfig(groupsList, setGroupColorsMap){
    React.useEffect(() => {
        var _groupColors = {};
        var i = 0;
        for(var group of groupsList){
          _groupColors[group] = GROUP_COLORS[i];
          i += 1;
          if(i > 3){
            i = 0;
          }
        }
        setGroupColorsMap({..._groupColors});
    }, [groupsList])
}

export function useFilterList(sensorList, searchQuery, setFilteredEvents){
    React.useEffect(() => {
        var filtered = [];
        if(searchQuery){
          for(const sensor of sensorList){
            if(sensor.name.toLowerCase().includes(searchQuery.toLowerCase()))
            {
              filtered.push(sensor);
            }
          }
        }else{
          filtered = [...sensorList];
        }
        setFilteredEvents([...filtered]);
    
    }, [searchQuery, sensorList])
}