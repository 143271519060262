import { Popper, Box, Typography, Tooltip  } from "@mui/material";
import ArticleIcon from '@mui/icons-material/Article';
import SensorsIcon from "@mui/icons-material/Sensors"
import EventIcon from '@mui/icons-material/Event';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import SensorCard from "./sensor-card";
import EventCard from "./event-card"
import DeploymentCard from "./deployment-card";
import DocumentCard from "./document-card";

import { COLORS } from "../../constants";

import { useEffect, useState, cloneElement } from "react";

export default function ResourceList({
    setMainTab,
    visibility,
    setVisibility,
    sendMaxeHiddenQuery,
    resources,
    monitor,
    open,
    resourcesButtonRef,
    hasDocument,
    setInputPageNumber
}){
    const [resourceLists, setResourceLists] = useState({
        sensors:[],
        events:[],
        deployments:[],
        documents:[]
    });
    const [tab, setTab] = useState('Sensors');

    useEffect(() => {
        var lists = {
            sensors:[],
            events:[],
            deployments:[],
            documents:[]
        };
        resources.sensors.forEach((sensorId, idx) => {
            for(const sensor of monitor.sensors){
                if(sensor.sensor_id === sensorId){
                    var config = {...sensor};
                    config['color'] = COLORS[idx];
                    lists.sensors.push(config);
                }
            }
        })

        resources.events.forEach((eventId, idx) => {
            for(const event of monitor.events){
                if(event.event_id === eventId){
                    var config = {...event};
                    config['color'] = COLORS[idx + monitor.sensors.length];
                    lists.events.push(config);
                }
            }
        })

        resources.deployments.forEach((modelId, idx) => {
            for(const deployment of monitor.deployments){
                console.log(deployment);
                if(deployment.model_id === modelId){
                    var config = {...deployment};
                    config['color'] = COLORS[idx + monitor.sensors.length + monitor.events.length];
                    lists.deployments.push(config);
                }
            }
        })

        if(hasDocument){
            lists.documents.push({
                'name': monitor.document_filename,
                'date_uploaded': monitor.creation_time
            })
        }
        setResourceLists({...lists});
    }, [resources, monitor, hasDocument])

    const TabButton = ({name}) => {
        const icon = name === 'Sensors' ? <SensorsIcon/> : name === 'Events' ? <EventIcon/> : name === 'Deployments' ? <RocketLaunchIcon/> : <ArticleIcon/>;

        return (
            <Tooltip title={name} placement="top">
                <Box
                    sx={{
                        padding:'5px',
                        borderRadius:'5px',
                        '&:hover':{
                            backgroundColor:tab === name ? 'black' : 'rgba(55,70,91,0.2)'
                        },
                        backgroundColor: tab === name ? 'black' : 'white'
                    }}
                    onClick={() => setTab(name)}
                >
                    {
                        tab === name ?
                        cloneElement(icon, { sx: {fill:'white', height:'20px'} }) :
                        cloneElement(icon, { sx: {height:'20px'} })
                    }
                </Box>
            </Tooltip>
        )
    }

    return (
        <Popper
            placement={'bottom-start'}
            open={open}
            anchorEl={resourcesButtonRef}
        >
            <Box
                sx={{
                    backgroundColor:'rgba(0,0,0,0.7)',
                    borderRadius:'10px'
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center'
                    }}
                >
                    <Typography padding='10px' fontWeight='bold' color='white' fontSize='13px'>
                        Resources
                    </Typography>
                    <Box
                        sx={{
                            display:'flex',
                            gap:'5px',
                            padding:'10px'
                        }}
                    >
                        {
                            resourceLists.sensors.length > 0 &&
                            <TabButton name={'Sensors'}/>
                        }
                        {
                            resourceLists.events.length > 0 &&
                            <TabButton name={'Events'}/>
                        }
                        {
                            resourceLists.deployments.length > 0 &&
                            <TabButton name={'Deployments'}/>
                        }
                        {
                            hasDocument &&
                            <TabButton name={'Documents'}/>
                        }
                    </Box>
                </Box>
                <Box
                    sx={{
                        maxHeight:'320px',
                        width:'260px',
                        overflow:'scroll',
                        padding:'4px',
                        gap:'4px',
                        display:'flex',
                        flexDirection:'column'
                    }}
                >
                    {
                        tab === 'Sensors' ?
                        resourceLists.sensors.map((sensor) => (
                            <SensorCard
                                setMainTab={setMainTab}
                                sensor={sensor}
                                visibility={visibility}
                                setVisibility={setVisibility}
                                sendMaxeHiddenQuery={sendMaxeHiddenQuery}
                                color={sensor.color}
                            />
                        )) : tab === 'Events' ?
                        resourceLists.events.map((event) => (
                            <EventCard
                                setMainTab={setMainTab}
                                event={event}
                                visibility={visibility}
                                setVisibility={setVisibility}
                                sendMaxeHiddenQuery={sendMaxeHiddenQuery}
                                color={event.color}
                            />
                        )) : tab === 'Deployments' ?
                        resourceLists.deployments.map((deployment) => (
                            <DeploymentCard
                                setMainTab={setMainTab}
                                deployment={deployment}
                                visibility={visibility}
                                setVisibility={setVisibility}
                                sendMaxeHiddenQuery={sendMaxeHiddenQuery}
                                color={deployment.color}
                            />
                        )) : resourceLists.documents.map((document) => (
                            <DocumentCard
                                setMainTab={setMainTab}
                                manual={document}
                                setInputPageNumber={setInputPageNumber}
                            />
                        ))
                    }
                </Box>
            </Box>

        </Popper>
    )
}