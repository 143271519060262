import { useState } from 'react';

import { Box, Typography, IconButton } from "@mui/material";

import SensorsIcon from "@mui/icons-material/Sensors";
import CircleIcon from '@mui/icons-material/Circle';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";


export default function MonitorOverviewMenu({monitor, getActiveTab, activeTab}){
    const MenuTab = ({title, count, activeTab}) => {
        const active = (activeTab === title);
        return (
            <Box
                sx={{
                    borderTop: active ? '3px solid #4643D4' : '3px solid white',
                    borderRight: active ? '1px solid rgba(0, 0, 0, 0.15)' : 'none',
                    borderLeft: active ? '1px solid rgba(0, 0, 0, 0.15)' : 'none',
                    height:'35px',
                    backgroundColor: active ? 'white' : 'none',
                    borderTopLeftRadius:'4px',
                    borderTopRightRadius:'4px',
                    alignItems:'center',
                    display:'flex',
                    padding:'20px',
                    pt:'10px',
                    pb:'10px',
                    gap:'8px',
                    '&:hover':{
                        borderTop: active ? '3px solid (70, 67, 212, 1)' : '3px solid rgba(70, 67, 212, 0.2)',
                        color:'rgba(70, 67, 212, 0.8)'
                    },
                    cursor:'pointer',
                    color: active ? '#4643D4' : 'black',
                }}
                onClick={() =>  getActiveTab(title)}
            >
                <Typography
                    sx={{
                        fontSize:'14px'
                    }}
                >
                    {title}
                </Typography>
                <Box
                    sx={{
                        backgroundColor:'rgba(95, 107, 124, 0.1)',
                        padding:'5px',
                        borderRadius:'5px'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize:'11px'
                        }}
                    >
                        {
                            title === 'Sensors' ?
                            monitor.sensors.length :
                            title === 'Events' ?
                            monitor.events.length :
                            title === 'Deployments' ?
                            monitor.deployments.length :
                            1
                        }
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                borderBottom:'1px solid rgba(0, 0, 0, 0.15)',
                pl: '48px',
                pr: '48px',
                height:'40px',
                pt:'12px',
                pb:'8px',
                display:'flex',
                alignItems:'center',
            }}
        >
            <Box
                sx={{
                    display:'flex',
                    gap:'16px',
                    alignItems:'center',
                    justifyContent:'space-between',
                    width:'100%'
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        gap:'8px',
                        marginRight:'30px'
                    }}
                >
                    {
                        monitor &&
                        <>
                            <MenuTab title={'Events'} count={monitor.events.length} activeTab={activeTab}/>
                            <MenuTab title={'Sensors'} count={monitor.sensors.length} activeTab={activeTab}/>
                            <MenuTab title={'Deployments'} count={monitor.deployments.length} activeTab={activeTab}/>
                        </>
                    }
                </Box>
                <IconButton
                    sx={{p:0, m:0}}
                >
                    <SettingsIcon sx={{height:'40px', fill:'#4F4F4F'}}/>
                </IconButton>

            </Box>
        </Box>
    );
}