import Box from "@mui/material/Box";
import {
  Grid,
  Divider,
  Typography,
  Button,
  LinearProgress,
  TextField,
  ListItemButton,
} from "@mui/material";
import {
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";


import CustomCheckBox from "../custom-check-box";

import { SENSOR_ICONS } from "../../constants";

export function HeaderCell ({ col }) {
    return (
        <TableCell
        sx={{
            padding: 0,
        }}
        >
        <Typography fontSize="0.8rem" variant="body1">
            {col}
        </Typography>
        </TableCell>
    );
};

export function SensorRow({ sensor, selectSensor, selectedSensor, groupColorsMap }){
    var isSelected = false;
    if (sensor.sensor_id == selectedSensor.sensor_id) {
      isSelected = true;
    }

    var sensorIcon = <SensorsIcon />;
    Object.keys(SENSOR_ICONS).forEach((type) => {
      if (sensor.description.includes(type)) {
        sensorIcon = SENSOR_ICONS[type];
      }
    });

    var sensorColor = "black";
    if(sensor.tag_group_name && groupColorsMap[sensor.tag_group_name]){
      sensorColor = groupColorsMap[sensor.tag_group_name];
    }

    return (
      <TableRow
        sx={{
          "&:hover": {
            cursor: "pointer",
            backgroundColor: isSelected
              ? "rgba(70, 67, 212, 0.08)"
              : "rgba(134, 134, 134, 0.08)",
          },
          backgroundColor: isSelected ? "rgba(70, 67, 212, 0.08)" : "white",
        }}
      >
        <TableCell
          sx={{
            p: '10px',
            paddingTop: "16px",
            paddingBottom: "16px",
          }}
        >
            <CustomCheckBox sx={{p:0, m:0}} />
        </TableCell>
        <TableCell
          sx={{
            p: 0,
            paddingTop: "16px",
            paddingBottom: "16px",
          }}
          onClick={() => selectSensor(sensor)}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "20px",
                width: "20px",
                borderRadius: "3px",
                color: "white",
                backgroundColor: sensorColor,
                "& .MuiSvgIcon-root": { fontSize: "14px" },
              }}
            >
              {sensorIcon}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0px",
                width:'200px',
                overflow:'wrap',
                wordWrap: 'break-word',
              }}
            >
              <Typography
                variant="body1"
                lineHeight="20px"
                fontSize="14px"
                fontWeight="600"
              >
                {sensor.name}
              </Typography>
              <Typography variant="body1" fontSize="12px">
                {sensor.description}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell  align='left' sx={{ mr: '10px', paddingRight:'40px' }} onClick={() => selectSensor(sensor)}>
          <Typography fontSize="14px" fontWeight="bold">
            {sensor.tag_id}
          </Typography>
        </TableCell>
        <TableCell sx={{ p: 0 }} onClick={() => selectSensor(sensor)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0px",
              maxWidth:'150px',
              overflow:'wrap',
              wordWrap: 'break-word',
            }}
          >
            <Typography fontSize="14px">{sensor.tag_group_name}</Typography>
          </Box>
        </TableCell>
        <TableCell sx={{ p: 0 }} onClick={() => selectSensor(sensor)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0px",
              maxWidth:'150px',
              overflow:'wrap',
              wordWrap: 'break-word',
            }}
          >
            <Typography fontSize="14px">{sensor.measuring_point}</Typography>
          </Box>
        </TableCell>
      </TableRow>
    );
  };