import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Box, Divider, Typography, Button, IconButton, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ReactMarkdown from 'react-markdown';
import CancelIcon from '@mui/icons-material/Cancel';
import LoadingSpinner from '../../../../components/loading-spinner';

import { getModel, getModelResults, getModelResultsStatus } from '../../../../api';

import { STEP_MAP } from '../constants';

export default function TrainingLogs({jobStarted}) {
    const {modelId} = useParams('modelId')

    const [trainingStatus, setTrainingStatus] = React.useState(null);
    const [model, setModel] = React.useState({
        'model_id': null,
    });
    const [trainLogs, setTrainLogs] = React.useState(null);

    const getResults = async () => {
        const modelResults = await getModelResults(model);
        setTrainLogs(modelResults['training_logs']);
        setTrainingStatus(modelResults['training_status']);
    }

    React.useEffect(() => {
        const _getModel = async () => {
            const _model = await getModel(modelId);
            setModel({..._model});
        };
        _getModel();
    }, [modelId])

    React.useEffect(() => {
        if(model.model_id){
            const getTrainingLogs = async () => {
                getResults();
            }
            getTrainingLogs();
            const intervalId = setInterval(getTrainingLogs, 10000);
            if(trainingStatus && ((trainingStatus == 'success') || (trainingStatus == 'failure') || (trainingStatus == 'complete'))){
                clearInterval(intervalId);
            }
            return () => clearInterval(intervalId);
        }
    }, [model, trainingStatus, jobStarted])

    console.log(trainingStatus);

    const ProgressDescription = ({stepName, step}) => {
        return (
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    gap:'5px'
                }}
            >
                <Box
                    sx={{
                    position: 'relative',
                    width: '130px',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}
                >
                    <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: (stepName === 'Initialize') ? '50%' : 0,
                        right: (stepName === 'Complete!') ? '50%' : 0,
                        height: '1px',
                        backgroundColor: (((STEP_MAP[step] > STEP_MAP[trainingStatus]) && (trainingStatus !== 'complete')) || !trainingStatus)  ? 'lightgrey' : trainingStatus === 'failure' ? 'red' : 'rgba(20, 174, 92, 1)',
                        transform: 'translateY(-50%)',
                    }}
                    />
                    {
                        !trainingStatus ? <CircleIcon
                            sx={{
                                fill:'lightgrey',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'white', // Optional: to give a background to the icon
                                borderRadius: '50%',     // Optional: to make the background circular
                            }}
                        /> :
                        trainingStatus == 'complete' ? <CheckCircleIcon
                        sx={{
                        fill:'rgba(20, 174, 92, 1)',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white', // Optional: to give a background to the icon
                        borderRadius: '50%',     // Optional: to make the background circular
                        }}/> :
                        trainingStatus == 'failure' ? <CancelIcon
                        sx={{
                        fill:'red',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white', // Optional: to give a background to the icon
                        borderRadius: '50%',     // Optional: to make the background circular
                        }}/> :
                        (STEP_MAP[step] == STEP_MAP[trainingStatus])  ?
                        <LoadingSpinner isLoading={true} size={30}/> :
                        (STEP_MAP[step] > STEP_MAP[trainingStatus]) ?
                        <CircleIcon
                            sx={{
                                fill:'lightgrey',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'white', // Optional: to give a background to the icon
                                borderRadius: '50%',     // Optional: to make the background circular
                            }}
                        /> :
                        <CheckCircleIcon
                            sx={{
                            fill:'rgba(20, 174, 92, 1)',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'white', // Optional: to give a background to the icon
                            borderRadius: '50%',     // Optional: to make the background circular
                        }}
                        />
                    }
                </Box>
                <Typography color='rgba(30, 30, 30, 1)' fontSize='0.8rem'>
                    {stepName}
                </Typography>
            </Box>
        )
    }

    const ProgressBar = ({step}) => {
        return (
            <Box
                sx={{
                    width:'40px',
                    height:'2px',
                    backgroundColor:(STEP_MAP[step] >= STEP_MAP[trainingStatus])  ? 'lightgrey' : 'rgba(20, 174, 92, 1)'
                }}
            ></Box>
        )
    }

  return (
        <Box sx={{}}>
            <Box
                sx={{
                    display:'flex',
                    alignItems:'center',
                    flexWrap:'wrap'
                }}
            >   
                <ProgressDescription step={'init'} stepName={'Initialize'}/>
                <ProgressDescription step={'analyze'} stepName={'Analyze Data'}/>
                <ProgressDescription step={'arch-tune'} stepName={'Select Architecture'}/>
                <ProgressDescription step={'train'} stepName={'Fine-tune Parameters'}/>
                <ProgressDescription step={'fine-tune'} stepName={'Train model'}/>
                <ProgressDescription step={'complete'} stepName={'Complete!'}/>
            </Box>
            {
                trainingStatus &&
                <Box
                    sx={{
                        backgroundColor:'white',
                        border:'1px solid lightgrey',
                        marginTop:'20px',
                    }}
                >
                    <Box
                        sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            padding:'10px'
                        }}
                    >
                        <Box
                            sx={{
                                display:'flex',
                                gap:'2px',
                                alignItems:'center'
                            }}
                        >
                            <IconButton sx={{p:0, m:0}}><ArrowDropDownIcon sx={{fill:'black'}}/></IconButton>
                            <Typography variant='h7' fontSize='0.9rem' fontWeight='bold'>Training Logs</Typography>
                        </Box>
                        <Button
                            sx={{
                                color:'rgba(75, 64, 221, 1)',
                                textTransform:'none',
                                '&:hover': {
                                    backgroundColor: 'rgba(114, 110, 255, 0.1)', // Hover background color
                                },
                            }}
                            variant='text'
                            startIcon={<DownloadIcon/>}
                        >
                            Download .csv 
                        </Button>
                    </Box>
                    <Divider/>
                    {
                        trainLogs &&
                        <Box
                            sx={{
                                pl:'40px',
                                pt:'5px',
                                height:'100px',
                                overflow:'scroll'
                            }}
                        >
                            <ReactMarkdown children={trainLogs.replace(/\n/g, '  \n')}/>
                        </Box>
                    }
                </Box>
            }
        </Box>
    );
}