import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Box } from "@mui/material";
import "chartjs-adapter-dayjs-4";
import zoomPlugin from 'chartjs-plugin-zoom';

import { COLORS } from "../../constants"

import dayjs from 'dayjs';

// Register the necessary chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin, zoomPlugin);

const PredictiveTSGraph = ({ color, predictionData, targetEventData, target}) => {
  const [chartData, setChartData] = useState(null);
  const [options, setOptions] = useState(null);
  useEffect(() => {
    if(predictionData && target){
      const targetDataType = target['data_type'];
      const targetId = target[targetDataType + '_id'];
      const targetKey = `${targetDataType}_${targetId}`;

      var _chartData = {
        datasets: [
            {
              label:target.name,
              borderColor:color,
            },
            {
            //   label: data.title,
              label:'% Chance',
              data: predictionData.map((data, idx) => ({x:data.time, y:data.probability})),
              fill: false,
              borderColor: 'grey',
              tension: 0.1,
              yAxisID: 'y',
              borderWidth:1,
              // pointBackgroundColor: predData.map((data) => (data.correct ? 'green' : 'red'))
            },
        ]
      };

      var _options = {
        responsive: true,
        // maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: true,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'xy',
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'xy',
            },
          },
          title: {
            display: false,
          },
          annotation: {
            annotations: [
              ...targetEventData.map((e) => (
                {
                  type: 'box', // Draw a rectangle
                  xMin: e.start_time, // Start time of the e
                  xMax: e.end_time, // End time of the event
                  backgroundColor: color.replace('0.6', '0.25'),
                  borderColor: color
                  
                  // borderWidth: 2,
                }
              )),
            ]
          },
        },
        scales: {
          x: {
            type: 'time', // Time series on the X axis
            time: {
                unit: 'hour',
            },
          },
        },
        // onHover: (event, chartElement) => {
        //   const time = dayjs(event.chart.scales.x.getValueForPixel(event.x))
        //   var inBox = false;
        //   for(const event of eventData){
        //     const start = dayjs(event.start_time);
        //     const end = dayjs(event.end_time);
        //     if((time >= start) && (time <= end)){
        //       setHoverEvent(event);
        //       inBox = true;
        //       break;
        //     }
        //   }
        // },
      };
        setChartData({..._chartData});
        setOptions({..._options});
    }

  }, [predictionData, target])

  return (
    <Box style={{width:'100%', height:'350px', alignItems:'center', display:'flex', justifyContent:'center'}}>
      {
        (chartData && options) &&
        <Line sx={{p:0, m:0}} data={chartData} options={options} />
      }
    </Box>
  );
};

export default PredictiveTSGraph;
