import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import { Box, Divider } from "@mui/material";

import ModelBuilderHeader from './components/header';
import AlertModal from './components/alert-modal';
import TrainingDataConfig from './components/training-data-config';
import ModelConfig from './components/model-config';
import TSGraph from './components/timeseries-graph';
import DateConfig from './components/date-config';

import { getModel, getTrainingDataset, saveTrainingDataset, getSensors, saveModel, getEvents, getModels } from '../../../api';
import { COLORS } from './constants';

export default function ModelBuilder({}){
    const {modelId} = useParams('modelId')
    const [model, setModel] = useState({
        'model_id': '',
        'name': '',
        'model_type': '',
        'model_objective': '',
    });
    const [trainingSet, setTrainingSet] = useState({
        'training_set_id': null,
        'name': '',
        'sensor_config': [],
    });
    const [hasAlert, setHasAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({'message':'', 'severity':''});
    const [sensorList, setSensorList] = useState([]);
    const [eventsList, setEventsList] = useState([]);
    const [deploymentList, setDeploymentList] = useState([]);

    const [visibility, setVisibility] = useState({});
    const [tab, setTab] = useState('data');
    const [startDate, setStartDate] = useState(dayjs(model['start_time']));
    const [endDate, setEndDate] = useState(dayjs(model['end_time']));
    const [graphStatus, setGraphStatus] = useState({});
    const [graphStart, setGraphStart] = useState(dayjs().subtract(7, 'days'));
    const [graphEnd, setGraphEnd] = useState(dayjs());
    const [sensorColors, setSensorColors] = useState({});


    useEffect(() => {
        var map = {};
        trainingSet.sensor_config.forEach((config, idx) => {
            const idCol = `${config['data_type']}_id`;
            const key =  `${config.data_type}_${config[idCol]}`
            map[key] = COLORS[idx];
        })
        setSensorColors(map);
    }, [trainingSet])

    const saveTrainingSet = async () => {
        const success = await saveTrainingDataset(trainingSet);
        if(success){
            setAlertMessage({...{
                'message': 'Success!',
                'severity': 'success'
            }})
        }else{
          setAlertMessage({...{
            'message': 'Failed to save training set',
            'severity': 'error'
          }})
        }
        setHasAlert(true);
    };

    const saveModelConfig = async () => {
        const success = await saveModel(modelId, model, 1, startDate, endDate);
        if(success){
            setAlertMessage({...{
                'message': 'Success!',
                'severity': 'success'
            }})
        }else{
            setAlertMessage({...{
                'message': 'Failed to save model',
                'severity': 'error'
            }})
        }
        setHasAlert(true);
    };

    useEffect(() => {
        const _getTrainingSet = async () => {
            const _trainingSet = await getTrainingDataset(modelId);
            setTrainingSet(_trainingSet);
            var _visibility = {};
            _trainingSet['sensor_config'].forEach((sensor) => {
                const idCol = `${sensor['data_type']}_id`;
                if(sensor['variable_type'] == 'target'){
                    _visibility[`${sensor['data_type']}_${sensor[idCol]}`] = true;
                }else{
                  _visibility[`${sensor['data_type']}_${sensor[idCol]}`] = false;
                }
            })
            setVisibility({..._visibility})
        }
        if(modelId){
            _getTrainingSet();
        }
    }, [modelId])

    useEffect(() => {
        const _getModel = async () => {
            const _model = await getModel(modelId);
            setModel(_model);
            setStartDate(dayjs(_model['start_time']));
            setEndDate(dayjs(_model['end_time']));
        }
        if(modelId){
            _getModel();
        }
    }, [modelId])

    useEffect(() => {
        const _getSensors = async () => {
          const sensors = await getSensors();
          setSensorList([...sensors]);
        };
        _getSensors();
    }, [])

    useEffect(() => {
        const _getEvents = async () => {
            const events = await getEvents();
            setEventsList([...events]);
        }
        _getEvents();
    }, [])

    useEffect(() => {
        const _getModels = async () => {
            var deployments = [];
            const models = await getModels();
            models.forEach((model) => {
                if(model.is_deployed === 'true'){
                    model['deployment_id'] = model['model_id'];
                    deployments.push(model);
                }
            })
            setDeploymentList(deployments);
        }
        _getModels();
    }, [])

    useEffect(() => {
        var status = {...graphStatus};
        var ids = trainingSet['sensor_config'].map((sensor) => (sensor['sensor_id']));
        var hasChange = false;
        ids.forEach((id) => {
          if(id && !Object.keys(status).includes(id.toString())){
            status[id] = false;
            hasChange = true;
          }
        })
        if(hasChange){
          setGraphStatus({...status})
        }
    }, [trainingSet])

    return (
        <Box sx={{minHeight:'100vh'}}>
            <AlertModal hasAlert={hasAlert} setHasAlert={setHasAlert} alertMessage={alertMessage}/>
            {
                (model) &&
                <ModelBuilderHeader tab={tab} setTab={setTab} model={model} save={tab === 'data' ? saveTrainingSet : saveModelConfig}/>
            }
            <Divider/>
            <Box sx={{display:'flex', width:'100%', height:'100%',}}>
                {/* Data & Model Config */}
                <Box
                    sx={{
                        width:'40%',
                        maxWidth:'40%',
                        backgroundColor:'rgba(247, 247, 247, 1)',
                        minHeight:'95vh'
                    }}
                >
                    {
                        tab === 'data' ?
                        <TrainingDataConfig model={model} setModel={setModel} deploymentList={deploymentList} eventsList={eventsList} sensorList={sensorList} visibility={visibility} setVisibility={setVisibility} trainingSet={trainingSet} setTrainingSet={setTrainingSet} sensorColors={sensorColors}/> :
                        <ModelConfig startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} model={model} setModel={setModel}/>
                    }
                </Box>
                <Divider orientation='vertical' flexItem/>
                <Box
                    sx={{
                        width:'60%',
                        height:'75vh',
                        padding:'20px'
                    }}
                >
                    <Box
                        sx={{
                            width:'60%'
                        }}
                    >
                        <DateConfig graphStart={graphStart} graphEnd={graphEnd} getStartDate={setGraphStart} getEndDate={setGraphEnd}/>
                    </Box>
                    <TSGraph
                        sensorConfig={trainingSet['sensor_config']}
                        graphStatus={graphStatus}
                        visibility={visibility}
                        startDate={graphStart}
                        endDate={graphEnd}
                        sensorColors={sensorColors}
                        samplingFrequency={model['sampling_frequency']}
                        samplingFrequencyUnits={model['sampling_frequency_units']}
                    />
                </Box>
            </Box>
        </Box>
    )
};