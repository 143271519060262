import { Typography, Box, Divider, IconButton } from "@mui/material";
import FindInPageIcon from '@mui/icons-material/FindInPage';

import VariableCard from "./variable-card";

import { COLORS } from "../../constants"
import SensorsIcon from '@mui/icons-material/Sensors';
import EventIcon from '@mui/icons-material/Event';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SettingsIcon from "@mui/icons-material/Settings";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import TSGraph from "./ts-graph";
import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

export default function Workspace({sendMaxeHiddenQuery, startDate,  endDate, sensors, events, deployments, sensorData, eventsData, deploymentsData, colors}){
    const [visibility, setVisibility] = useState(null);
    useEffect(() => {
        var _visibility = {sensors:{}, events:{}, deployments:{}};
        sensors.forEach((s) => {
            _visibility.sensors[s.sensor_id] = false;
        })
        events.forEach((e) => {
            _visibility.events[e.event_id] = false;
        })
        deployments.forEach((d) => {
            _visibility.deployments[d.deployment_id] = false;
        })
        setVisibility(_visibility);
    }, [sensors, events, deployments])
    
    const DataSection = ({icon, section, data, idxOffset}) => {
        const [show, setShow] = useState(true);

        return (
            <Box
                sx={{
                display:'flex',
                backgroundColor:'white',
                flexDirection:'column'
                }}
            >
                <Box
                    sx={{
                        padding:'15px',
                        pr:'10px',
                        pl:'10px',
                        display:'flex',
                        alignItems:'center',
                        gap:'5px',
                        backgroundColor:'rgba(77,77,77,0.05)',
                        border:'1px solid lightgrey',
                        shadow:4,
                        justifyContent:'space-between'
                    }}
                >
                    <Box
                        sx={{
                            display:'flex',
                            alignItems:'center',
                        }}
                    >
                        {icon}
                        <Typography fontSize='12px' color='rgb(78,78,78)'>{section}</Typography>
                    </Box>
                    <IconButton onClick={() => setShow(!show)} sx={{p:0, m:0}}>
                        {
                            show ?
                            <UnfoldLessIcon/>
                            :
                            <ArrowDropDownIcon/>
                        }
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        // maxHeight:'300px',
                        // overflow:'scroll'
                    }}
                >
                    {
                        (visibility && show) &&
                        data.map((d, idx) => (
                            <VariableCard sendMaxeHiddenQuery={sendMaxeHiddenQuery} visibility={visibility} setVisibility={setVisibility} config={d} color={COLORS[idx + idxOffset]}/>
                        ))
                    }
                </Box>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                width:'100%',
            }}
        >
            <Box
                sx={{
                    display:'flex'
                }}
            >
                <Box
                    sx={{
                        width:'25%',
                        display:'flex',
                        flexDirection:'column',
                        maxHeight:'calc(100vh - 50px)',
                        overflow:'scroll'
                    }}
                >
                    <Box
                        sx={{
                            padding:'15px',
                            pr:'10px',
                            pl:'10px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'space-between',
                            gap:'5px',
                            backgroundColor:'white'
                        }}
                    >
                        <IconButton
                            sx={{p:0, m:0}}
                        >
                            <SettingsIcon sx={{height:'20px', fill:'rgb(20,20,20)'}}/>
                        </IconButton>
                        <IconButton
                            sx={{p:0, m:0}}
                        >
                            <CloseFullscreenIcon sx={{height:'20px', fill:'rgb(20,20,20)'}}/>
                        </IconButton>
                    </Box>
                    <DataSection icon={<SensorsIcon sx={{height:'20px', fill:'rgb(78,78,78)'}}/>} section={'Sensors'} data={sensors} idxOffset={0}/>
                    <Divider flexItem flexDirection='horiztonal'/>
                    <DataSection icon={<EventIcon sx={{height:'20px', fill:'rgb(78,78,78)'}}/>} section={'Events'} data={events} idxOffset={sensors.length}/>
                    <Divider flexItem flexDirection='horiztonal'/>
                    <DataSection icon={<RocketLaunchIcon sx={{height:'20px', fill:'rgb(78,78,78)'}}/>} section={'Deployments'} data={deployments} idxOffset={sensors.length + events.length}/>
                </Box>
                <Divider orientation="vertical" flexItem/>
                <Box
                    sx={{
                        display:'flex',
                        width:'75%',
                    }}
                >
                    <TSGraph
                        visibility={visibility}
                        sensors={sensors}
                        events={events}
                        deployments={deployments}
                        sensorData={sensorData}
                        eventData={eventsData}
                        deploymentData={deploymentsData}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Box>
            </Box>
        </Box>
    )
}