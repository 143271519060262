import { useState, useEffect } from 'react';

import { Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';


export default function TypedText({text, idx}){
    return (
        <ReactMarkdown>{text.trim().substring(0,idx)}</ReactMarkdown>
    )
}