import * as React from "react";
import {useState, useEffect} from "react";
import Box from "@mui/material/Box";
import {
  Divider,
  Modal,
  Typography,
  Grid,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StormIcon from "@mui/icons-material/Storm";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import RockwellFactoryTalkLogo from '../assets/rockwell-factory-talk.png';
import GeLogo from '../assets/ge.png';
import HoneywellLogo from '../assets/honeywell.png';
import OsiPiLogo from '../assets/osi-pi.png';
import SiemensLogo from '../assets/siemens.png';
import HistoriainSuccess from '../assets/historian-success.png';

import LoadingSpinner from "../../../../components/loading-spinner";

import { useNavigate } from "react-router-dom";



import { createModel, createTrainingDataset, getHistorianMetadata, getSensors } from "../../../../api";
import IngestSensorsTable from "./ingest-sensors-table";

export default function NewHistorianModal({ open, handleModalState }) {
  const navigate = useNavigate();

  const options = {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const now = new Date();
  const formattedDate = now
    .toLocaleDateString("en-US", options)
    .replace(",", "")
    .split(",")[0];
  const time = now
    .toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .replace(" ", "");
  const [name, setName] = React.useState(
    `New Model - ${formattedDate} at ${time}`
  );
  const handleNameChange = (event) => {
        setName(event.target.value);
  };

  const [desc, setDesc] = React.useState(null);
  const handleDescChange = (newDesc) => {
    setDesc(newDesc);
  };

  const [tab, setTab] = React.useState(1);
  const handleNextTab = () => {
    var _tab = tab;  
    setTab(_tab + 1);
  }
  const handlePrevTab = () => {
    var _tab = tab;  
    setTab(_tab - 1);
  }

  const [historianType, setHistorianType] = useState(null);
  const [historianLogo, setHistorianLogo] = useState(null);
  const [authType, setAuthType] = useState(null);

  const HistorianDetailsTab = ({}) => {
    return (
        <Box>
            <Box
                sx={{
                    padding: "24px",
                    paddingBottom: "15px",
                    paddingTop: "20px",
                    display: "flex",
                    gap: "5px",
                    flexDirection: "column",
                }}
            >
                <Typography color="rgba(79, 79, 79, 1)">
                    Name<span style={{ color: "rgba(255,20,20)" }}>*</span>
                </Typography>
                <TextField
                    autoComplete="off"
                    placeholder="Enter name..."
                    size="small"
                    sx={{ width: "100%" }}
                    variant="outlined"
                />
            </Box>
            <Box
                sx={{
                    padding: "24px",
                    paddingBottom: "15px",
                    paddingTop: "5px",
                    display: "flex",
                    gap: "5px",
                    flexDirection: "column",
                }}
            >
            <Typography color="rgba(79, 79, 79, 1)">
                Description<span style={{ color: "rgba(255,20,20)" }}>*</span>
            </Typography>
            <TextField
                autoComplete="off"
                placeholder="Add description..."
                size="small"
                sx={{ width: "100%" }}
                variant="outlined"
                multiline
                rows={3}
            />
            </Box>
        </Box>
    )
  }

  const HistorianTypeSelectorTab = ({}) => {
        const HistorianCard = ({name, company, logo}) => {
            const handleClick = () => {
                setHistorianLogo(logo);
                setHistorianType(name);
            }

            return (
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        gap:'5px',
                        alignItems:'center',
                        justifyContent:'center',
                        padding:'20px',
                        border: historianType === name ? '1px solid #4643D4' : '1px solid rgba(78,78,78,0.3)',
                        '&:hover':{
                            border: '1px solid #4643D4',
                        },
                        borderRadius:'8px'
                    }}
                    onClick={handleClick}
                >
                    <img src={logo} height='28px'/>
                    <Typography variant='h4' fontSize='16px'>
                        {name}
                    </Typography>
                    <Typography variant='h4' fontSize='14px'>
                        <span style={{color:'#828282'}}>By</span> {company}
                    </Typography>
                </Box>
            )
        }

        const HistorianTypesList = [
            {
                logo: RockwellFactoryTalkLogo,
                name: 'Factory Talk',
                company: 'Rockwell Automation'
            },
            {
                logo: GeLogo,
                name: 'Proficy',
                company: 'GE Digital'
            },
            {
                logo: HoneywellLogo,
                name: 'Uniformance',
                company: 'Honeywell'
            },
            {
                logo: OsiPiLogo,
                name: 'PI Vision',
                company: 'OSIsoft',
            },
            {
                logo: SiemensLogo,
                name: 'SIMATIC',
                company: 'Siemens'
            },
        ]

      return (
        <Box
            sx={{padding:'40px'}}
        >
            <Grid container spacing={'16px'}>
                {
                    HistorianTypesList.map((historian) => (
                        <Grid item xs={6}>
                            <HistorianCard name={historian.name} company={historian.company} logo={historian.logo}/>
                        </Grid>
                    ))
                }

            </Grid>
        </Box>
      )
  }

  const HistorianAuthenticateTab = ({}) => {
    const AuthenticateTypeOption = ({option, idx}) => {
        return (
            <Box
                sx={{
                    display:'flex',
                    backgroundColor: authType === option ? 'white' : 'rgba(247, 247, 247, 0.5)',
                    alignItems:'center',
                    gap:'5px',
                    padding:'10px',
                    borderRadius: idx !== 1 ? '8px' : '0px',
                    borderLeft: idx === 1 ? '0.5px solid rgba(77, 77, 77, 0.8)' : 'none',
                    borderRight: idx === 1 ? '0.5px solid rgba(77, 77, 77, 0.8)' : 'none',
                    '&:hover': {
                        backgroundColor:'white'
                    },
                    cursor:'pointer'
                }}
                onClick={() => setAuthType(option)}
            >
                {
                    authType === option ?
                    <RadioButtonCheckedIcon sx={{fill:'#4643D4'}}/> :
                    <RadioButtonUncheckedIcon sx={{fill:'#4F4F4F'}}/>
                }
                <Typography color={authType === option ? '#4643D4' : 'black'} fontSize='18px'>{option}</Typography>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                padding:'28px',
                display:'flex',
                flexDirection:'column',
                gap:'20px'
            }}
        >
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    gap:'10px',
                }}
            >
                <Typography color='#4F4F4F' fontSize='16px'>Authentication type</Typography>
                <Box
                    sx={{
                        borderRadius:'8px',
                        border:'0.5px solid rgba(77, 77, 77, 0.8)'
                    }}
                >
                    <Grid container spacing={'0px'}>
                        {
                            ['OAuth', 'BASIC', 'SAML'].map((auth, idx) => (
                                <Grid item xs={4}>
                                    <AuthenticateTypeOption idx={idx} option={auth}/>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Box>
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    gap:'10px',
                }}
            >
                <Typography color='#4F4F4F' fontSize='16px'>Connection URL</Typography>
                <TextField autoComplete="off" placeholder={'www.historian.com'} size='small'/>
            </Box>
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    gap:'10px',
                }}
            >
                <Typography color='#4F4F4F' fontSize='16px'>Username</Typography>
                <TextField autoComplete="off"  size='small'/>
            </Box>
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    gap:'10px',
                }}
            >
                <Typography color='#4F4F4F' fontSize='16px'>Password</Typography>
                <TextField autoComplete="off" type={'password'} size='small'/>
            </Box>
        </Box>
    )
  }

  const HistorianConnectionStatusTab = ({}) => {
    const [showStatus, setShowStatus] = useState(false);
    
    useEffect(() => {
        const handleLoad = () => {
            const timer = setTimeout(() => {
                setShowStatus(true);
              }, 3000); // Close after 5 seconds
          
            return () => clearTimeout(timer);
        };
        handleLoad();
    }, [])

    return (
        <Box
          sx={{
              minHeight:'300px',
              height:'100%',
              width:'100%',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              gap:'14px'
          }}
        >
            {
                !showStatus ? 
                <LoadingSpinner isLoading={true} size={'40px'}/> :
                
                <Box
                    sx={{
                        height:'100%',
                        width:'100%',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        flexDirection:'column',
                        gap:'14px'
                    }}
                >
                <Box
                    sx={{marginLeft:'30px'}}
                >
                    <img src={HistoriainSuccess} height='160px'/>
                </Box>
                <Typography variant='h3' fontWeight='bold' fontSize='18px' color='#24252A'>Connection Successful</Typography>
                <Box
                    sx={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        flexDirection:'column',
                        gap:'1px'
                    }}
                >
                    <Typography fontSize='14px' color='#24252A'>Your {historianType} Historian is connected and</Typography>
                    <Typography fontSize='14px' color='#24252A'><span style={{
                            fontWeight:'bold',
                            background:'linear-gradient(90deg, #4643D4, #09C878)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}>16 sensors</span> were found. Ingest sensors to use</Typography>
                    <Typography fontSize='14px' color='#24252A'>them in models and monitor configurations.</Typography>
                </Box>
                <Button
                    sx={{
                        background: 'linear-gradient(90deg, black, black)',
                        color: 'white',
                        padding: '10px 30px',
                        borderRadius: '8px',
                        textTransform: 'none',
                        '&:hover': {
                        background: 'linear-gradient(90deg, black, black)', // Reverse gradient on hover
                        },
                    }}
                    endIcon={<ArrowForwardIcon/>}
                    onClick={handleNextTab}
                >
                    Ingest sensors
                </Button>
                <Box
                    sx={{
                        paddingTop:'40px',
                        paddingBottom:'10px',
                        cursor:'pointer',
                    }}
                >
                    <Typography
                        sx={{
                            '&:hover':{
                                fontWeight:'bold'
                            }
                        }}
                    >Skip, bring me to my Historian</Typography>
                </Box>
                </Box>
            }
        </Box>
        )
    }

    const [sensors, setSensors] = useState([]);
    useEffect(() => {
        const _getSensors = async () => {
            const _sensors = await getHistorianMetadata(1);
            setSensors(_sensors);
        }
        _getSensors();
    }, []);

    const [selectedSensors, setSelectedSensors] = useState([]);

    const handleSelectSensor = (sensor) => {
        var _selected = [...selectedSensors];
        if(!_selected.map((s) => s.sensor_id).includes(sensor.sensor_id)){
            _selected.push(sensor);
        }else{
            var _selected = [];
            selectedSensors.forEach((s) => {
                if(s.sensor_id !== sensor.sensor_id){
                    _selected.push(s);
                }
            })
        }
        setSelectedSensors([..._selected]);
    }

    const handleSelectAllSensors = (selected) => {
        if(selectedSensors.length < sensors.length){
            setSelectedSensors(selected);
        }else if(selected.length === selectedSensors.length){
            setSelectedSensors([]);
        }
    }

    const IngestSensorsTab = ({}) => {
        return (
            <Box>
                <IngestSensorsTable selectAll={handleSelectAllSensors} sensors={sensors} selectSensor={handleSelectSensor} selectedSensors={selectedSensors}/>
                <Divider/>
                <Box
                    sx={{
                        padding:'20px',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'space-between',

                    }}
                >
                    <Typography fontSize='14px'><span style={{fontWeight:'bold', color:'#4643D4'}}>{selectedSensors.length}</span> of {sensors.length} sensors will be ingested</Typography>
                    <Box
                        sx={{
                            display:'flex',
                            gap:'10px'
                        }}
                    >
                        <Button
                            sx={{
                                background: 'white',
                                color: '#212B38',
                                padding: '10px 20px',
                                borderRadius: '8px',
                                textTransform: 'none',
                                '&:hover': {
                                background: 'rgba(200, 200, 200, 0.5)', // Reverse gradient on hover
                                },
                                border:'1px solid rgba(77, 77, 77, 0.5)'
                            }}
                            onClick={() => handleModalState(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{
                                background: 'linear-gradient(90deg, #7975FF, #5853F5)',
                                color: 'white',
                                padding: '10px 20px',
                                borderRadius: '8px',
                                textTransform: 'none',
                                '&:hover': {
                                background: 'linear-gradient(90deg, #5853F5, #7975FF)', // Reverse gradient on hover
                                },
                            }}
                            onClick={() => handleModalState(false)}
                        >
                            Ingest sensors
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }

  return (
    <Modal
      open={open}
      onClose={() => handleModalState(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: tab < 5 ? "500px" : '700px',
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: 24,
          maxHeight:'90vh',
          overflow:'scroll'
        }}
      >
          <Box
            sx={{
                display:'flex',
                flexDirection:'column'
            }}
          >
                <Box
                    sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        padding:'20px',
                    }}
                >
                    <Box>
                    {
                        (tab > 1 && tab < 5) ?
                        <IconButton
                            sx={{
                            margin: 0,
                            padding: 0,
                            }}
                        >
                            <ArrowBackIcon sx={{fontSize:'28px'}} onClick={handlePrevTab} />
                        </IconButton> :
                        tab === 5 ?
                        <Box
                            sx={{
                                display:'flex',
                                flexDirection:'column',
                                gap:'10px'
                            }}
                        >
                            <Typography fontWeight='bold'>Select sensors to ingest</Typography>
                            <Box
                                sx={{
                                    display:'flex',
                                    alignItems:'center',
                                    gap:'5px'
                                }}
                            >
                                <Typography sx={{fontSize:'14px', fontWeight:'bold'}}>{sensors.length} available sensors</Typography>
                                <Typography sx={{fontSize:'14px'}}>|</Typography>
                                <Typography sx={{fontSize:'14px'}}>You can always ingest more sensors later</Typography>

                            </Box>
                        </Box>
                        : <Box></Box>
                    }
                    </Box>
                    <IconButton
                        sx={{
                        margin: 0,
                        padding: 0,
                        }}
                    >
                        <CancelIcon sx={{fontSize:'28px'}} onClick={() => handleModalState(false)} />
                    </IconButton>
                </Box>
                {
                    tab <= 2 ?
                    <Box
                        sx={{
                            display:'flex',
                            justifyContent:'center'
                        }}
                    >
                        <Typography fontSize='24px' variant='h3'>{tab === 1 ? 'Connect new historian' : 'Select historian type'}</Typography>
                    </Box> :
                    tab === 3 ?
                    <Box
                        sx={{
                            display:'flex',
                            justifyContent:'center',
                            flexDirection:'column',
                            alignItems:'center',
                        }}
                    >
                        <Box
                            sx={{display:'flex', gap:'10px', alignItems:'center'}}
                        >
                            <Typography fontSize='24px'><span style={{color:'#24252A'}}>Connect to</span></Typography>
                            <img height='28px' src={historianLogo}/>
                            <Typography fontSize='24px'><span style={{fontWeight:'bold'}}>{historianType}</span></Typography>
                        </Box>
                        <Typography fontSize='14px' color='#24252A'>Authenticate to access historian data</Typography>
                    </Box> :
                    <Box></Box>
                }
                {
                    tab === 1 ? <HistorianDetailsTab/> :
                    tab === 2 ? <HistorianTypeSelectorTab/> :
                    tab === 3 ? <HistorianAuthenticateTab/> :
                    tab === 4 ? <HistorianConnectionStatusTab/> :
                    <IngestSensorsTab/>
                }
                {
                    tab < 5 &&
                    <Box
                        sx={{
                            display:'flex',
                            justifyContent:'center',
                            padding:'24px'
                        }}
                    >
                        {
                            tab <= 2 ?
                            <Button
                                sx={{
                                    background: 'linear-gradient(90deg, #7975FF, #5853F5)',
                                    color: 'white',
                                    padding: '10px 20px',
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                    '&:hover': {
                                    background: 'linear-gradient(90deg, #5853F5, #7975FF)', // Reverse gradient on hover
                                    },
                                    width:'100%'
                                }}
                                endIcon={<ArrowForwardIcon/>}
                                onClick={handleNextTab}
                            >
                                Next
                            </Button> :
                            tab === 3 ?
                            <Button
                                sx={{
                                    background: 'linear-gradient(90deg, #27AE60, #27AE60)',
                                    color: 'white',
                                    padding: '10px 20px',
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                    '&:hover': {
                                    background: 'linear-gradient(90deg, #27AE60, #27AE60)', // Reverse gradient on hover
                                    },
                                    width:'100%'
                                }}
                                onClick={handleNextTab}
                            >
                                Connect
                            </Button>
                            :
                            <Box></Box>

                        }
                    </Box>
                }
          </Box>
      </Box>
    </Modal>
  );
}
