import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Table, TableHead, TableCell, TableBody, TableRow } from '@mui/material';
import "chartjs-adapter-dayjs-4";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
  Title,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Typography } from '@mui/material';

import dayjs from "dayjs";

// Register Chart.js components and plugins
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
  Title,
  annotationPlugin // Register the annotation plugin
);

const RecordsTSGraph = ({events, startTime, endTime}) => {
  const data = {
    datasets: [
        {
            data: events.map((e) => (
                {
                    x:e.time,
                    y:e.value
                }
            )),
            pointRadius:10,
            backgroundColor: 'rgba(255,99,132,1)',
            type: 'scatter',
            showLine: false,
        },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'time', // Time series on the X axis
        time: {
          unit: 'day',
        },
        min:startTime.toISOString(),
        max:endTime.toISOString()
      },
      y: {
        type: 'linear',
        position: 'left',
      },
    },
    plugins: {
        legend: {
            display: false,  // This will remove the legend
        },
        title: {
          display: false,  // This will remove the title
        },
    },
  };



  var _columns = Object.keys(events[0]).sort();
  var columns = ['start_time', 'end_time'];
  for(const col of _columns){
    if((col != 'start_time') & (col != 'end_time')){
      columns.push(col);
    }
  }

  return (
      <div style={{width:'100%'}}>
        <Line data={data} options={options} />
      </div>
  );
};

export default RecordsTSGraph;
