import { Box, Typography } from "@mui/material";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

export default function TimeRangeSelector({setEndDate, setStartDate, startDate, endDate}){
    return (
        <Box
            sx={{
            borderBottom: "1px solid lightgrey",
            backgroundColor: "white",
            }}
        >
        <Box
          sx={{
            backgroundColor: "white",
            height: "48px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
            paddingRight: "20px",
            gap: "8px",
          }}
        >
          <CalendarMonthIcon fontSize="16px" />
          <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
            Time Range
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            padding: "0px 20px 16px 20px",
          }}
        >
          <Typography sx={{ fontSize: "12px" }}>
            Start date & time
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5px",
              backgroundColor: "white",
              marginBottom: "12px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                slotProps={{ textField: { size: "small" } }}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                slotProps={{ textField: { size: "small" } }}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
            </LocalizationProvider>
          </Box>
          <Typography sx={{ fontSize: "12px" }}>
            End date & time
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5px",
              backgroundColor: "white",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                slotProps={{ textField: { size: "small" } }}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                slotProps={{ textField: { size: "small" } }}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>
    )
}