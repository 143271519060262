import { Box, Divider, Typography, Button, Drawer } from "@mui/material";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import StormIcon from "@mui/icons-material/Storm";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import BugReportIcon from '@mui/icons-material/BugReport';

export default function ModelObjectiveIcon({model, flexDirection, iconSize, textFontSize}){
    return (
        <Box
        sx={{
          display: "flex",
          flexDirection:flexDirection,
          alignItems: "center",
          gap: "5px",
        }}
      >
            {model.model_objective === "Root Cause Analysis" ? (
            <BugReportIcon
                sx={{ color: "rgba(33, 150, 122, 1)", fontSize: iconSize }}
            />
            ) : model.model_objective === "Forecasting" ? (
            <StormIcon
                sx={{ color: "rgba(33, 150, 122, 1)", fontSize: iconSize }}
            />
            ) : model.model_objective === "Optimization" ? (
            <TroubleshootIcon sx={{ color: "#0047AB", fontSize: iconSize }} />
            ) : model.model_objective === 'Predictive' ? (
            <BuildCircleIcon sx={{ color: "rgb(33, 118, 122)", fontSize: iconSize }} />
            ) : (
            <CircleNotificationsIcon
                sx={{ color: "rgba(199, 94, 0, 1)", fontSize: iconSize }}
            />
            )}
            <Typography
                color={
                    model.model_objective === 'Predictive' ?
                    'rgb(33, 118, 122)' :
                    model.model_objective === "Forecasting"
                    ? "rgba(33, 150, 122, 1)"
                    : model.model_objective === "Optimization"
                    ? "#0047AB"
                    : "rgba(199, 94, 0, 1)"
                }
                fontSize={textFontSize}
                fontWeight="600"
                variant="body1"
            >
                {model.model_objective}
            </Typography>
        </Box>
    )
}