import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from "@mui/material";

import ModelHeader from '../components/model-header';
import ModelSandboxConfiguration from './components/configuration';
import AnomalyDetectionTSGraph from './components/anomaly-testing-graph';
import DeployModal from './components/deploy-modal';
import AlertModal from './components/alert-modal';

import { getModel, getTrainingDataset, getAnomalyModelDeployment, createModelDeployment, getPredictiveModelDeployment } from '../../../api';

import dayjs from 'dayjs';
import PredictiveTSGraph from './components/predictive-sandbox-graph';

const CURRENT_TIME = '2024-06-23T23:00:00';

export default function ModelSandboxV2({}) {
    const { modelId } = useParams("modelId");
    const [model, setModel] = useState({
        model_id:null,
        name:null,
    });
    const [trainingSet, setTrainingSet] = useState(null)

    const [timeFrame, setTimeFrame] = useState(1);
    const [timeFrameUnits, setTimeFrameUnits] = useState('week');

    const [target, setTarget] = useState([]);
    const [inputs, setInputs] = useState([]);

    const [testActuals, setTestActuals] = useState([]);
    const [testAnomalies, setTestAnomalies] = useState([]);
    const [testProbability, setTestProbability] = useState([]);
    const [testFutureProbability, setTestFutureProbability] = useState([]);

    const [deploy, setDeploy] = useState(false);

    const [alert, setAlert] = useState({message:'', severity:''});
    const [hasAlert, setHasAlert] = useState(false);

    useEffect(() => {
        const _getModel = async () => {
            const _model = await getModel(modelId);
            setModel(_model);
        }
        _getModel();
    }, [modelId])

    useEffect(() => {
        const _getTrainingSet = async () => {
            const _trainingSet = await getTrainingDataset(modelId);
            setTrainingSet(_trainingSet);
        }
        _getTrainingSet();
    }, [modelId])

    useEffect(() => {
        var _target = [];
        var _inputs = [];
        if(trainingSet){
            trainingSet.sensor_config.map((config) => (
                config.variable_type === 'target' ? _target.push(config) : _inputs.push(config)
            ))
        }
        setTarget(_target);
        setInputs(_inputs);
    }, [trainingSet])

    const getResults = async () => {
        const endDate = dayjs(CURRENT_TIME);
        const startDate = endDate.subtract(timeFrame, timeFrameUnits);
        if(model.model_objective === 'Anomaly Detection'){
            const {actuals, anomalies} = await getAnomalyModelDeployment(model, startDate, endDate);
            setTestActuals(actuals);
            setTestAnomalies(anomalies);
        }else if(model.model_objective === 'Predictive'){
            const {actuals, probability, future_probability} = await getPredictiveModelDeployment(model, startDate, endDate);
            setTestActuals(actuals);
            setTestProbability(probability);
            setTestFutureProbability(future_probability);
        }
    }

    const handleCreateDeployment = async () => {
        setDeploy(false);
        setAlert({
            message:'Creating deployment...',
            severity:'warning'
        })
        setHasAlert(true);
        const result = await createModelDeployment(model);
        if(result['success']){
            setHasAlert(true);
            setHasAlert(false);
            setAlert({
                message:'Deployment created!',
                severity:'success'
            })
            setHasAlert(true);
        }else{
            setHasAlert(true);
            setHasAlert(false);
            setAlert({
                message:'Deployment creation failed',
                severity:'failure'
            })
            setHasAlert(true);
        }
        const _model = await getModel(modelId);
        setModel(_model);
    }

    useEffect(() => {
        if(model.model_id){
            getResults();
        }
    }, [model])

    return (
        <Box>
            <ModelHeader model={model} tab={'Sandbox'}/>
            <AlertModal hasAlert={hasAlert} setHasAlert={setHasAlert} alertMessage={alert} />
            <DeployModal open={deploy} setOpen={setDeploy} handleCreateDeployment={handleCreateDeployment} />
            <Box sx={{display:'flex'}}>
                <Box sx={{
                    width:'360px',
                    height:"calc(100vh - 44px)",
                }}>
                    <ModelSandboxConfiguration model={model} setDeploy={setDeploy} target={target} inputs={inputs} timeFrame={timeFrame} timeFrameUnits={timeFrameUnits} setTimeFrame={setTimeFrame} setTimeFrameUnits={setTimeFrameUnits} getResults={getResults}/>
                </Box>
                <Box
                    sx={{
                        width:'calc(100vw - 460px)',
                        padding:'20px'
                    }}
                >
                    {
                        model.model_objective === 'Anomaly Detection' ?
                        <AnomalyDetectionTSGraph actuals={testActuals} anomalies={testAnomalies}/>
                        : <PredictiveTSGraph actuals={testActuals} probability={testProbability} futureProbability={testFutureProbability}/>
                    }
                </Box>
            </Box>
        </Box>
    );
};