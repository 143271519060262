import React, { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput, Box } from "@mui/material";

const PageSelector = ({pageNumber, setPageNumber}) => {

  const handlePageChange = (event) => {
    setPageNumber(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <FormControl
        sx={{
          minWidth: 120,
          backgroundColor: "white",
          borderRadius: 2,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        size="small"
      >
        <InputLabel id="page-selector-label">Page</InputLabel>
        <Select
          labelId="page-selector-label"
          value={pageNumber}
          onChange={handlePageChange}
          input={<OutlinedInput label="Page" />}
          sx={{
            borderRadius: 2,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: "rgba(0, 0, 0, 0.2)",
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: "#1976d2",
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: "#1976d2",
            },
          }}
        >
          {[1, 2, 3, 4].map((pn) => (
            <MenuItem key={pn} value={pn}>
              Page {pn}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default PageSelector;
