import * as React from 'react';
import Box from '@mui/material/Box';
import { Divider, Typography, Table, TableContainer, TableBody, TableRow, TableCell } from '@mui/material';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';

import { getSensors } from '../../../../api';

export default function SensorSelector({getSelectedSensor}) {
    const [selectedSensor, setSelectedSensor] = React.useState(null);
    const selectSensor = (sensor) => {
      setSelectedSensor(sensor);
      getSelectedSensor(sensor);
      setAnchor(null);
    }
    const [anchor, setAnchor] = React.useState(null);
    const openSensorList = Boolean(anchor);
    const handleClick = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
    };
    const SensorRow = ({sensor}) => {
        var isSelected = false;
        if(selectedSensor && (sensor.name == selectedSensor.name)){
          isSelected = true;
        }
        return (
          <TableRow
            sx={{
              '&:hover': {
                backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.08)' : 'rgba(134, 134, 134, 0.08)',
              },
              backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.08)' : 'white',
            }}
          >
            <TableCell
              sx={{
                p:'20px',
                paddingRight:'60px',
                paddingTop:'20px',
                paddingBottom:'15px',
              }}
              onClick={() => selectSensor(sensor)}
            >
              <Box
                sx={{
                  display:'flex',
                  gap:'10px',
                }}
              >
                <Box
                  sx={{
                    display:'flex',
                    flexDirection:'column',
                    gap:'5px'
                  }}
                >
                  <Typography variant='body1' fontSize='0.9rem'>{sensor.name}</Typography>
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{p:'10px'}}
              onClick={() => selectSensor(sensor)}
            >
              <Typography fontWeight='bold'>{sensor.tag_id}</Typography>
            </TableCell>
            <TableCell
              sx={{p:'10px'}}
              onClick={() => selectSensor(sensor)}
            >
              <Typography>{sensor.historian_name}</Typography>
            </TableCell>
          </TableRow>
        )
      }

    const [sensorList, setSensorList] = React.useState([]);
    React.useEffect(() => {
      const _getSensors = async () => {
        const sensors = await getSensors();
        setSensorList(sensors);
      };
      // Call the fetchData function
      _getSensors();
    }, [])

    return (
        <Box>
        <Box
            sx={{
                width:'120px',
                height:'30px',
                border:'1px solid lightgrey',
                borderRadius:'8px',
                paddingLeft:'20px',
                display:'flex',
                alignItems:'center',
            }}
            onClick={handleClick}
        >
            <Box>
                {!selectedSensor && <Typography fontSize='1rem' color='grey' >Select...</Typography>}
                {selectedSensor && <Typography fontSize='1rem' color='black' >{selectedSensor.name}</Typography>}
            </Box>
        </Box>
        <BasePopup strategy='fixed' disablePortal placement={'bottom'} open={openSensorList} anchor={anchor}>
            <Box
              sx={{
                  position: 'relative',
                  backgroundColor: 'white',
                  border: '1px solid rgba(0, 0, 0, 0.15)',
                  borderRadius: '8px',
              }}
            >
            <Box
                sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                }}
            >
                <Box
                sx={{
                    display:'flex',
                    alignItems:'center',
                    gap:'5px',
                    padding:'10px'
                }}
                >
                </Box>
                <Box
                sx={{
                    display:'flex',
                    alignItems:'center',
                    gap:'5px',
                    paddingRight:'5px',
                    padding:'5px',
                }}
                >
                <Box
                    sx={{
                        borderRadius:'5px',
                        padding:'5px',
                        backgroundColor: 'rgba(55, 70, 91, 0.08)',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        gap:'10px',
                        paddingLeft:'10px',
                        paddingRight:'10px',
                        boxShadow:1
                    }}
                    >
                        <Typography fontSize='0.9rem'>All historians</Typography>
                        <ArrowDropDownIcon fontSize='1.2rem'/>
                </Box>
                <Divider flexItem orientation='vertical'/>
                <CloseIcon onClick={handleClick} sx={{fill:'rgba(36, 37, 42, 1)'}}/>
                </Box>
            </Box>
            <Divider/>
            <TableContainer sx={{width:'500px', maxHeight:'400px', bgcolor: 'background.paper'}}>
            <Table>
                <TableBody sx={{backgroundColor:'white'}}>
                {
                    sensorList.map((sensor) => (
                    <SensorRow sensor={sensor}/>
                    ))
                }
                </TableBody>
            </Table>
            </TableContainer>
            </Box>
        </BasePopup>
        </Box>
    );
}
