import { Box, Typography, Divider, MenuItem, Select, TextField, FormControl } from "@mui/material";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

export default function TimeRangeSelector({timeFrame, timeFrameUnits, setTimeFrame, setTimeFrameUnits}){
    return (
      <Box
        sx={{
          margin: "10px",
          backgroundColor: "white",
          border: "1px solid lightgrey",
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            height: "50px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
            paddingRight: "20px",
            gap: "10px",
          }}
        >
          <CalendarMonthIcon />
          <Typography fontWeight="bold" variant="h8">
            Time Range
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            marginLeft: "20px",
            marginRight: "20px",
            padding: "10px",
          }}
        >
          <Typography>Last</Typography>
          <TextField
            value={timeFrame}
            onChange={(event) => setTimeFrame(event.target.value)}
            type="number"
            size="small"
          />
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              defaultValue={"week"}
              value={timeFrameUnits}
              onChange={(event) =>
                setTimeFrameUnits(event.target.value)
              }
            >
              <MenuItem value={"minute"}>Minute(s)</MenuItem>
              <MenuItem value={"hour"}>Hour(s)</MenuItem>
              <MenuItem value={"day"}>Day(s)</MenuItem>
              <MenuItem value={"week"}>Week(s)</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    )
}