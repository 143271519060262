import { useState, useEffect } from "react";

import { Box, Typography, IconButton, Divider, TableContainer, TableCell, TableRow, Table, TableBody, TableHead } from "@mui/material";

import { ArrowBack } from "@mui/icons-material";

import SensorsIcon from '@mui/icons-material/Sensors';
import EventIcon from '@mui/icons-material/Event';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

import { Visibility as VisibilityIcon, VisibilityOff } from '@mui/icons-material';

import dayjs from "dayjs";

export default function RcaPanel({setSelectedInput, selectedInput, dataColors, resourceImportance, visibility, setVisibility, setStartDate, setEndDate, metricImportance, rca, rcaExamples}){
    const dataType = selectedInput['data_type'];
    const idCol = `${dataType}_id`;
    const key = `${dataType}_${selectedInput[idCol]}`;
    const color = dataColors[key];
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const importance = resourceImportance[key]*100;

    const [metrics, setMetrics] = useState([]);
    const [selectedMetric, setSelectedMetric] = useState(null);

    useEffect(() => {
        const _metrics = Object.keys(metricImportance[key]);
        setMetrics(_metrics);
        if(_metrics.length > 0){
            setSelectedMetric(_metrics[0])
        }
    }, [metricImportance]);

    const handleAnalysisZoom = (date) => {
        const zoomDate = dayjs(date);
        setStartDate(zoomDate.subtract(8, 'hour'))
        setEndDate(zoomDate.add(8, 'hour'))
    };

    const ProgressBar = ({progress}) => {
        return (
            <Box
                sx={{
                    height:'10px',
                    backgroundColor:'#CDCDCD',
                    width:'100%',
                    borderRadius:'8px'
                }}
            >
                <Box
                    sx={{
                        width:`${progress.toLocaleString(0)}%`,
                        backgroundColor:'#4643D4',
                        height:'10px',
                        borderRadius:'8px'
                    }}
                />
            </Box>
        )
    }

    const ImportanceBar = ({progress, metric}) => {
        const barColor = rca[key][metric]['significant'] == '1' ? 'rgba(1,174,92,0.1)' : 'rgba(236,34,31,0.1)';
        const textColor = rca[key][metric]['significant'] == '1' ? 'rgba(1,174,92)' : 'rgba(236,34,31)';

        return (
            <Box
                sx={{
                    height:'30px',
                    backgroundColor:'#F7F7F7',
                    border:'0.5px solid rgba(0,0,0,0.2)',
                    width:'100%',
                }}
            >
                <Box
                    sx={{
                        width:`${progress.toLocaleString(0)}%`,
                        backgroundColor:barColor,
                        height:'30px',
                        border:`1px solid ${barColor}`,
                        borderRight:`2px solid ${textColor}`,
                        display:'flex',
                        justifyContent:'right',
                        alignItems:'center'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize:'14px',
                            fontWeight:'bold',
                            color:textColor,
                            marginRight:'5px'
                        }}
                    >
                        {progress.toFixed(0)}%
                    </Typography>
                </Box>
            </Box>
        )
    }

    const handleVisibility = () => {
        var _visibility = {...visibility};
        _visibility[key] = !_visibility[key];
        setVisibility({..._visibility});
      }
  
    const [visibile, setVisibile] = useState(false);
    useEffect(() => {
        const _visibile = visibility[key];
        setVisibile(_visibile);
    }, [visibility])

    const TabButton = ({name}) => {
        return (
            <Box
                sx={{
                    padding:'16px 10px',
                    '&:hover':{
                        color:'#4643D4',
                        borderBottom:'1px solid #4643D4'
                    },
                    borderBottom: name === 'Feature Analysis' ? '1px solid #4643D4' : 'none',
                    color: name === 'Feature Analysis' ? '#4643D4' : 'black',
                    cursor:'pointer'
                }}
            >
                <Typography
                    sx={{
                        fontSize:'15px',
                    }}
                >
                    {name}
                </Typography>
            </Box>
        )
    }

    const MetricImportance = ({metricName}) => {
        const metricImportanceVal = metricImportance[key][metricName];

        const metricImportancePct = ((metricImportanceVal / (importance / 100))) * 100;


        return (
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    gap:'10px',
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        gap:'8px',
                        alignItems:'center'
                    }}
                >
                    <Typography sx={{fontSize:'15px'}}>{metricName.toUpperCase().slice(0,1) + metricName.slice(1, metricName.length)}</Typography>
                    <Typography sx={{fontSize:'13px', color:'rgba(1,1,1,0.5)'}}>Warning range: ({(parseFloat(rca[key][metricName]['warning_mean']) - parseFloat(rca[key][metricName]['warning_std'])*0.2).toLocaleString('en-US')} to {(parseFloat(rca[key][metricName]['warning_mean']) + parseFloat(rca[key][metricName]['warning_std'])*0.2).toLocaleString('en-US')})</Typography>
                </Box>
                <ImportanceBar progress={metricImportancePct} metric={metricName}/>
            </Box>
        )
    }

    const [sortedExamples, setSortedExamples] = useState([]);
    useEffect(() => {
        if(rcaExamples && selectedMetric){
            var _sorted = rcaExamples[key][selectedMetric];
            console.log(_sorted);
            _sorted.sort((a,b) => new Date(b.time) - new Date(a.time));
            console.log(_sorted);
            setSortedExamples(_sorted);
        }
    }, [rcaExamples, selectedMetric]);


    return (
        <Box>
            <Box
                sx={{
                    display:'flex',
                    gap:'4px',
                    backgroundColor:'rgba(55,70,0,0.08)',
                    padding:'10px',
                }}
            >
                <IconButton
                    sx={{p:0, m:0}}
                    onClick={() => setSelectedInput(null)}
                >
                    <ArrowBack sx={{fill:'black'}}/>
                    <Typography color='black' fontSize='16px'>Back</Typography>
                </IconButton>
            </Box>
            <Divider/>
            <Box
                sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    padding:'20px'
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        gap:'10px'
                    }}
                >
                    <Box
                        sx={{
                        // width:'20px',
                        maxHeight:'25px',
                        borderRadius:'5px',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        padding:'2px',
                        backgroundColor:color
                        }}
                    >
                        {
                            selectedInput.data_type === 'sensor' ? <SensorsIcon sx={{height:'20px', fill:'white'}}/> :
                            selectedInput.data_type === 'event' ? <EventIcon sx={{height:'20px', fill:'white'}}/> :
                            <RocketLaunchIcon sx={{height:'20px', fill:'white'}}/> 
                        }
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                        }}
                    >
                        <Typography sx={{color:'#262626', fontSize:'14px'}}>{selectedInput.name}</Typography>
                        <Typography sx={{color:'#262626', fontSize:'12px'}}>{selectedInput.description}</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display:'flex',
                        gap:'3px',
                        width:'30%'
                    }}
                >
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            gap:'5px',
                            width:'80%'
                        }}

                    >
                        <Typography sx={{fontSize:'14px', fontWeight:'bold'}}>{parseFloat(importance).toFixed(2)}%</Typography>
                        <ProgressBar progress={importance}/>
                    </Box>
                    { 
                        visibile ?
                        <VisibilityIcon onClick={handleVisibility} sx={{cursor:'pointer', height:'20px'}}/>
                        : <VisibilityOff onClick={handleVisibility} sx={{height:'20px', cursor:'pointer'}}/>
                    }
                </Box>
            </Box>
            <Divider/>
            <Box
                sx={{
                    display:'flex',
                    paddingLeft:'14px',
                    gap:'5px'
                }}
            >
                <TabButton name={'Overview'}/>
                <TabButton name={'Feature Analysis'}/>
            </Box>
            <Divider/>
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    gap:'20px',
                    padding:'16px'
                }}
            >
                <Typography sx={{fontSize:'15px', fontWeight:'bold'}}>
                    Metric Importance
                </Typography>
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        gap:'10px'
                    }}
                >
                    {
                        metrics.map((metric) => (
                            <MetricImportance metricName={metric}/>
                        ))
                    }
                </Box>
            </Box>
            <Divider/>
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        padding:'5px',
                        alignItems:'center'
                    }}
                >
                    <Typography sx={{padding:'16px', fontSize:'15px', fontWeight:'bold'}}>
                        Past examples
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor:'rgba(144,144,144,0.1)',
                            padding:'4px',
                            display:'flex',
                            height:'20px',
                            border:'1px solid rgba(0,0,0,0.1)',
                            borderRadius:'4px'
                        }}
                    >
                        {
                            metrics.map((metric) => (
                                <Box
                                    sx={{
                                        backgroundColor: selectedMetric === metric ? 'white' : 'none',
                                        display:'flex',
                                        alignItems:'center',
                                        cursor:'pointer',
                                        padding:'4px 8px',
                                        border: selectedMetric === metric ? '1px solid rgba(0,0,0,0.1)' : 'none',
                                        borderRadius:'4px',
                                        color: selectedMetric === metric ? 'black' : 'rgba(144,144,144,0.5)',
                                        '&:hover':{
                                            color:'black'
                                        }
                                    }}
                                    onClick={() => setSelectedMetric(metric)}
                                >
                                    <Typography sx={{fontSize:'12px'}}>
                                        {metric.slice(0,1).toUpperCase() + metric.slice(1, metric.length)}
                                    </Typography>
                                </Box>
                            ))
                        }
                    </Box>
                </Box>
                <TableContainer sx={{borderTop:'0.5px solid rgba(0,0,0,0.5)', maxHeight:'200px', overflow:'scroll'}}>
                <Table>
                    <TableHead sx={{backgroundColor:'rgba(0,0,0,0.1)'}}>
                        <TableCell>
                            Time
                        </TableCell>
                        <TableCell>
                            Value
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableHead>
                    <TableBody>
                        {
                            sortedExamples.map((example, index) => (
                                <TableRow
                                    onMouseEnter={() => setHoveredRowIndex(index + 1)}
                                    onMouseLeave={() => setHoveredRowIndex(null)}
                                >
                                    <TableCell>
                                        <Typography variant='caption' sx={{color:'black'}}>
                                            {example['time'].substring(1,19)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='caption' color='black'>
                                            {parseFloat(example['value']).toFixed(5)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            height:'50px',
                                            width:'40px'
                                        }}
                                    >
                                        {
                                            (hoveredRowIndex && ((index + 1) === hoveredRowIndex)) &&
                                            <IconButton
                                                onClick={() => handleAnalysisZoom(example['time'])}
                                            ><TroubleshootIcon sx={{fill: 'black'}}/></IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
        </Box>
    )
}