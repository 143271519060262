import Box from "@mui/material/Box";
import {
  Grid,
  Divider,
  Typography,
  Button,
  LinearProgress,
  TextField,
  ListItemButton,
} from "@mui/material";
import {
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import CheckIcon from "@mui/icons-material/Check";

import CustomCheckBox from "../custom-check-box";

import { SENSOR_ICONS } from "../../constants";

export function HeaderCell ({ col }) {
    return (
        <TableCell
        sx={{
            padding: 0,
        }}
        >
        <Typography fontSize="0.8rem" variant="body1">
            {col}
        </Typography>
        </TableCell>
    );
};

export function SensorRow({ sensor, selectSensor, selectedSensors}){
    var isSelected = false;
    if(selectedSensors.map((s) => (s.sensor_id)).includes(sensor.sensor_id)) {
      isSelected = true;
    }

    return (
      <TableRow
        sx={{
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "rgba(134, 134, 134, 0.08)",
          },
          backgroundColor: "white",
        }}
      >
        <TableCell
          sx={{
            p: '10px',
            paddingTop: "16px",
            paddingBottom: "16px",
          }}
        >
          {
            isSelected ? (
              <Box
                sx={{
                  height: "18px",
                  width: "18px",
                  backgroundColor: "rgba(70, 67, 212, 1)",
                  border: "1px solid rgba(0, 0, 0, 0.24)",
                  borderRadius: "3px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => selectSensor(sensor)}
              >
                <CheckIcon sx={{ stroke: "white", fill: "white" }} />
              </Box>
            ) : (
              <Box
                sx={{
                  height: "18px",
                  width: "18px",
                  backgroundColor: "rgba(247, 247, 247, 1)",
                  border: "1px solid rgba(0, 0, 0, 0.24)",
                  borderRadius: "3px",
                }}
                onClick={() => selectSensor(sensor)}
              ></Box>
            )
          }
        </TableCell>
        <TableCell
          sx={{
            p: 0,
            paddingTop: "16px",
            paddingBottom: "16px",
          }}
          onClick={() => selectSensor(sensor)}
        >
              <Typography
                variant="body1"
                lineHeight="20px"
                fontSize="14px"
              >
                {sensor.tag_id}
              </Typography>
        </TableCell>
        <TableCell
          sx={{
            p: 0,
            paddingTop: "16px",
            paddingBottom: "16px",
          }}
          onClick={() => selectSensor(sensor)}
        >
            <Typography
                variant="body1"
                lineHeight="20px"
                fontSize="14px"
            >
              {sensor.name}
            </Typography>
        </TableCell>
        <TableCell
          sx={{
            p: 0,
            paddingTop: "16px",
            paddingBottom: "16px",
          }}
          onClick={() => selectSensor(sensor)}
        >
            <Typography
                variant="body1"
                lineHeight="20px"
                fontSize="14px"
            >
              {sensor.description}
            </Typography>
        </TableCell>
        <TableCell
          sx={{
            p: 0,
            paddingTop: "16px",
            paddingBottom: "16px",
          }}
          onClick={() => selectSensor(sensor)}
        >
            <Typography
                variant="body1"
                lineHeight="20px"
                fontSize="14px"
            >
              {sensor.unit}
            </Typography>
        </TableCell>
      </TableRow>
    );
  };