import * as React from "react";
import { Link } from "react-router-dom";

import { Box, Divider, Typography, Button, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";

import CreateModal from "./components/create-modal";
import ModelCard from "./components/model-card";
import SensorTag from "./components/sensor-tag";
import StreamTag from "./components/stream-tag";
import ModelObjectiveIcon from "../components/model-objective.icon";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from "@mui/icons-material/Cancel";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StormIcon from "@mui/icons-material/Storm";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import PsychologyIcon from "@mui/icons-material/Psychology";
import StarIcon from "@mui/icons-material/Star";
import SettingsIcon from "@mui/icons-material/Settings";

import { getModels, getModelResultsStatus, getModelResults, getModelResources } from "../../../api";
import { formatDateTime } from "./helpers";

const drawerWidth = 400;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    position: "relative",
  })
);

export default function MLFactoryHome({}) {
  const [modelList, setModelList] = React.useState([]);
  
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  
  const [selectedModel, setSelectedModel] = React.useState({ model_id: -1 });
  const [selectedModelData, setSelectedModelData] = React.useState({
    sensors: [],
    streams: [],
  });
  const [hasResults, setHasResults] = React.useState(false);
  const [trainResults, setTrainResults] = React.useState(null);

  const handleModelSelect = (model) => {
    if (model.model_id === selectedModel.model_id) {
      setOpenDrawer(false);
      setSelectedModel({ ...{ model_id: -1 } });
      setHasResults(false);
      setTrainResults(null);
    } else {
      setSelectedModel({ ...model });
      setOpenDrawer(true);
    }
  };

  React.useEffect(() => {
    const _getModels = async () => {
      const models = await getModels();
      setModelList([...models]);
    };
    _getModels();
  }, []);

  React.useEffect(() => {
    const checkForResults = async () => {
      const _hasResults = await getModelResultsStatus(selectedModel);
      setHasResults(_hasResults);
    };
    if (selectedModel.model_id !== -1) {
      checkForResults();
    }
  }, [selectedModel]);


  React.useEffect(() => {
    const getResults = async () => {
      console.log(selectedModel);
      const model_results = await getModelResults(selectedModel);
      setTrainResults({...model_results});
    };
    if (hasResults && (selectedModel.model_id !== -1)) {
      getResults();
    }
  }, [selectedModel, hasResults]);

  React.useEffect(() => {
    const getResources = async () => {
      const modelData = await getModelResources(selectedModel);
      setSelectedModelData({ ...modelData });
    };

    if (selectedModel.model_id !== -1) {
      getResources();
    }
  }, [selectedModel]);

  return (
    <Box>
      <CreateModal open={openCreate} handleModalState={setOpenCreate} />
      <Box sx={{ display: "flex" }}>
        <Main open={openDrawer}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "64px",
              paddingRight: "64px",
              paddingTop: "32px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ fontSize: "32px", lineHeight: "44px" }}
                variant="h4"
              >
                Models
              </Typography>
              <Button
                onClick={() => setOpenCreate(true)}
                color="success"
                startIcon={<AddIcon />}
                variant="contained"
              >
                New
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                height: "40px",
                paddingTop: "24px",
                paddingBottom: "24px",
              }}
            >
              <Box
                sx={{
                  flexGrow: "1",
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  gap: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "white",
                  boxShadow: 1,
                }}
              >
                <SearchIcon />
                <Typography>Search...</Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  backgroundColor: "white",
                  boxShadow: 1,
                }}
              >
                <Typography>Type</Typography>
                <ArrowDropDownIcon />
              </Box>
              <Box
                sx={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  backgroundColor: "white",
                  boxShadow: 1,
                }}
              >
                <Typography>Objective</Typography>
                <ArrowDropDownIcon />
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid lightgrey",
                overflow: "hidden",
              }}
            >
              {modelList.map((model) => (
                <ModelCard model={model} selectedModel={selectedModel} handleModelSelect={handleModelSelect}/>
              ))}
            </Box>
          </Box>
        </Main>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={openDrawer}
        >
          <Box
            sx={{
              width: "400px",
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
              minHeight: "100%",
              // boxShadow: 20,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "12px",
                justifyContent: "left",
                alignItems: "center",
                height: "64px",
                paddingLeft: "20px",
              }}
            >
              <Box
                sx={{
                  height: "30px",
                  width: "30px",
                  backgroundColor: "lightgrey",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PsychologyIcon />
              </Box>
              <Typography fontWeight="bold" variant="h7">
                {selectedModel.name}
              </Typography>
              <StarIcon sx={{ color: "gold" }} />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                height: "50px",
                padding: "10px",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "rgba(70, 67, 212, 1)",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "rgba(70, 67, 212, 0.8)", // Hover background color
                  },
                  width: "120px",
                }}
                variant="contained"
                component={Link}
                to={"/ml-factory/model-overview/" + selectedModel.model_id}
              >
                Open
              </Button>
              <Button
                sx={{
                  backgroundColor: "rgba(114, 110, 255, 0.16)",
                  color: "rgba(75, 64, 221, 1)",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "rgba(114, 110, 255, 0.5)", // Hover background color
                  },
                  width: "120px",
                }}
                component={Link}
                to={
                  selectedModel.model_type == "Targeted"
                    ? "/ml-factory/model-builder/" +
                      selectedModel.model_id
                    : "/ml-factory/model-builder/training-data/generalized/" +
                      selectedModel.model_id
                }
                variant="contained"
                startIcon={<SettingsIcon />}
              >
                Configure
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  borderColor: "black",
                  color: "black",
                  width: "50px",
                  "&:hover": {
                    borderColor: "black",
                    backgroundColor: "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                  },
                }}
                variant="outlined"
              >
                Share
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  borderColor: "black",
                  color: "black",
                  "&:hover": {
                    borderColor: "black",
                    backgroundColor: "rgba(0, 0, 0, 0.04)", // Slight background change on hover
                  },
                }}
                variant="outlined"
              >
                <Typography fontWeight="bold">...</Typography>
              </Button>
            </Box>
            <Divider />
            <Box
              sx={{
                padding: "20px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Details
              </Typography>
              <Box
                sx={{
                  paddingTop: "15px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "130px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                    Objective
                  </Typography>
                </Box>
                <ModelObjectiveIcon model={selectedModel} textFontSize='14px' iconSize='16px'/>
              </Box>
              <Box
                sx={{
                  paddingTop: "15px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "130px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                    Model Type
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">
                    {selectedModel.model_type}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingTop: "15px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "130px",
                    display: "flex",
                  }}
                >
                  <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                    Sensors
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "190px",
                    display: "flex",
                    alignItems: "top",
                    justifyContent: "top",
                    gap: "10px",
                    flexWrap: "wrap",
                    maxHeight:'100px',
                    overflow:'scroll'
                  }}
                >
                  {selectedModelData.sensors.map((sensor) => (
                    <SensorTag sensor={sensor} selectedModel={selectedModel}/>
                  ))}
                </Box>
              </Box>
              <Box
                sx={{
                  paddingTop: "15px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "130px",
                    display: "flex",
                  }}
                >
                  <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                    Training time range
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">
                    {formatDateTime(selectedModel.start_time)}{" "}
                    <span style={{ color: "rgba(79, 79, 79, 1)" }}>to</span>{" "}
                    {formatDateTime(selectedModel.end_time)}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingTop: "15px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "130px",
                    display: "flex",
                  }}
                >
                  <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                    Last trained
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "10px",
                  }}
                >
                  {(trainResults && trainResults.training_time) && (
                    <Typography variant="body2">
                      {formatDateTime(trainResults.creation_time)}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                padding: "20px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Deployment
              </Typography>
              <Box
                sx={{
                  paddingTop: "15px",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "130px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                    Active
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "2px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    borderRadius: "5px",
                  }}
                >
                  {
                    selectedModel.is_deployed !== 'true' ?
                    <CancelIcon sx={{ fill: "red", stroke: "white" }} /> :
                    <CheckCircleIcon sx={{ fill: "green", stroke: "white" }} /> 
                  }
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                padding: "20px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                Metadata
              </Typography>
              <Box
                sx={{
                  paddingTop: "15px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "130px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                    Creator
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">You</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingTop: "15px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "130px",
                    display: "flex",
                  }}
                >
                  <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                    Last modified
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">
                    {formatDateTime(selectedModel.last_updated)}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingTop: "15px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "130px",
                    display: "flex",
                  }}
                >
                  <Typography color="rgba(79, 79, 79, 1)" variant="body2">
                    Created on
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">
                    {formatDateTime(selectedModel.creation_time)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
}
