import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Box, IconButton, TextField, Typography, Dialog, Divider, Menu, MenuItem } from "@mui/material";

import PraxisLogoV2 from './assets/praxis-logo-v2.png';

import MonitorExplorerHeader from "./components/header";
import MonitorExplorerMenu from "./components/overview/menu";
import SensorsTable from "./components/overview/sensors-table"
import SensorFilters from "./components/sensor-filters";
import EventsChart from "./components/overview/events-graph";
import EventsTable from './components/overview/events-table';
import AlertsList from './components/alerts-list';
import PDFViewer from './components/overview/pdf-viewer';

import InfoIcon from '@mui/icons-material/Info';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';


import ManualsTable from './components/overview/manuals-table';

import SensorsIcon from "@mui/icons-material/Sensors"
import SendIcon from '@mui/icons-material/Send';

import { getMonitor, getMonitorData, getMonitorDocument, initializeMaxe, getMonitorChatResponse, getMaxeResponse, getMaxeInitStatus } from "../../../api";

import MonitorChatBot from './components/chatbot';
import DeploymentsTable from './components/overview/deployments-table';
import dayjs from 'dayjs';
import Workspace from './components/workspace';
import AlertConfig from './components/alert-config';

const CURRENT_TIME = '2024-11-29T13:00:00';

export default function MonitorExplorer ({ }) {
    const {monitorId} = useParams('monitorId');
    const [activeTab, setActiveTab] = useState('Events');

    const [timeFrame, setTimeFrame] = useState(24);
    const [startDate, setStartDate] = useState(dayjs(CURRENT_TIME).subtract(24, 'hour'));
    const [endDate, setEndDate] = useState(dayjs(CURRENT_TIME));
    const [sensorsData, setSensorsData] = useState(null);
    const [eventsData, setEventsData] = useState(null);
    const [deploymentsData, setDeploymentsData] = useState(null);

    const [monitor, setMonitor] = useState({
        'monitor_id': monitorId,
        'name': null,
        'description': null,
        'sensors': [],
        'deployments': [],
        'events': [],
        'owner': null,
    });

    const [conversation, setConversation] = useState([]);
    const [maxeResponse, setMaxeResponse] = useState(null);
    const [maxeResponseIdx, setMaxeResponseIdx] = useState(-1);
    const [userQuery, setUserQuery] = useState('');

    const [maxeInitStatus, setMaxeInitStatus] = useState(false);

    useEffect(() => {
        const getStatus = async () => {
            const status = await getMaxeInitStatus(monitorId);
            setMaxeInitStatus(status['initialized'])
            if(!status['initialized']){
                initMaxe();
            }
        }
        getStatus();
    }, [])

    const initMaxe = async () => {
        const end = endDate;
        const start = endDate.subtract(1, 'day');
        const result = await initializeMaxe(monitorId, start, end);
        if(result['success']){
            setMaxeInitStatus(true);
        }
    }

    const handleTextFieldEnter = async (event) => {
        if (event.key === 'Enter') {
            sendMaxeQuery(userQuery);
        }
    }

    const [alerts, setAlerts] = useState([]);
    const [alertConfig, setAlertConfig] = useState(
        // {
        //     summary: 'Predicted Downtimes',
        //     resources: 'Event 1, Sensor 2',
        //     critical_values: '44.6%',
        // }
    );

    const sendMaxeQuery = async (query) => {
        setUserQuery('');
        var _previousConversation = [...conversation];
        var _conversation = [...conversation];
        _conversation.push({
            role:'user',
            content:query,
            display:1
        });
        setConversation([..._conversation]);
        const response = await getMaxeResponse(monitorId, query, _previousConversation);
        if(response.type === 'message'){
            setMaxeResponse(response);
            const messageLength = response['content'].length;
            const timeoutId = setTimeout(() => {
                const interval = setInterval(() => {
                    setMaxeResponseIdx((prevIdx) => {
                        if (prevIdx >= messageLength) {
                          clearInterval(interval);  // Clear interval when max length is reached
                          var _response = response;
                          _response['display'] = 1;
                          _conversation.push(_response);
                          setConversation([..._conversation]);
                          setMaxeResponse(null);
                          return -1;
                        }
                        return prevIdx + 5;  // Otherwise, increment the index
                    });
                }, 1); // 10000 milliseconds = 10 seconds
                
                // Clean up the interval when the component unmounts
                return () => clearInterval(interval);
            }, 2000)
    
            return () => clearTimeout(timeoutId);
        }else if(response.type === 'alert'){
            setAlertConfig(response);
        }
    }

    const sendMaxeHiddenQuery = async (query) => {        
        var _conversation = [...conversation];
        _conversation.push({
            role:'user',
            content:query,
            display:-1
        });
        const response = await getMaxeResponse(monitorId, query, conversation);
        setConversation([..._conversation]);

        setMaxeResponse(response);
        const messageLength = response['content'].length;
        const timeoutId = setTimeout(() => {
            const interval = setInterval(() => {
                setMaxeResponseIdx((prevIdx) => {
                    if (prevIdx >= messageLength) {
                      clearInterval(interval);  // Clear interval when max length is reached
                      var _response = response;
                      _response['display'] = 1;
                      _conversation.push(_response);
                      setConversation([..._conversation]);
                      setMaxeResponse(null);
                      return -1;
                    }
                    return prevIdx + 5;  // Otherwise, increment the index
                });
            }, 1); // 10000 milliseconds = 10 seconds
            
            // Clean up the interval when the component unmounts
            return () => clearInterval(interval);
        }, 2000)

        return () => clearTimeout(timeoutId);
    }

    const [messages, setMessages] = useState([]);
    const [idx, setIdx] = useState(0);

    useEffect(() => {
        const messagesLength = messages.length;
        if(messagesLength && messages[messagesLength - 1]['is_final']){
            // Set up the interval to increment the count every 10 seconds
            const timeoutId = setTimeout(() => {
                const interval = setInterval(() => {
                    setIdx((prevIdx) => {
                        if (prevIdx >= messages[messagesLength - 1].text.length) {
                          clearInterval(interval);  // Clear interval when max length is reached
                          return prevIdx;
                        }
                        return prevIdx + 20;  // Otherwise, increment the index
                    });
                }, 1); // 10000 milliseconds = 10 seconds
                
                // Clean up the interval when the component unmounts
                return () => clearInterval(interval);
            }, 2000)
            setIdx(0);
            return () => clearTimeout(timeoutId);
        }
    }, [messages]); // Empty dependency array means the effect runs only once on mount

    useEffect(() => {
        setMessages([
            {
                'role': 'system',
                'text': "Hey there!\n\nAsk me questions about your machine data!",
                'is_final': false
            },
        ])
    }, []);

    const handleMessageEnter = async (message) => {
        console.log(message);
        const _messages = [];
        for(const _message of messages){
            _messages.push({
                'role': _message.role,
                'text': _message.text,
                'is_final': false
            })
        }
        _messages.push({
            'role':'user',
            'text': message,
            'is_final': false,
        })
        setMessages([..._messages]);
        const response = await getMonitorChatResponse(message);
        _messages.push({
            'role': 'system',
            'text': response['message'],
            'is_final': true,
        })
        setMessages([..._messages]);
    }

    useEffect(() => {
        const getMonitorData = async () => {
            const monitorData = await getMonitor(monitorId);
            setMonitor({...monitorData});
        }
        getMonitorData();
    }, []);

    const [blobUrl, setBlobUrl] = useState(null);
    useEffect(() => {
        const getDocument = async () => {
            const document = await getMonitorDocument(monitorId);
            setBlobUrl(document);
        }
        getDocument();
    }, [])

    const [query, setQuery] = useState(null);

    useEffect(() => {
        const start = dayjs(CURRENT_TIME).subtract(timeFrame, 'hour')
        setStartDate(start);
    }, [timeFrame])

    useEffect(() => {
        const getData = async () => {
            const {sensors, events, deployments} = await getMonitorData(monitorId, startDate, endDate);
            setSensorsData(sensors);
            setEventsData(events);
            setDeploymentsData(deployments);
        }
        getData();
    }, [startDate, endDate])

    const [view, setView] = useState('overview');

    const [viewAlerts, setViewAlerts] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <Box
            sx={{
                backgroundColor:'white',
            }}
        >
            <Dialog open={viewAlerts} onClose={() => setViewAlerts(false)}>
                <Box
                    sx={{
                        minHeight:'200px',
                        minWidth:'200px',
                    }}
                >
                    <Box
                        sx={{
                            padding:'10px'
                        }}
                    >
                        <Typography fontWeight='bold' fontSize='14px'>
                            Alerts
                        </Typography>
                    </Box>
                    <Divider/>
                    {
                        alerts.map((a, idx) => (
                            <Box>
                                <Typography paddingTop='10px' paddingLeft='10px'>
                                    Alert {idx + 1}
                                </Typography>
                                <AlertConfig alert={a}/>
                            </Box>
                        ))
                    }
                </Box>
            </Dialog>
            <MonitorExplorerHeader view={view} setView={setView} selectedHours={timeFrame} setSelectedHours={setTimeFrame} monitor={monitor}/>
            <Box
                sx={{
                    display:'flex',
                }}
            >
                <Box
                    sx={{
                        height:'95vh',
                        width:'70%',
                        overflow:'scroll'
                    }}
                >
                    {
                        view === 'overview' ?
                        <>
                        <MonitorExplorerMenu selectedHours={timeFrame} setSelectedHours={setTimeFrame} monitor={monitor} activeTab={activeTab} getActiveTab={setActiveTab}/>
                        <Box
                            sx={{
                                margin:'20px',
                                display:'flex',
                                flexDirection:'column',
                                gap:'10px'
                            }}
                        >
                            <SensorFilters/>
                            {
                                (activeTab === 'Sensors') ? <SensorsTable startDate={startDate} endDate={endDate}  sensorsData={sensorsData} sensors={monitor.sensors}/> :
                                (activeTab === 'Events') ? <EventsTable startDate={startDate} endDate={endDate} eventsData={eventsData} events={monitor.events} sensors={monitor.sensors}/> :
                                (activeTab === 'Deployments') ? <DeploymentsTable deploymentsData={deploymentsData} deployments={monitor.deployments} sensors={monitor.sensors} events={monitor.events}/> :
                                <ManualsTable monitor={monitor} blobUrl={blobUrl}/>
                            }
                        </Box> 
                        </> :
                        <Workspace sendMaxeHiddenQuery={sendMaxeHiddenQuery} startDate={startDate} endDate={endDate} sensors={monitor.sensors} events={monitor.events} deployments={monitor.deployments} sensorData={sensorsData} eventsData={eventsData} deploymentsData={deploymentsData}/>
                    }
                </Box>
                <Box
                    sx={{
                        width:'0.5%',
                        backgroundColor:'#EBEBEB',
                        borderLeft:'1px solid rgba(0, 0, 0, 0.15)',
                        borderRight:'1px solid rgba(0, 0, 0, 0.15)',
                    }}
                />
                <Box
                    sx={{
                        width:'39.5%',
                        padding:'10px'
                    }}
                >
                    <Box
                        sx={{
                            position:'relative',
                            top: `0px`,
                            height:'88vh',
                            padding: '10px',
                            display:'flex',
                            justifyContent:'space-between',
                            flexDirection:'column'
                        }}
                    >
                        <Box
                            sx={{
                                padding:'10px',
                                display:'flex',
                                flexDirection:'column',
                                gap:'10px'
                            }}
                        >
                            <Box
                                sx={{
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'space-between'
                                }}
                            >
                                <Box
                                    sx={{
                                        display:'flex',
                                        gap:'5px',
                                        alignItems:'center'
                                    }}
                                >
                                    <img src={PraxisLogoV2} height='40px'/>
                                    <Typography fontSize='20px' fontWeight='bold' variant='h4'><span style={{color:'#4643D4'}}>Max</span>.<span style={{color:'#FC5D1F'}}>E</span></Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display:'flex',
                                        gap:'5px',
                                        alignItems:'center'
                                    }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <AssignmentLateIcon/>
                                        <Typography>
                                            2
                                        </Typography>
                                    </IconButton>
                                    <AlertsList anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose}/>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    overflow:'scroll',
                                    maxHeight:'500px'
                                }}
                            >
                                <MonitorChatBot alerts={alerts} setAlertConfig={setAlertConfig} setAlerts={setAlerts} alertConfig={alertConfig} maxeInitStatus={maxeInitStatus} maxeResponse={maxeResponse} maxeResponseIdx={maxeResponseIdx} conversation={conversation} idx={idx} messages={messages}/>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center',
                                flexDirection:'row',
                                gap:'5px',
                                margin:'10px',
                                boxShadow: '0px 4px 77px 0px rgba(213, 212, 221, 1)',
                            }}
                        >
                            <TextField
                                sx={{
                                    width:'100%',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '5px', // Rounded corners
                                        '& fieldset': {
                                            borderRadius:'8px',
                                            border:'1px solid rgba(0, 0, 0, 0.15)',
                                            boxShadow: '0px 4px 77px 0px rgba(213, 212, 221, 1)',
                                        },
                                        '&:hover fieldset': {
                                            borderRadius:'8px',
                                            border:'1px solid rgba(70, 67, 212, 0.5)',
                                            boxShadow: '0px 4px 77px 0px rgba(213, 212, 221, 1)',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderRadius:'8px',
                                            border:'1px solid rgba(70, 67, 212, 1)',
                                            boxShadow: '0px 4px 77px 0px rgba(213, 212, 221, 1)',
                                        },
                                    },
                                }}
                                placeholder={'Ask a question'}
                                multiline={true}
                                rows={1}
                                onChange={(event) => setUserQuery(event.target.value)}
                                value={userQuery}
                                onKeyDown={handleTextFieldEnter}
                                disabled={!maxeInitStatus}
                            />
                            <Box
                                sx={{
                                }}
                            >
                                <IconButton disabled={!maxeInitStatus} onClick={() => sendMaxeQuery(userQuery)} sx={{p:0, m:0}}><SendIcon sx={{fill:'rgb(70, 67, 212)'}}/></IconButton>
                            </Box>
                        </Box>
                    </Box>

                </Box>

            </Box>
        </Box>
    );
}