import { Box, TextField, FormControl, Select, MenuItem, Typography } from "@mui/material";


export default function TimeFrameSelector({
    timeFrame,
    timeFrameUnits,
    setTimeFrame,
    setTimeFrameUnits
}){
    return (
        <Box
            sx={{
                display:'flex',
                gap:'20px',
                alignItems:'center',
                width:'100%'
            }}
            >
            <Typography>
                Previous
            </Typography>
            <TextField
                size='small'
                type='number'
                sx={{
                    width:'120px'
                }}
                value={timeFrame}
                onChange={(event) => setTimeFrame(event.target.value)}
            />
            <FormControl sx={{width:'100%'}} size='small'>
                <Select
                    value={timeFrameUnits}
                    onChange={(event) => setTimeFrameUnits(event.target.value)}
                >
                    <MenuItem value={'minute'}>minutes</MenuItem>
                    <MenuItem value={'hour'}>hours</MenuItem>
                    <MenuItem value={'day'}>days</MenuItem>
                    <MenuItem value={'week'}>weeks</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}
