import React, { useEffect, useState } from 'react';
import { Box, Typography, Popover, List, ListItem, Checkbox, ListItemText, IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const FilterBox = ({name, col, types, getOptions, filterSelections}) => {
  console.log(filterSelections);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState([]);

  useEffect(() => {
    var selected = [];
    for(const type of types){
      if(filterSelections && filterSelections[col] && filterSelections[col].includes(type)){
        selected.push(type);
      }
    }
    setSelectedTypes([...selected]);
  }, [filterSelections])

  const handleBoxClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (value) => {
    const currentIndex = selectedTypes.indexOf(value);
    const newChecked = [...selectedTypes];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedTypes(newChecked);
    getOptions(newChecked, col);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  return (
    <div>
      {/* Box that triggers the Popover */}
      <Box
        onClick={handleBoxClick}
        sx={{
          borderBottom: "1px solid rgba(47,47,47,0.6)",
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "6px",
          paddingLeft: "10px",
          paddingRight: "10px",
          padding:'8px',
          cursor: 'pointer',
        }}
      >
        <Typography fontSize="0.9rem">{name}</Typography>
        <ArrowDropDownIcon fontSize="1.2rem" />
      </Box>

      {/* Popover that contains the filter options */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List
            sx={{
                maxHeight:'200px'
            }}
        >
          {types.map((type) => (
            <ListItem key={type} button onClick={() => handleToggle(type)}>
              <Checkbox
                edge="start"
                checked={selectedTypes.indexOf(type) !== -1}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={type} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default FilterBox;
