import { useState, useEffect } from 'react';

import { Box, Divider, Typography, Grid, Button } from "@mui/material";

import a11_2x5eu_r from './images/a11_2x5eu_r_640x480_top.png';
import a11_2x61_2eu_r from './images/a11_2x61_2eu_r_640x480_top.png';
import ca3x5eu_1_r from './images/ca3x5eu-1_r_640x480_top.png';
import ca3x5eu_3_r_6 from './images/ca3x5eu-3_r_640x480_top 2.png';
import ca3x5eu_3_r from './images/ca3x5eu-3_r_640x480_top.png';
import ca4x8eu_7_r from './images/ca4x8eu-7_r_640x480_top.png';
import ace_logo from './images/ace-logo.gif'

import QuotingHeader from "./components.js/header"

import { getQuoteSimilarity, getQuoteSimilarityLogs } from '../../api';

export default function QuotingTool({}){
    const cadFiles = [
        {
            image: a11_2x5eu_r,
            name: 'a11_2x5eu_r',
            filepath: 'a11_2x5eu_r_top.dxf',
            manufacturer: 'ACE Stoßdämpfer GmbH'

        },
        {
            image: a11_2x61_2eu_r,
            name: 'a11_2x61_2eu_r',
            filepath: 'a11_2x61_2eu_r_top.dxf',
            manufacturer: 'ACE Stoßdämpfer GmbH'

        },
        {
            image: ca3x5eu_1_r,
            name: 'ca3x5eu_1_r',
            filepath: 'ca2x2eu-3_r_top.dxf',
            manufacturer: 'ACE Stoßdämpfer GmbH'

        },
        {
            image: ca3x5eu_3_r_6,
            name: 'ca3x5eu_3_r_6',
            filepath: 'ca3x5eu-1_r_top.dxf',
            manufacturer: 'ACE Stoßdämpfer GmbH'

        },
        {
            image: ca3x5eu_3_r,
            name: 'ca3x5eu_3_r',
            filepath: 'ca3x5eu-3_r_top.dxf',
            manufacturer: 'ACE Stoßdämpfer GmbH'

        },
        {
            image: ca4x8eu_7_r,
            name: 'ca4x8eu_7_r',
            filepath: 'ca4x8eu-7_r_top.dxf',
            manufacturer: 'ACE Stoßdämpfer GmbH'

        },
    ]

    const [selectedModel, setSelectedModel] = useState(null);
    const ModelCard = ({cad_model}) => {
        var isSelected = false;
        if(selectedModel && selectedModel.name === cad_model.name){
            isSelected = true;
        }

        return (
            <Box
                sx={{
                    backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.2)' : 'white',
                    '&:hover':{
                        backgroundColor:isSelected ? 'rgba(70, 67, 212, 0.2)' : 'lightgrey',
                    },
                    padding:'20px',
                    border:'1px solid lightgrey',
                    borderRadius:'8px'
                }}
                onClick={() => setSelectedModel(cad_model)}
            >
                <Box>
                    <Grid container spacing={'10px'}>
                        <Grid item xs={6}>
                            <Typography fontWeight='bold'>Part</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{cad_model.name}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight='bold'>Manufacturer</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <img src={ace_logo} height='10px'/>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        padding:'10px',
                        marginTop:'15px'
                    }}
                >
                    <img src={cad_model.image} width='100%'/>
                </Box>
            </Box>
        )
    }

    const [triggerLogs, setTriggerLogs] = useState(false);
    const [similarityMap, setSimilarityMap] = useState(null);
    const [similarityLogs, setSimilarityLogs] = useState(null);
    const findMatches = async () => {
        setTriggerLogs(true);
        const matches = await getQuoteSimilarity(selectedModel.filepath);
        console.log(matches);
        setSimilarityMap(matches);
        setTriggerLogs(false);
    }

    const getSimilarityLog = async () => {
        const logs = await getQuoteSimilarityLogs();
        setSimilarityLogs(logs.logs);
    }

    useEffect(() => {
        // Call the fetch function initially
        if(triggerLogs){
            getSimilarityLog();
            // Set interval to call fetchData every 10 seconds (10000 ms)
            const intervalId = setInterval(getSimilarityLog, 30000);
        
            // Cleanup the interval when the component unmounts
            return () => clearInterval(intervalId);
        }
      }, [triggerLogs]); // Empty dependency array means the effect runs only once after the component mounts

    return (
        <Box>
            <QuotingHeader/>
            <Box
                sx={{
                    display:'flex',
                }}
            >
                <Box
                    sx={{
                        background: 'linear-gradient(154.32deg, #FCFCFD 15.25%, #F6F5F9 93.61%)',
                        width:'30%',
                        pl:'40px',
                        pr:'40px',
                        pt:'40px',
                        gap:'20px',
                        display:'flex',
                        flexDirection:'column',
                        maxHeight:'100vh',
                        overflow:'scroll'
                    }}
                >
                    <Typography variant='h6' fontWeight='bold'>Part Repository</Typography>
                    {cadFiles.map((file) => (<ModelCard cad_model={file}/>))}
                </Box>
                <Divider flexItem orientation='vertical'/>
                <Box
                    sx={{
                        background:'white',
                        width:'70%',
                        height:'100%',
                        display:'flex',
                        flexDirection:'column'
                    }}
                >
                    <Button
                        variant='contained'
                        disabled={!selectedModel}
                        onClick={findMatches}
                    >Find Matches
                    </Button>
                    {
                        selectedModel &&
                        <Box
                            sx={{
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                                justifyContent:'center'
                            }}
                        >
                            <Typography sx={{padding:'40px'}} variant='h5' fontWeight='bold'>
                                {selectedModel.name}
                            </Typography>
                            <img src={selectedModel.image}/>
                        </Box>
                    }
                    {
                        similarityLogs &&
                        <Box
                            sx={{
                                display:'flex',
                                flexDirection:'column',
                                gap:'10px',
                                backgroundColor:'lightgrey',
                                border:'1px solid lightgrey',
                                margin:'40px',
                                padding:'20px'

                            }}
                        >
                            <Typography fontWeight='bold' variant='h7'>Processing Logs</Typography>
                            <Divider flexItem orientation='horizontal'/>
                            <Box
                                sx={{
                                    maxHeight:'400px',
                                    maxWidth:'500px',
                                    overflow:'scroll'
                                }}
                            >
                                <Typography>
                                    {similarityLogs}
                                </Typography>
                            </Box>
                        </Box>
                    }
                    {
                        similarityMap && 
                        <Box
                            sx={{
                                display:'flex',
                                flexDirection:'column',
                                gap:'10px',
                                backgroundColor:'lightgrey',
                                border:'1px solid lightgrey',
                                margin:'40px',
                                padding:'20px'
                            }}
                        >
                            <Typography fontWeight='bold' variant='h7'>Top Match</Typography>
                            <Divider flexItem orientation='horizontal'/>
                                {Object.keys(similarityMap).map((file) => (
                                    <Typography>
                                        <span style={{fontWeight:'bold'}}>{file}:</span> <span> {similarityMap[file]}</span>
                                    </Typography>
                                ))}
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    )
}