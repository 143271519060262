import { Modal, Alert, AlertTitle } from "@mui/material";
import { useEffect, useState } from "react";

export default function AlertModal({hasAlert, setHasAlert, alertMessage}){
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const handleAlert = () => {
            setShowAlert(true);
            const timer = setTimeout(() => {
              setShowAlert(false);
              setHasAlert(false);
            }, 3000); // Close after 5 seconds
        
            return () => clearTimeout(timer);
        };
        if(hasAlert){
            handleAlert();
        }
    }, [hasAlert])


    return (
        <Modal
            open={showAlert}
        >
            <Alert
                sx={{
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                top: '15%',
                left: '50%',
                width: '400px'
                }}
                severity={alertMessage['severity']}
            >
                <AlertTitle>{alertMessage['severity'].toUpperCase()}</AlertTitle>
                {alertMessage['message']}
            </Alert>
        </Modal>
    );
}