import React from 'react';
import { Box } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, PointElement, LineElement, Tooltip, TimeScale, Legend } from 'chart.js';


// Register chart.js components
ChartJS.register(LineElement, TimeScale, Tooltip, Legend, PointElement);

const SparkLine = ({
  values,
  labels,
  anomalyValues,
}) => {
    const chartData = {
      labels: [...labels],
      datasets: [
        {
            data: [...anomalyValues],
            borderWidth: 0, // No lines connecting the points
            backgroundColor: '#FF0707', // Background color for points
            pointRadius: 2, // Size of the points
            pointHoverRadius: 4, // Size when hovered
            pointBackgroundColor: '#FF0707', // Color of the points
        },
        {
          data: [...values],
          borderColor: '#02542D',
          borderWidth: 1,
        },
      ]
    };
  
    const chartOptions = {
      scales: {
        x: {
          display: false, // Hide the X-axis
        },
        y: {
          display: false, // Hide the Y-axis
        },
      },
      plugins: {
        legend: {
          display: false, // Hide the legend
        },
        tooltip: {
          enabled: false, // Disable tooltips
        },
      },
      elements: {
        point: {
          radius: 0, // Adjust the size of points
        },
      },
    };
  
    return (
        <div>
            <Line data={chartData} options={chartOptions} style={{ height: '60px', width: '100%' }} />
        </div>
    )
};

export default SparkLine;