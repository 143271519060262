import { Typography, Divider, Box, IconButton, Dialog, Button } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import TypedText from './typed-text';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CategoryIcon from '@mui/icons-material/Category';
import ReactMarkdown from 'react-markdown';

import LoadingSpinner from '../../../../../components/loading-spinner';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import ResourceList from "./resources-list";
import MaxeThinkingSpinner from './maxe-thinking-spinner';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const INTRO_TEXT = "Hey there!\nAsk me questions about your machine data!"


export default function ChatInterface({
    sendMaxeQuery,
    maxeThinking,
    visibility,
    setVisibility,
    sendMaxeHiddenQuery,
    monitor,
    maxeInitStatus,
    maxeResponse,
    maxeResponseIdx,
    conversation,
    setMainTab,
    setInputPageNumber
}){    
    const TEMPERATURE_ANOMALIES_TEXT = `There have been several anomalies on the temperature sensor where it has fallen below the detected lower control limit of 314. Several downtimes in the past day have seen temperature drop below 314 several times in the hour prior to the downtime. For example
    •	Downtime 5462
        Start Time: 2024/11/12 12:22 PM
        End Time:  2024/11/12 12:48 PM
        Duration: 26 minutes
        Downtime Code: A1
    •	Downtime 5468
        Start Time: 2024/11/12 1:27 PM
        End Time:  2024/11/12 2:48 PM
        Duration: 1 hour 21 minutes
        Downtime Code: A2
    •	Downtime 5469
        Start Time: 2024/11/12 4:34 PM
        End Time:  2024/11/12 6:48 PM
        Duration: 2 hours 14 minutes
        Downtime Code: C01
    `
    
    const BotMessage = ({message, typeText, idx, resources}) => {
        const [resourcesRef, setResourcesRef] = useState(null);
        const openResources = Boolean(resourcesRef);

        const handleClick = (event) => {
            setResourcesRef(resourcesRef ? null : event.currentTarget)
        }

        return (
            <Box
                sx={{
                    width:'100%',
                    display:'flex',
                    flexDirection:'column',
                    gap:'2px',
                    width:'95%'
                }}
            >
                {!typeText &&
                    <Box
                        sx={{
                            display:'flex',
                            justifyContent:'right',
                            alignItems:'center',
                            pb:'2px'
                        }}
                    >
                        <IconButton onClick={handleClick} sx={{p:0, m:0}}><CategoryIcon sx={{height:'18px', fill:'rgb(90, 90, 90, 0.3)', '&:hover':{fill:'rgba(90,90,90)'}}}/></IconButton>
                        <IconButton sx={{p:0, m:0}}><ContentCopyIcon sx={{height:'18px', fill:'rgb(90, 90, 90, 0.3)', '&:hover':{fill:'rgba(90,90,90)'}}}/></IconButton>
                        <ResourceList
                            visibility={visibility}
                            setVisibility={setVisibility}
                            sendMaxeHiddenQuery={sendMaxeHiddenQuery}
                            resources={resources}
                            open={openResources}
                            monitor={monitor}
                            resourcesButtonRef={resourcesRef}
                            hasDocument={resources.has_document}
                            setMainTab={setMainTab}
                            setInputPageNumber={setInputPageNumber}
                        />
                    </Box>
                }
                <Box
                    sx={{
                        background: 'linear-gradient(154.32deg, #FCFCFD 15.25%, #F6F5F9 93.61%)',
                        borderRadius: '8px',
                        border:'1px solid rgba(0, 0, 0, 0.15)',
                        pl:'10px',
                        pr:'10px',
                    }}
                >
                    {
                        typeText &&
                        <TypedText text={message} idx={idx}/>
                    }
                    {
                        !typeText &&
                        <ReactMarkdown>{message}</ReactMarkdown>
                    }
                </Box>
            </Box>
        )
    }

    const RelatedMessage = ({options, openView}) => {
        const handleClick = async (option) => {
            sendMaxeQuery(option);
            setOpen(false);
        }

        const [open, setOpen] = useState(openView);

        return (
            <Box
                sx={{
                    width:'100%',
                    display:'flex',
                    flexDirection:'column',
                    gap:'2px',
                    width:'95%',
                    transform: open ? 'translate(0%, -10%)' : 'translate(0%, -50%)'
                }}
            >
                <Box
                    sx={{
                        background: 'linear-gradient(154.32deg, #FCFCFD 15.25%, #F6F5F9 93.61%)',
                        borderRadius: '8px',
                        border:'1px solid rgba(0, 0, 0, 0.15)',
                        pl:'20px',
                        pr:'10px',
                        pt:open ? '10px' : '5px',
                    }}
                >
                    <Box sx={{justifyContent:'space-between', display:'flex'}}>
                        <Typography fontSize='14px' fontWeight='bold'>Related</Typography>
                        <IconButton sx={{p:0, m:0}} onClick={() => setOpen((prev) => (!prev))}>
                            {
                                !open ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/>
                            }
                        </IconButton>
                    </Box>
                    {
                        open &&
                        <Box
                            sx={{borderLeft:'1px solid rgba(0,0,0,0.2)', mb:'10px'}}
                        >
                            {
                                options.map((option, idx) => (
                                    <Box
                                        sx={{
                                            '&:hover':{
                                                backgroundColor:'rgba(0,0,0,0.05)',
                                            },
                                            cursor:'pointer',
                                            padding:'10px',
                                            pt:'4px',
                                            pb:'4px',
                                        }}
                                        onClick={() => handleClick(option)}
                                    >
                                        <Typography>{`${idx + 1}. ${option}`}</Typography>
                                    </Box>
                                ))
                            }
                        </Box>
                    }
                </Box>
            </Box>
        )
    }

    const UserMessage = ({message}) => {
        return (
            <Box
                sx={{
                    width:'100%',
                    display:'flex',
                    alignItems:'center'
                }}
            >
                <Box
                    sx={{
                        width:'10%'
                    }}
                >
                </Box>
                <Box
                    sx={{
                        background: 'rgb(36, 37, 42)',
                        borderRadius: '8px',
                        pl:'10px',
                        pr:'10px',
                        pt:'15px',
                        pb:'15px',
                        width:'90%',
                    }}
                >
                    <span style={{fontSize:'15px', color:'white'}}>{message}</span>
                </Box>

            </Box>
        )
    }

    const messagesEndRef = useRef(null);

    const [showRelated, setShowReleated] = useState(false);
    useEffect(() => {
        if(!maxeThinking && maxeResponseIdx === -1){
            const timer = setTimeout(() => {
                setShowReleated(true)
            }, 2000)
            return () => clearTimeout(timer)
        }else{
            setShowReleated(false);
        }
    }, [maxeThinking, maxeResponseIdx])

    useEffect(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [maxeResponseIdx, conversation, maxeThinking]);

    return (
        !maxeInitStatus ?
        <Box
            sx={{
                height:'70vh',
                width:'100%',
                alignItems:'center',
                justifyContent:'center',
                display:'flex',
                flexDirection:'column',
                gap:'20px'
            }}
        >
            <Typography fontWeight='bold' variant='overline' fontSize='16px'>Initializing...</Typography>
            <LoadingSpinner isLoading={true}/>
        </Box>
        :
        <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                gap:'15px',
                overflow:'scroll',
            }}
        >
            <BotMessage message={INTRO_TEXT} typeText={false} hasDocument={true} resources={{sensors:[2], events:[1], deployments:[2]}}/>
            {/*
            <UserMessage message={'Summarize the predicted stamping press downtime'}/>
            <BotMessage message={`The predicted probability of a downtime is 84%. The main factor a downtime might happen right now due indicated by the prediction model: \n \n * **Temperature (Sensor 2)**: \n \t * Importance value of 0.135 \n \t * Anomaly Count (Last Hour): 3`} typeText={false}/>
            <UserMessage message={'Summarize the temperature anomalies'}/>
            <BotMessage message={'There have been several anomalies on the temperature sensor where it has fallen below the detected lower control limit of 314. Several downtimes in the past day have seen temperature drop below 314 several times in the hour prior to the downtime. For example \n \n * Downtime 5462 \n \t * Start Time: 2024/11/12 12:22 PM \n \t * End Time:  2024/11/12 12:48 PM \n \t * Duration: 26 minutes \n \t * Downtime Code: A1 \n * Downtime 5468 \n \t * Start Time: 2024/11/12 1:27 PM \n \t * End Time:  2024/11/12 2:48 PM \n \t * Duration: 1 hour 21 minutes \n \t * Downtime Code: A2 \n * Downtime 5469 \n \t * Start Time: 2024/11/12 4:34 PM \n \t * End Time:  2024/11/12 6:48 PM \n \t * Duration: 2 hours 14 minutes \n \t * Downtime Code: C01'} typeText={false}/>
            <UserMessage message={'What is the root cause of these anomalies?'}/>
            <BotMessage message={`The root cause of these anomalies can be contributed to many factors including plant conditions and human error. Based on the sesor data provided, the features noted are: \n \n * Flow Rate In 24.3% \n \n * Vibration Motor 19.2% \n \n I would focus on the Flow Rate In. There is high correlation between low values in flow rate and anomalies in the temperature sensor in recent data.`} typeText={false}/>
            <UserMessage message={`How can I increase the flow rate?`}/>
            <BotMessage message={`To address issues related to the Vibration Senor, follow these steps based on the information from the maintenance manual: \n \n 1.	Calibration: \n \n * Regularly calibrate the control limit sensor impacting the flow rate of the machine. In conditions of low flow rate, decrease the pressure on valve A.  \n \n 2.	Cleaning: \n \n * Keep the sensor free from dirt, dust, and other contaminants that may affect its performance. If necessary, clean the sensor according to standard procedures to remove any debris.`} typeText={false}/>
            <UserMessage message={`What should I change the pressure value to?`}/>
            <BotMessage message={`Based on the data provided, the recent average value for pressure is consistently around 128. Given the historical data and its importance in predictive modeling, it is recommended to maintain pressure around this target value to ensure optimal performance and to avoid deviations that may lead to anomalies or issues in production efficiency. \n \n The optimization model provided recommends decreasing the throughput by **50 RPM over 20 minutes** to achieve this.`} typeText={false}/>
            */}
            {conversation.map((message) => (
                (message.display === 1 && message.role === 'user') ? <UserMessage message={message.content}/> : (message.display === 1) ? <BotMessage message={message.content} typeText={false} resources={message.resources}/> : <Box></Box>
            ))}
            {
                (maxeResponseIdx > -1) &&
                <BotMessage message={maxeResponse.content} typeText={true} idx={maxeResponseIdx} />
            }
            {
                maxeThinking &&
                <MaxeThinkingSpinner/>
            }
            {
                (showRelated && (conversation.length > 0) && (conversation[conversation.length - 1].role !== 'user')) &&
                <RelatedMessage options={conversation[conversation.length - 1].related}
                openView={true}
                />
            }
            <div ref={messagesEndRef} />
        </Box>
    )
}

// {sensors:[1,2], events:[1], deployments:[1,2]}