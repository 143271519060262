import { Box, Divider, Typography, Button, Drawer } from "@mui/material";
import { SENSOR_COLORS } from "../constants";

import SensorsIcon from '@mui/icons-material/Sensors';
import EventIcon from '@mui/icons-material/Event';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

export default function SensorTag({ sensor, selectedModel }){
  var icon = <SensorsIcon sx={{height:'10px', width:'10px', fill:'orange'}}/>;
  if(sensor.data_type === 'event'){
    icon = <EventIcon sx={{height:'10px', width:'10px', fill:'purple'}}/>;
  }else if(sensor.data_type === 'deployment'){
    icon = <RocketLaunchIcon sx={{height:'10px', width:'10px', fill:'green'}}/>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Box
        sx={{ display:'flex', justifyContent:'center', alignItems:'center', border:'1px solid lightgrey', borderRadius:'5px', padding:'2px' }}
      >
        {icon}
      </Box>
      <Typography variant="body2">
        {sensor.name}
      </Typography>
    </Box>
  );
};
