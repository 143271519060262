import { Box, Typography, Divider, TextField, FormControl, Select, MenuItem, InputLabel, IconButton } from "@mui/material";

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';

export default function SamplingFrequency({model, setModel}){
    const handleSamplingFrequency = (event) => {
        var config = {...model};
        config['sampling_frequency'] = event.target.value;
        setModel({...config});
    };

    const handleSamplingFrequencyUnits = (event) => {
        var config = {...model};
        if(event.target.value){
            config['sampling_frequency_units'] = event.target.value;
        }else{
            config['sampling_frequency_units'] = null;
        }
        setModel({...config});
    };

    const resetSamplingFrequency = () => {
        var config = {...model};
        config['sampling_frequency'] = null;
        config['sampling_frequency_units'] = null;
        setModel({...config});
    };
    
    return (
        <Box
        sx={{
            border: '1px solid rgba(0, 0, 0, 0.1)',
            backgroundColor:'white',
            margin:'20px'
        }}
        >
        <Box
        sx={{
            padding:'15px',
            paddingLeft:'20px',
            display:'flex',
            gap:'10px',
            alignItems:'center'
        }}
        >
            <ArrowCircleRightIcon sx={{fill:'rgba(47, 128, 237, 1)'}}/>
            <Typography variant='h7' fontSize='0.9rem' fontWeight='bold'>Sampling Frequency</Typography>
        </Box>
        <Divider/>
        <Box
            sx={{
                display:'flex',
                gap:'20px',
                padding:'20px',
                flexDirection:'column'
            }}
        >
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    width:'100%',
                    gap:'5px'
                }}
            >
                <Typography variant='caption'>Choose the rate at which data is sampled. Larger sample frequencies result in less training time and compute, but can miss trends in the data.</Typography>
                {
                    (model['sampling_frequency'] === 'auto' || !model['sampling_frequency']) ?
                    <Select
                        labelId="select-label"
                        value={model['sampling_frequency']}
                        onChange={handleSamplingFrequency}
                        fullWidth
                    >
                        <MenuItem value="auto">Auto</MenuItem>
                        <MenuItem value="input">Input</MenuItem>
                    </Select> :
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'row',
                            gap:'5px',
                            width:'60%'
                        }}
                    >
                        <TextField
                            defaultValue={1}
                            value={model['sampling_frequency']}
                            onChange={handleSamplingFrequency}
                            type='number'
                            size='small'
                            sx={{
                                width:'100px'
                            }}
                        />
                        <FormControl sx={{width:'80%'}} size='small'>
                            <Select
                                defaultValue={'hour'}
                                value={model['sampling_frequency_units']}
                                onChange={handleSamplingFrequencyUnits}
                            >
                            <MenuItem value={'second'}>Second</MenuItem>
                            <MenuItem value={'minute'}>Minute</MenuItem>
                            <MenuItem value={'hour'}>Hour</MenuItem>
                            <MenuItem value={'day'}>Day</MenuItem>
                            <MenuItem value={'week'}>Week</MenuItem>
                            <MenuItem value={'month'}>Month</MenuItem>
                            </Select>
                        </FormControl>
                        <IconButton
                            sx={{m:0, p:0}}
                            onClick={resetSamplingFrequency}
                        >
                            <CancelIcon/>
                        </IconButton>
                    </Box>
                }
            </Box>
        </Box>
        </Box>
    )
}
