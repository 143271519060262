import { Box, Typography, Divider, Button } from "@mui/material";

import MonitorsIcon from '../assets/monitors-icon.png'
import MonitorIcon from '../assets/monitor-icon.png'

import ShareIcon from "@mui/icons-material/Share";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import SettingsIcon from '@mui/icons-material/Settings';

import TimeRangeList from "./overview/time-range-list";


export default function MonitorExplorerHeader({view, setView, monitor, selectedHours, setSelectedHours}){

    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: "white",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                }}
                >
                <Box
                    sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    padding: "10px",
                    }}
                >
                        <img src={MonitorsIcon} height='20px'/>
                        <Typography
                        sx={{ color: "rgba(130, 130, 130, 1)", fontSize: "14px" }}
                        >
                        Monitors
                        </Typography>
                </Box>
                <Typography fontSize="1.4rem" color="rgba(130, 130, 130, 1)">
                    /
                </Typography>
                <Box
                    sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    }}
                >
                    <img src={MonitorIcon} height='20px'/>
                    <Typography
                    sx={{ pt: "2px", fontWeight: "bold", fontSize: "14px" }}
                    >
                        {monitor.name}
                    </Typography>
                </Box>
                <Divider flexItem orientation="vertical" />
                <Box
                    sx={{
                        height: '30px',
                    }}
                >
                    <TimeRangeList selectedHours={selectedHours} setSelectedHours={setSelectedHours}/>
                </Box>
                </Box>
                <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    paddingRight: "20px",
                }}
                >
                    {
                        view === 'overview' ? 
                        <Button
                            variant="contained"
                            style={{
                                borderRadius: '5px',
                                fontSize: '14px',
                                backgroundColor: '#4643D4',
                                display: 'flex',
                                height: '30px',
                                color:'white',
                                alignItems:'center',
                                '&:hover':{
                                    backgroundColor:'#6664ed',
                                },
                                textTransform:'none'
                            }}
                            onClick={() => setView('workspace')}
                            endIcon={<ArrowOutwardIcon/>}
                        >
                            Open workspace
                        </Button> :
                        <Button
                            variant="contained"
                            style={{
                                borderRadius: '5px',
                                fontSize: '14px',
                                backgroundColor: '#4643D4',
                                display: 'flex',
                                height: '30px',
                                color:'white',
                                alignItems:'center',
                                '&:hover':{
                                    backgroundColor:'#6664ed',
                                },
                                textTransform:'none'
                            }}
                            onClick={() => setView('overview')}
                            endIcon={<SouthWestIcon/>}
                        >
                            Open overview
                        </Button>
                    }
                    <Divider flexItem orientation="vertical" />
                    <ShareIcon />
                    <MoreHorizIcon />
                </Box>
            </Box>
            <Divider/>
        </Box>
    );
}