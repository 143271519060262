import React from 'react';
import { Navigate, useLocation } from "react-router-dom";


import useToken from './use-token';
import PageHolder from '../page-holder';
import useHealthCheck from './use-health-check';

export default function AuthCheck({ children }) {
    const location = useLocation();
    const { token, removeToken, setToken } = useToken();
    const [isTokenValid, setIsTokenValid] = React.useState(!!token);
    useHealthCheck(() => setIsTokenValid(false));

    if (!isTokenValid || !token) {
      // Redirect to the login page and pass the current location to return after login
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <PageHolder children={children} removeToken={removeToken}/>
};
