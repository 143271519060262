import { useState, useEffect, useRef } from "react";

import { Box, Typography, Popover, Popper, Divider } from "@mui/material";

import { Visibility as VisibilityIcon, VisibilityOff } from '@mui/icons-material';

import SensorsIcon from '@mui/icons-material/Sensors';
import EventIcon from '@mui/icons-material/Event';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

export default function InputCard({config, dataColors, visibility, setVisibility, resourceImportance, handleInsightsButton, warnings}){
    const [dataType, setDataType] = useState(config['data_type']);
    const [idCol, setIdCol] = useState(`${dataType}_id`);
    const [key, setKey] = useState(`${dataType}_${config[idCol]}`);
    const [color, setColor] = useState(dataColors[key]);

    useEffect(() => {
        setDataType(config['data_type']);
        setIdCol(`${dataType}_id`);
        setKey(`${dataType}_${config[idCol]}`);
        setColor(dataColors[key]);
    }, [config])

    const importance = resourceImportance[key]*100;

    const [detailsRef, setDetailsRef] = useState(null);
    const detailsOpen = Boolean(detailsRef);
    const [placement, setPlacement] = useState(null);
    const handleDetailsOpen = (event) => {
        setDetailsRef(event.currentTarget);
        setPlacement(event);
    }
    const open = Boolean(detailsRef);

    const handleDetailsClose = () => {
        setDetailsRef(null);
    }

    const ProgressBar = ({progress}) => {
        return (
            <Box
                sx={{
                    height:'10px',
                    backgroundColor:'#CDCDCD',
                    width:'100%',
                    borderRadius:'8px'
                }}
            >
                <Box
                    sx={{
                        width:`${progress.toLocaleString(0)}%`,
                        backgroundColor:'#4643D4',
                        height:'10px',
                        borderRadius:'8px'
                    }}
                />
            </Box>
        )
    }

    const handleVisibility = () => {
        var _visibility = {...visibility};
        _visibility[key] = !_visibility[key];
        console.log('helel');
        setVisibility({..._visibility});
      }
  
    const [visibile, setVisibile] = useState(false);
    useEffect(() => {
        const _visibile = visibility[key];
        setVisibile(_visibile);
    }, [visibility])


    const [warningsList, setWarningsList] = useState([]);
    useEffect(() => {
        const _list =  warnings[key].map((warning) => (
            <Typography fontSize='14px'>
                The <span style={{fontWeight:'bold'}}>{warning.metric === 'std' ? 'standard deviation' : warning.metric === 'avg' ? 'average' : warning.metric === 'count' ? 'count of anomalies' : 'current value'}</span> {(warning.metric === 'std' || warning.metric === 'avg' || warning.metric === 'count') ? 'in the last hour' : ''} is <span style={{fontWeight:'bold', color:'#4643D4'}}>{parseFloat(warning.current_value).toFixed(3)}</span>. This is between the warning range of <span style={{fontWeight:'bold', color:'rgb(229,160,0)'}}>{parseFloat(warning.warning_lower).toFixed(3)} to {parseFloat(warning.warning_upper).toFixed(3)}</span>
            </Typography>
        ))
        setWarningsList(_list);
    }, [warnings, key])

    return (
        <Box
            sx={{
                padding:'20px',
                backgroundColor:'white',
                border:'1px solid rgba(47,47,47,0.2)',
                borderRadius:'8px',
                '&:hover':{
                    border:'1px solid rgba(47,47,47,0.6)'
                },
                cursor:'pointer',
            }}
        >
            <Popper
                placement={'right-start'}
                open={open}
                anchorEl={detailsRef} 
            >
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        gap:'5px',
                        borderRadius:'8px',
                        backgroundColor:'white',
                        boxShadow:1,
                        border:'0.5px solid rgba(47,47,47,0.3)',
                        maxWidth:'200px'
                    }}
                >
                    {
                        warningsList.length === 0 ? 
                        <Box
                            sx={{
                                padding:'15px',
                                border:'0.5px solid rgba(47,47,47,0.3)',
                                borderRadius:'8px',
                            }}
                        >
                            <Typography fontSize='14px'>Healthy. No warnings detected.</Typography>
                        </Box>
                        : warningsList.map((warning) => (
                            <Box
                                sx={{
                                    padding:'15px',
                                    border:'0.5px solid rgba(47,47,47,0.3)',
                                    borderRadius:'8px',
                                }}
                            >
                                {warning}
                            </Box>
                        ))
                    }
                </Box>
            </Popper>
            <Box
                sx={{
                    display:'flex',
                    justifyContent:'space-between',
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        gap:'10px'
                    }}
                >
                    <Box
                        sx={{
                        // width:'20px',
                        maxHeight:'25px',
                        borderRadius:'5px',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        padding:'2px',
                        backgroundColor:color
                        }}
                    >
                        {
                            config.data_type === 'sensor' ? <SensorsIcon sx={{height:'20px', fill:'white'}}/> :
                            config.data_type === 'event' ? <EventIcon sx={{height:'20px', fill:'white'}}/> :
                            <RocketLaunchIcon sx={{height:'20px', fill:'white'}}/> 
                        }
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                        }}
                    >
                        <Typography sx={{color:'#262626', fontSize:'14px'}}>{config.name}</Typography>
                        <Typography sx={{color:'#262626', fontSize:'12px'}}>{config.description}</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display:'flex',
                        gap:'5px',
                        alignItems:'center'
                    }}

                >
                    { 
                        visibile ?
                        <VisibilityIcon onClick={handleVisibility} sx={{height:'15px'}}/>
                        : <VisibilityOff onClick={handleVisibility} sx={{height:'15px'}}/>
                    }
                    {
                        warningsList.length === 0 ?
                        <Box
                            sx={{
                                '&:hover': {
                                    backgroundColor:'rgba(90,90,90,0.2)'
                                },
                                height:'25px',
                                width:'25px',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center',
                            }}
                        >
                            <Box
                                sx={{
                                    height:'25px',
                                    width:'25px',
                                    borderRadius:'25px',
                                    backgroundColor:'rgba(88,189,128,0.1)',
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    padding:'2px'
                                }}
                                onMouseEnter={handleDetailsOpen}
                                onMouseLeave={handleDetailsClose}
                            >
                                <Box
                                    sx={{
                                        height:'15px',
                                        width:'15px',
                                        borderRadius:'15px',
                                        backgroundColor:'rgb(88,189,128)',
                                    }}
                                />
                            </Box>
                        </Box>
                        :
                        <Box
                            sx={{
                                '&:hover': {
                                    backgroundColor:'rgba(90,90,90,0.2)'
                                },
                                height:'25px',
                                width:'25px',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center',
                            }}
                        >
                            <Box
                                sx={{
                                    height:'25px',
                                    width:'25px',
                                    borderRadius:'25px',
                                    backgroundColor:'rgba(229,160,0,0.1)',
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center'
                                }}
                                onMouseEnter={handleDetailsOpen}
                                onMouseLeave={handleDetailsClose}
                            >
                                <Box
                                    sx={{
                                        height:'15px',
                                        width:'15px',
                                        borderRadius:'15px',
                                        backgroundColor:'rgb(229,160,0)',
                                    }}
                                />
                            </Box> 
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    )
}