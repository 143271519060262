import { Link } from "react-router-dom";

import { formatDateTime } from "../helpers";

import { Box, Divider, Typography, Button, Drawer } from "@mui/material";

import ModelObjectiveIcon from "../../components/model-objective.icon";

export default function ModelCard ({ model, selectedModel, handleModelSelect }) {


    return (
      <Box
        sx={{
          padding: "16px 20px",
          borderBottom: "1px solid lightgrey",
          backgroundColor:
            model.model_id === selectedModel.model_id
              ? "rgba(70, 67, 212, 0.06)"
              : "rgba(255, 255, 255, 1)",
          "&:hover": {
            backgroundColor: "rgba(44, 44, 45, 0.06)",
            cursor: "pointer",
          },
        }}
        onClick={() => handleModelSelect(model)}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "20px",
              color: "#000000",
              textDecoration: "none",
              "&:hover": {
                color: "#4643D4",
              },
            }}
            component={Link}
            to={"/ml-factory/model-overview/" + model.model_id}
          >
            {model.name}
          </Typography>
          <Box
            sx={{
              backgroundColor:
                model.model_type === "Generalized"
                  ? "#F2F2F2"
                  : "rgba(47, 128, 237, 0.1)",
              padding: "2px 8px",
              borderRadius: "3px",
            }}
          >
            <Typography sx={{ fontSize: "12px" }}>
              {model.model_type}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: "4px",
          }}
        >
          <Typography
            variant="subtitle"
            sx={{
              fontSize: "14px",
              lineHeight: "18px",
            }}
          >
            {model.description}
          </Typography>
        </Box>
        <Box
          sx={{
            paddingTop: "16px",
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <ModelObjectiveIcon model={model} textFontSize={'12px'} iconSize={'16px'}/>
          <Typography fontSize="12px" fontWeight="800">
            •
          </Typography>
          <Typography fontSize="12px" variant="subtitle2" color="#5e5e5e">
            Created on {formatDateTime(model.creation_time)}
          </Typography>
        </Box>
      </Box>
    );
  };