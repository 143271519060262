import {useEffect, useState} from "react";

import { HeaderCell, SensorRow } from "./helpers";
import {
    Box,
    TableContainer,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TextField,
    TableRow,
} from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import FilterBox from '../filter-box';
import SearchIcon from "@mui/icons-material/Search";



export default function IngestSensorsTable({sensors, selectSensor, selectAll, selectedSensors}) {
    const [searchQuery, setSearchQuery] = useState(null);
    const [selectedTypes, setSelectedTypes] = useState(null);

    const [filteredSensors, setFilteredSensors] = useState(sensors);
    console.log(sensors);
    useEffect(() => {
        var _filtered = [];
        if(searchQuery){
            sensors.forEach((s) => {
                console.log(s);
                if(s.measuring_point.includes(searchQuery) || s.tag_group_name.includes(searchQuery) || s.name.includes(searchQuery) || s.description.includes(searchQuery)){
                    _filtered.push(s);
                }
            })
        }else{
            _filtered = sensors;
        }
        setFilteredSensors([..._filtered]);
    }, [searchQuery])

    return (
        <Box>
        <Box
            sx={{
                display:'flex',
                padding:'10px',
                alignItems:'center'
            }}
        >
            <Box
                sx={{
                border: "1px solid lightgrey",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                gap: "10px",
                paddingLeft: "10px",
                boxShadow: 1,
                overflow:'hidden',
                width:'95%'
                }}
            >
                <SearchIcon fontSize="1.2rem" />
                <TextField
                onChange={(event) => setSearchQuery(event.target.value)}
                size='small'
                fullWidth={true}
                variant='filled'
                placeholder="Search..."
                autoComplete="off" // Disables browser autocomplete
                />
            </Box>
        </Box>
        <TableContainer
            sx={{
            maxHeight: "50vh",
            overflow: "scroll",
            }}
        >
            <Table>
            <TableHead sx={{ backgroundColor: "rgba(247, 247, 247, 1)" }}>
                <TableRow>
                <TableCell
                    sx={{
                        pl:'10px'
                    }}
                >
                    {
                        filteredSensors.length === selectedSensors.length  ? ( <Box
                            sx={{
                            height: "18px",
                            width: "18px",
                            backgroundColor: "rgba(70, 67, 212, 1)",
                            border: "1px solid rgba(0, 0, 0, 0.24)",
                            borderRadius: "3px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor:'pointer'
                            }}
                            onClick={() => selectAll(filteredSensors)}
                        >
                            <HorizontalRuleIcon sx={{ stroke: "white", fill: "white" }} />
                        </Box>
                        ) : (
                            <Box
                                sx={{
                                height: "18px",
                                width: "18px",
                                backgroundColor: "rgba(247, 247, 247, 1)",
                                border: "1px solid rgba(0, 0, 0, 0.24)",
                                borderRadius: "3px",
                                cursor:'pointer'
                                }}
                                onClick={() => selectAll(filteredSensors)}
                            ></Box>
                        )
                    }
                </TableCell>
                {["Tag ID", "Name", "Description", "Unit"].map((col) => (
                    <HeaderCell col={col} />
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {filteredSensors.map((sensor) => (
                    <SensorRow sensor={sensor} selectSensor={selectSensor} selectedSensors={selectedSensors}/>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
        </Box>
    )
}
