import React, { useState } from 'react';
import { List, ListItem, ListItemText, Typography, Box, Select, MenuItem } from '@mui/material';

const TimeRangeList = ({selectedHours, setSelectedHours}) => {
  // Array of options with labels and values
  const timeOptions = [
    { label: 'Last 8 hours', value: 8 },
    { label: 'Last 16 hours', value: 16 },
    { label: 'Last day', value: 24 },
  ];

  // Handler function for list item click
  const handleSelect = (event) => {
    setSelectedHours(event.target.value); // Update selected hours
  };

  return (
    <Box>
      <Select
        value={selectedHours}
        onChange={handleSelect}
        displayEmpty
        variant="outlined"
        sx={{
          color:'white',
          height:'30px',
          fontSize: '14px',
          borderRadius:'8px',
          backgroundColor: '#4643D4',
          '.MuiSelect-icon': { color: 'white' },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#777777', // Hover effect
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          m:0
        }}
      >
        {timeOptions.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              fontSize: '14px',
              pr:'4px',
              pl:'4px',
              display:'flex',
              alignItems:'center',
              '&:hover': {
                backgroundColor: 'rgba(70,67,212,0.3)',
              },
              '&.Mui-selected': {
                backgroundColor: 'rgba(70,67,212,0.3)', // Color for selected item
                color: 'black',
                '&:hover': {
                  backgroundColor: 'rgba(70,67,212,0.3)', // Color on hover when selected
                },
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default TimeRangeList;
