import {
    Alert,
    AlertTitle,
    Box,
    IconButton,
    Divider,
    Typography,
    TextField,
    Button,
} from "@mui/material";

import SensorsIcon from '@mui/icons-material/Sensors';
import EventIcon from '@mui/icons-material/Event';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import PraxisLogoV2 from '../../assets/praxis-logo-v2.png'



export default function VariableCard({ sendMaxeHiddenQuery, config, color, visibility, setVisibility }){
    const dataType = config['data_type'];
    const idCol = `${dataType}_id`;
    const key = `${dataType}_${config[idCol]}`;

    const maxeDataType = dataType === 'deployment' ? 'model' : dataType;
    const maxeKey = `${maxeDataType}_${config[idCol]}`;

    const handleVisibility = () => {
      var _visibility = visibility;
      _visibility[dataType+'s'][config[idCol]] = !_visibility[dataType+'s'][config[idCol]];
      setVisibility({..._visibility});
    }



    return (
      <Box
        sx={{
          padding: "12px 16px",
          backgroundColor: "white",
          borderRadius: "4px",
          border: visibility[dataType + 's'][config[idCol]] ?  "1px solid #4643D4": "1px solid rgba(0, 0, 0, 0.15)",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.7)",
          backgroundColor: "white",
          cursor:"pointer",
          "&:hover":{
            border: "1px solid #4643D4",
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            onClick={handleVisibility}
          >
          <Box
              sx={{
                // width:'20px',
                // height:'20px',
                borderRadius:'5px',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                padding:'2px',
                backgroundColor:color
              }}
            >
              {
                config.data_type === 'sensor' ? <SensorsIcon sx={{height:'20px', fill:'white'}}/> :
                config.data_type === 'event' ? <EventIcon sx={{height:'20px', fill:'white'}}/> :
                <RocketLaunchIcon sx={{height:'20px', fill:'white'}}/> 
              }

            </Box>
            <Box>
              {
                config.data_type === 'sensor' ? 
                <Typography                
                  variant="overline"
                  color={"black"}
                >
                  {config.name} ({config.tag_id})
                </Typography> :
                <Typography                
                  fontSize='12px'
                  color={"black"}
                >
                  {config.name}
                </Typography>
              }
              <Typography
                fontSize='12px'
                color={"black"}
              >
                {config.description}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              '&:hover':{
                boxShadow:2,
                height:'15px'
              }
            }}
            onClick={() => sendMaxeHiddenQuery(`Summarize data for ${maxeKey}`)}
          >
            <img src={PraxisLogoV2} height={'15px'}/>
          </Box>
        </Box>
      </Box>
    );
  };