import { Drawer, Typography, Box, IconButton} from "@mui/material";

import MaxeLogo from "./maxe-logo";

import ChatInterface from "./chat-interface";
import QueryInterface from "./query-interface"

export default function MonitorChatBot({
    maxeThinking,
    setMainTab,
    visibility,
    setVisibility,
    sendMaxeHiddenQuery,
    monitor,
    setOpen,
    shrunk,
    setShrunk,
    maxeInitStatus,
    maxeResponse,
    maxeResponseIdx,
    conversation,
    userQuery,
    setUserQuery,
    handleTextFieldEnter,
    sendMaxeQuery,
    setInputPageNumber
}){
    const ShrinkIcon = ({}) => {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_819_3902)">
            <path d="M11 0H9V7H16V5H11V0ZM0 11H5V16H7V9H0V11Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_819_3902">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
            </svg>
        )
    }

    const ExpandIcon = ({}) => {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 8H1V15H8V13H3V8ZM8 3H13V8H15V1H8V3Z" fill="black"/>
            </svg>
        )
    }

    const CloseIcon = ({}) => {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.55673 8.025L3.05029 11.5353L4.46451 12.9495L8.05049 9.36356L11.5356 12.9495L12.9498 11.5353L9.41426 7.99979L12.9498 4.46426L11.5336 3.0481L8.00004 6.58558L4.46451 3.05005L3.05029 4.46426L6.55673 8.025Z" fill="#24252A"/>
            </svg>
        )
    }


    return (
        <Box sx={{}}>
            <Box
                sx={{padding:'20px'}}
            >
                <Box
                    sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                    }}
                >
                    <Box
                        sx={{display:'flex', alignItems:'center', gap:'5px'}}
                    >
                        <Box
                            sx={{
                                borderRadius:'20px',
                                overflow:'hidden',
                                height:'35px',
                                width:'35px',
                                alignItems:'center',
                                display:'flex',
                                justifyContent:'center',
                                backgroundColor:'rgba(128, 255, 238, 0.5)'
                            }}
                        >
                            <MaxeLogo active={false}/>
                        </Box>
                        <Typography fontSize='16px' fontWeight='bold' variant='h4'><span style={{color:'#4643D4'}}>Max</span>.<span style={{color:'#FC5D1F'}}>E</span></Typography>
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            gap:'8px'
                        }}
                    >
                        {
                            shrunk ?
                            <IconButton
                                sx={{m:0, p:0}}
                                onClick={() => setShrunk(false)}
                            >
                                <ExpandIcon/>
                            </IconButton>
                            :
                            <IconButton
                                sx={{m:0, p:0}}
                                onClick={() => setShrunk(true)}
                            >
                                <ShrinkIcon/>
                            </IconButton>
                        }
                        <IconButton
                            sx={{m:0, p:0}}
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                </Box>

            </Box>
            <Box
                sx={{
                    padding:'10px',
                    display:'flex',
                    justifyContent:'space-between',
                    flexDirection:'column',
                }}
            >
                <Box sx={{minHeight:'69vh', maxHeight:'69vh', overflow:'scroll'}}>
                    <ChatInterface
                        sendMaxeQuery={sendMaxeQuery}
                        maxeThinking={maxeThinking}
                        setMainTab={setMainTab}
                        visibility={visibility}
                        setVisibility={setVisibility}
                        sendMaxeHiddenQuery={sendMaxeHiddenQuery}
                        monitor={monitor}
                        maxeInitStatus={maxeInitStatus}
                        maxeResponse={maxeResponse}
                        maxeResponseIdx={maxeResponseIdx}
                        conversation={conversation}
                        setInputPageNumber={setInputPageNumber}
                    />
                </Box>
                <QueryInterface
                    userQuery={userQuery}
                    setUserQuery={setUserQuery}
                    maxeInitStatus={maxeInitStatus}
                    sendMaxeQuery={sendMaxeQuery}
                    handleTextFieldEnter={handleTextFieldEnter}
                />
            </Box>
        </Box>
    )
}