import { Box, Typography, Divider, Button } from "@mui/material";

import ShareIcon from "@mui/icons-material/Share";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

export default function QuotingHeader({}){

    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: "white",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                }}
                >
                <Box
                    sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    padding: "10px",
                    }}
                >
                        <FormatQuoteIcon sx={{fill:'rgb(252, 93, 30)'}}/>
                        <Typography
                        sx={{ color: "rgba(130, 130, 130, 1)", fontSize: "14px" }}
                        >
                        Quotes
                        </Typography>
                </Box>
                <Typography fontSize="1.4rem" color="rgba(130, 130, 130, 1)">
                    /
                </Typography>
                <Box
                    sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    }}
                >
                    <RequestQuoteIcon sx={{fill:'rgb(252, 93, 30)'}}/>
                    <Typography
                    sx={{ pt: "2px", fontWeight: "bold", fontSize: "14px" }}
                    >
                        New Quote (2024-10-16 02:45:14 PM)
                    </Typography>
                </Box>
                <Divider flexItem orientation="vertical" />
                </Box>
                <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    paddingRight: "20px",
                }}
                >
                    <Button
                        variant="contained"
                        style={{
                            borderRadius: '5px',
                            fontSize: '14px',
                            backgroundColor: '#4643D4',
                            display: 'flex',
                            height: '30px',
                            color:'white',
                            alignItems:'center',
                            '&:hover':{
                                backgroundColor:'#6664ed',
                            },
                            textTransform:'none'
                        }}
                        endIcon={<ArrowOutwardIcon/>}
                    >
                        Request approval
                    </Button>
                    <Divider flexItem orientation="vertical" />
                    <ShareIcon />
                    <MoreHorizIcon />
                </Box>
            </Box>
            <Divider/>
        </Box>
    );
}