import { useState, useEffect } from 'react'
import { Box, Typography, Button } from "@mui/material";

import MonitorsIcon from './monitors-icon.png';

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import MonitorCard from './components/monitor-card';

import AbbyProfilePicture from './profile-pic-abby.avif'
import JohnProfilePicture from './profile-picture-john.webp'

import { getMonitor, getAllMonitors, getMonitorAnomalyData, getMonitorVibrationAnomalies, getMonitorsAnomalyData } from "../../../api"
import CreateModal from './components/create-modal';

export default function MonitorsHome({}) {
  const [monitorsList, setMonitorsList] = useState([]);
  const [createModal, setCreateModal] = useState(false);

  const [selectedMonitor, setSelectedMonitor] = useState(null);

  useEffect(() => {
    const _getMonitors = async () => {
      const monitors = await getAllMonitors();
      setMonitorsList(monitors);
    }
    _getMonitors();
  }, [])

  const createMonitor = async () => {

  }


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "64px",
                paddingRight: "64px",
                paddingTop: "16px",
            }}
        >
          <CreateModal open={createModal} handleModalState={setCreateModal}/>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "64px",
              paddingRight: "64px",
              paddingTop: "32px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems:'center',
                        justifyContent:'center',
                        gap:'10px'
                    }}
                >
                    <img src={MonitorsIcon} height='45px'/>
                    <Typography
                        sx={{ fontSize: "36px", lineHeight: "44px", fontFamily:'arial' }}
                        variant="h4"
                    >
                        Monitors
                    </Typography>
                </Box>
              <Button
                sx={{
                    backgroundColor:'#219653',
                    '&:hover':{
                        backgroundColor:'green'
                    }
                }}
                startIcon={<AddIcon/>}
                variant="contained"
                onClick={() => setCreateModal(true)}
              >
                New
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                height: "40px",
                paddingTop: "24px",
                paddingBottom: "24px",
              }}
            >
              <Box
                sx={{
                  flexGrow: "1",
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  gap: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "white",
                  boxShadow: 1,
                  cursor:'pointer'
                }}
              >
                <SearchIcon />
                <Typography>Search...</Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  backgroundColor: "white",
                  boxShadow: 1,
                  cursor:'pointer'
                }}
              >
                <Typography>Any owner</Typography>
                <ArrowDropDownIcon />
              </Box>
              <Box
                sx={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  backgroundColor: "white",
                  boxShadow: 1,
                  cursor:'pointer'
                }}
              >
                <Typography>Any severity</Typography>
                <ArrowDropDownIcon />
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid lightgrey",
                overflow: "hidden",
              }}
            >
              {
                monitorsList.map((monitor) => (
                  <MonitorCard handleMonitorSelect={setSelectedMonitor} monitor={monitor}/>
                ))
              }
            </Box>
          </Box>
        </Box>
    );
};
