import React, { useState } from 'react';
import { Box, IconButton, Typography } from "@mui/material";
import { Document, Page, pdfjs } from 'react-pdf';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import ManualPDF from '../assets/maintenance-manual.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


const PDFViewer = ({blobUrl}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // When a PDF is loaded, update the number of pages
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = (error) => {
    console.log(error); // Set the error message when the PDF fails to load
  };


  return (
      <Box>
        <Box
            sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between',
                backgroundColor:'lightgrey',
            }}
        >
            <IconButton disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}><ArrowBackIcon/></IconButton>
            <Typography>{pageNumber}</Typography>
            <IconButton disabled={pageNumber === numPages} onClick={() => setPageNumber(pageNumber + 1)}><ArrowForwardIcon/></IconButton>

        </Box>
        <Box
            sx={{
                maxHeight:'700px',
                overflow:'hidden',
                display:'flex',
                flexDirection:'column',
                gap:'10px',
                border:'1px solid lightgrey'
            }}
        >
                <Document
                    file={blobUrl} // PDF file path or URL
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onDocumentLoadError}
                >
                    <Page pageNumber={pageNumber} renderTextLayer={false} />
                </Document>
        </Box>
      </Box>
  );
};

export default PDFViewer;