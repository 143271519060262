import { Box, TextField, IconButton } from "@mui/material";

import SendIcon from "@mui/icons-material/Send"

export default function QueryInterface({
    userQuery,
    setUserQuery,
    maxeInitStatus,
    sendMaxeQuery,
    handleTextFieldEnter,
}){
    return (
        <Box
            sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                flexDirection:'row',
                gap:'5px',
                margin:'10px',
                boxShadow: '0px 4px 77px 0px rgba(213, 212, 221, 1)',
            }}
        >
            <TextField
                sx={{
                    width:'100%',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '5px', // Rounded corners
                        '& fieldset': {
                            borderRadius:'8px',
                            border:'1px solid rgba(0, 0, 0, 0.15)',
                            boxShadow: '0px 4px 77px 0px rgba(213, 212, 221, 1)',
                        },
                        '&:hover fieldset': {
                            borderRadius:'8px',
                            border:'1px solid rgba(70, 67, 212, 0.5)',
                            boxShadow: '0px 4px 77px 0px rgba(213, 212, 221, 1)',
                        },
                        '&.Mui-focused fieldset': {
                            borderRadius:'8px',
                            border:'1px solid rgba(70, 67, 212, 1)',
                            boxShadow: '0px 4px 77px 0px rgba(213, 212, 221, 1)',
                        },
                    },
                }}
                placeholder={'Ask a question'}
                multiline={true}
                rows={1}
                onChange={(event) => setUserQuery(event.target.value)}
                value={userQuery}
                onKeyDown={handleTextFieldEnter}
                disabled={!maxeInitStatus}
            />
            <Box
                sx={{
                }}
            >
                <IconButton disabled={!maxeInitStatus} onClick={() => sendMaxeQuery(userQuery)} sx={{p:0, m:0}}><SendIcon sx={{fill:'rgb(70, 67, 212)'}}/></IconButton>
            </Box>
        </Box>
    )
}