import { EventAvailableSharp } from '@mui/icons-material';
import {
    Typography,
    TableRow,
    TableCell,
    Box
  } from '@mui/material';

import ModelObjectiveIcon from '../../../ml-factory/components/model-objective.icon'

export default function DeploymentRow({deployment, selectedDeployments, selectDeployment}){
    var isSelected = false;
    if((selectedDeployments.map((d) => (d.model_id))).includes(deployment.model_id)){
      isSelected = true;
    }
    return (
      <Box
        sx={{
          '&:hover': {
            backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.08)' : 'rgba(134, 134, 134, 0.08)',
          },
          backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.08)' : 'white',
          display:'flex',
          justifyContent:'space-between',
          cursor:'pointer',
          padding:'20px',
          border:'1px solid lightgrey'
        }}
        onClick={() => selectDeployment(deployment)}
      >
        <Box
          sx={{
            flexDirection:'column',
            display:'flex',
            gap:'8px',
          }}
        >
          <Typography fontWeight='bold' variant='body1' fontSize='0.9rem'>{deployment.name}</Typography>
          <Typography variant='body1' fontSize='0.8rem'>{deployment.description}</Typography>
        </Box>
        <Box
          sx={{
            flexDirection:'column',
            gap:'10px',
          }}
        >
          <Typography variant='overline' fontSize='0.6rem'>Objective</Typography>
          <ModelObjectiveIcon model={deployment} iconSize='14px' textFontSize='15px'/>
        </Box>
      </Box>
    )
  }