import { useState } from 'react';

import { Box, Typography, Button, Divider, Select, FormControl, MenuItem, TextField } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import SensorSelectionModal from './sensor-selection-modal';
import SensorsFilter from './sensors-filter';
import DataVariables from './data-variables';
import SamplingFrequency from './sampling-frequency'

import { getSensor } from '../../../../../api';


export default function TrainingDataConfig({model, setModel, deploymentList, eventsList, sensorList, visibility, setVisibility, trainingSet, setTrainingSet, sensorColors}){
    const [anchor, setAnchor] = useState(null);
    const handleImportModal = (event) => {
        setAnchor(anchor ? null : event.currentTarget)
    }

    const getSensorMetadata = (sensor, dataType) => {
        const sensorImportData = {
            ...sensor,
            'data_type': dataType,
            'variable_type': null,
            'interpolation_strategy': 'linear',
            'null_strategy': 'interpolate',
            'fill_value': null,
            'transforms': [],
            'sensor_type': null,
        }
        return sensorImportData
    };


    const handleSensorSelection = async (sensors, dataType) => {
        var sensorImports = trainingSet['sensor_config'] ? trainingSet['sensor_config'] : []
        var sensorDatasetIds = sensorImports ? sensorImports.map((sensor) => (sensor['sensor_id'])) : []
        var config = trainingSet;
        var _visibility = {...visibility};
        sensors.forEach((sensor) => {
            const sensorImport = getSensorMetadata(sensor, dataType);
            sensorImports.push(sensorImport);
            const idCol = `${sensorImport['data_type']}_id`
            _visibility[`${sensorImport['data_type']}_${sensorImport[idCol]}`] = false;
        })
        config['sensor_config'] = [...sensorImports];
        setTrainingSet({...config});
        setVisibility({..._visibility});
        setAnchor(null);
    }

    const handleSensorDelete = async (sensor) => {
        var config = trainingSet;
        const sensor_config = config['sensor_config'];
        var _sensor_config = [];
        for(const c of sensor_config){
          if(c.sensor_id !== sensor.sensor_id){
            _sensor_config.push(c);
          }
        }
        config['sensor_config'] = _sensor_config;
        setTrainingSet({...config})
    };

    return (
        <Box>
            <Box
                sx={{
                height:'50px',
                display:'flex',
                backgroundColor:'white',
                justifyContent:'space-between',
                alignItems:'center',
                paddingLeft:'20px',
                paddingRight:'20px',
                }}
            >
                <Typography fontWeight='bold' variant='h8'>Data</Typography>
                <Button
                    sx={{
                        textTransform:'none',
                        color:'rgba(75, 64, 221, 1)'
                    }}
                    variant='text'
                    startIcon={<AddIcon/>}
                    onClick={handleImportModal}
                >
                    Add
                </Button>
            </Box>
            <SensorSelectionModal model={model} deploymentList={deploymentList} eventsList={eventsList} sensorList={sensorList} anchor={anchor} placement={'right-start'} handleImportModal={handleImportModal} handleSensorSelection={handleSensorSelection}/>
            <Divider/>
            <SensorsFilter/>
            <Divider/>
            {
                model.model_objective === 'Predictive' &&
                <SamplingFrequency model={model} setModel={setModel}/>
            }
            <DataVariables
                model={model}
                trainingSet={trainingSet}
                setTrainingSet={setTrainingSet}
                visibility={visibility}
                setVisibility={setVisibility}
                handleSensorDelete={handleSensorDelete}
                sensorColors={sensorColors}
            />
        </Box>
    );
}