
import { Box, IconButton, TextField, Typography, Dialog, Divider, Menu, MenuItem } from "@mui/material";
import ReactMarkdown from 'react-markdown';

export default function AlertsList({handleClose, anchorEl}) {
    const open = Boolean(anchorEl);
    const AlertSection = ({color, name, text}) => {
        return (
            <Box
                sx={{
                    backgroundColor:'white',
                    border:'1px solid rgba(0,0,0,0.1)',
                    borderRadius:'8px',
                    boxShadow:1,
                    width:'100%',
                    padding:'5px',
                    pl:'10px',
                    pr:'10px'
                }}
            >
                <Typography fontWeight='bold' fontSize='12px'>{text}</Typography>
            </Box>
        )
    }

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            sx={{
                gap:0
            }}
        >
            <Typography fontWeight='bold' fontSize='12px' paddingLeft='10px' paddingRight='10px' marginBottom='10px'>Warnings</Typography>
            <Box
                sx={{
                    height:'5px',
                    backgroundColor:'red',
                    borderRadius:'8px',
                    marginBottom:'10px'
                }}
            />
            <MenuItem onClick={handleClose}>
                <Typography fontWeight='bold' fontSize='12px'>Predicted Stamping Press Downtime</Typography>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <Typography fontWeight='bold' fontSize='12px'>Temperature Anomalies</Typography>
            </MenuItem>
        </Menu>
    )
};