import {
    Alert,
    AlertTitle,
    Box,
    IconButton,
    Divider,
    Typography,
    TextField,
    Grid,
    Button,
} from "@mui/material";

import { COLORS } from "../constants";

import SensorsIcon from '@mui/icons-material/Sensors';
import EventIcon from '@mui/icons-material/Event';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';


export default function VariableCard({ model, config, idx, resourceImportance, dataColors, predictionWindow, contextWindow, samplingFrequency }){
    const dataType = config['data_type'];
    const idCol = `${dataType}_id`;
    const key = `${dataType}_${config[idCol]}`;

    const importance = (resourceImportance ? resourceImportance[key] : 0) * 100;
  
    return (
      <Box
        sx={{
          margin: "8px",
          padding: "12px 16px",
          backgroundColor: "white",
          borderRadius: "4px",
          border: "1px solid rgba(0, 0, 0, 0.15)",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.07)",
          backgroundColor: "white",
          width:'100%'
        }}
      >
        <Box
          sx={{
            display: "flex",
            width:'100%'
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: model.model_objective === 'Predictive' ? '50%' : '100%'
            }}
          >
          <Box
              sx={{
                // width:'20px',
                // height:'20px',
                borderRadius:'5px',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                padding:'2px',
                backgroundColor:dataColors[key].replace('0.25', '0.6')
              }}
            >
              {
                config.data_type === 'sensor' ? <SensorsIcon sx={{height:'20px', fill:'white'}}/> :
                config.data_type === 'event' ? <EventIcon sx={{height:'20px', fill:'white'}}/> :
                <RocketLaunchIcon sx={{height:'20px', fill:'white'}}/> 
              }

            </Box>
            <Box>
              {
                config.data_type === 'sensor' ? 
                <Typography                
                  variant="overline"
                  color={"black"}
                >
                  {config.name} ({config.tag_id})
                </Typography> :
                <Typography                
                  fontSize='14px'
                  color={"black"}
                >
                  {config.name}
                </Typography>
              }
              <Typography
                fontSize='12px'
                color={"black"}
              >
                {config.description}
              </Typography>
            </Box>
          </Box>
          {
            model.model_objective === 'Predictive' &&
            <Divider sx={{ml:'4px', mr:'4px'}} flexItem orientation='vertical'/>
          }
          {
            model.model_objective === 'Predictive' &&
            <Box
              sx={{
                display: "flex",
                flexDirection:'column',
                gap: "10px",
                padding:'5px',
                width:'50%'
              }}
            >
              <Grid container spacing={'0px'}>
                <Grid item xs={8}>
                  <Typography fontSize='12px' color="rgba(48, 48, 48, 0.6)">Prediction window</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography fontSize='12px' color="rgba(48, 48, 48)">{model.prediction_window} {model.prediction_window_units}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography fontSize='12px' color="rgba(48, 48, 48, 0.6)">Context window</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography fontSize='12px' color="rgba(48, 48, 48)">{model.context_window} {model.context_window_units}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography fontSize='12px' color="rgba(48, 48, 48, 0.6)">Sampling frequency</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography fontSize='12px' color="rgba(48, 48, 48)">{model.sampling_frequency} {model.sampling_frequency_units}</Typography>
                </Grid>
              </Grid>
            </Box>
          }
        </Box>
      </Box>
    );
  };