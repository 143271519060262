import { EventAvailableSharp } from '@mui/icons-material';
import {
    Typography,
    TableRow,
    TableCell,
    Box
  } from '@mui/material';

export default function EventRow({event, selectedEvents, selectEvent}){
    var isSelected = false;
    if((selectedEvents.map((e) => (e.event_id))).includes(event.event_id)){
      isSelected = true;
    }
    return (
      <Box
        sx={{
          '&:hover': {
            backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.08)' : 'rgba(134, 134, 134, 0.08)',
          },
          backgroundColor: isSelected ? 'rgba(70, 67, 212, 0.08)' : 'white',
          display:'flex',
          cursor:'pointer',
          display:'flex',
          flexDirection:'column',
          gap:'5px',
          padding:'20px',
          border:'1px solid lightgrey'
        }}
        onClick={() => selectEvent(event)}
      >
        <Typography fontWeight='bold' variant='body1' fontSize='0.9rem'>{event.name}</Typography>
        <Typography variant='body1' fontSize='0.8rem'>{event.description}</Typography>
      </Box>
    )
  }