export const GROUP_COLORS = {
    'Bearing LC 6219': '#4B4B4B' /* Dark Charcoal */,
    'Bearing LL 6219': '#2F4F4F' /* Dark Slate Gray */,
    'Clean tank': '#4B0082' /* Indigo */,
    'Clutch operating data': '#483D8B' /* Dark Slate Blue */,
    'Dirty tank': '#2C3E50' /* Midnight Blue */,
    'Extraction Guides': '#556B2F' /* Dark Olive Green */,
    'Filter hydraulics': '#8B0000' /* Dark Red */,
    'Filters': '#800080' /* Purple */,
    'Main_Motor_DEBF': '#5F9EA0' /* Cadet Blue */,
    'Motor Axle': '#3D2C2C' /* Dark Brown */,
    'Motor operating data': '#0F4C5C' /* Deep Sea Blue */,
    'Motors Vibration': '#2A2B2D' /* Onyx */,
    'Operation modes': '#1C1C1C' /* Very Dark Gray */,
    'Press operating data': '#3C3B6E' /* Deep Blue */,
    'default_configuration_base_measurement_job': '#2E2D88' /* Royal Indigo */,
}


export const COLORS = [
    "rgba(39, 174, 96, 0.6)",
    "rgba(41, 128, 185, 0.6)",
    "rgba(231, 76, 60, 0.6)",
    "rgba(243, 156, 18, 0.6)",
    "rgba(142, 68, 173, 0.6)",
    "rgba(26, 188, 156, 0.6)",
    "rgba(241, 196, 15, 0.6)",
    "rgba(52, 152, 219, 0.6)",
    "rgba(211, 84, 0, 0.6)",
    "rgba(127, 140, 141, 0.6)",
    "rgba(255, 99, 132, 0.6)",
    "rgba(54, 162, 235, 0.6)",
    "rgba(255, 206, 86, 0.6)",
    "rgba(75, 192, 192, 0.6)",
    "rgba(153, 102, 255, 0.6)",
    "rgba(255, 159, 64, 0.6)",
    "rgba(244, 67, 54, 0.6)",
    "rgba(33, 150, 243, 0.6)",
    "rgba(76, 175, 80, 0.6)",
    "rgba(156, 39, 176, 0.6)",
    "rgba(63, 81, 181, 0.6)",
    "rgba(255, 87, 34, 0.6)",
    "rgba(205, 220, 57, 0.6)",
    "rgba(121, 85, 72, 0.6)",
    "rgba(96, 125, 139, 0.6)",
    "rgba(255, 235, 59, 0.6)",
    "rgba(139, 195, 74, 0.6)",
    "rgba(233, 30, 99, 0.6)",
    "rgba(103, 58, 183, 0.6)",
    "rgba(0, 150, 136, 0.6)",
    "rgba(39, 174, 96, 0.7)",
    "rgba(41, 128, 185, 0.7)",
    "rgba(231, 76, 60, 0.7)",
    "rgba(243, 156, 18, 0.7)",
    "rgba(142, 68, 173, 0.7)",
    "rgba(26, 188, 156, 0.7)",
    "rgba(241, 196, 15, 0.7)",
    "rgba(52, 152, 219, 0.7)",
    "rgba(211, 84, 0, 0.7)",
    "rgba(127, 140, 141, 0.7)",
    "rgba(255, 99, 132, 0.7)",
    "rgba(54, 162, 235, 0.7)",
    "rgba(255, 206, 86, 0.7)",
    "rgba(75, 192, 192, 0.7)",
    "rgba(153, 102, 255, 0.7)",
    "rgba(255, 159, 64, 0.7)",
    "rgba(244, 67, 54, 0.7)",
    "rgba(33, 150, 243, 0.7)",
    "rgba(76, 175, 80, 0.7)",
    "rgba(156, 39, 176, 0.7)",
    "rgba(63, 81, 181, 0.7)",
    "rgba(255, 87, 34, 0.7)",
    "rgba(205, 220, 57, 0.7)",
    "rgba(121, 85, 72, 0.7)",
    "rgba(96, 125, 139, 0.7)",
    "rgba(255, 235, 59, 0.7)",
    "rgba(139, 195, 74, 0.7)",
    "rgba(233, 30, 99, 0.7)",
    "rgba(103, 58, 183, 0.7)",
    "rgba(0, 150, 136, 0.7)",
    "rgba(39, 174, 96, 0.8)",
    "rgba(41, 128, 185, 0.8)",
    "rgba(231, 76, 60, 0.8)",
    "rgba(243, 156, 18, 0.8)",
    "rgba(142, 68, 173, 0.8)",
    "rgba(26, 188, 156, 0.8)",
    "rgba(241, 196, 15, 0.8)",
    "rgba(52, 152, 219, 0.8)",
    "rgba(211, 84, 0, 0.8)",
    "rgba(127, 140, 141, 0.8)",
    "rgba(255, 99, 132, 0.8)",
    "rgba(54, 162, 235, 0.8)",
    "rgba(255, 206, 86, 0.8)",
    "rgba(75, 192, 192, 0.8)",
    "rgba(153, 102, 255, 0.8)",
    "rgba(255, 159, 64, 0.8)",
    "rgba(244, 67, 54, 0.8)",
    "rgba(33, 150, 243, 0.8)",
    "rgba(76, 175, 80, 0.8)",
    "rgba(156, 39, 176, 0.8)",
    "rgba(63, 81, 181, 0.8)",
    "rgba(255, 87, 34, 0.8)",
    "rgba(205, 220, 57, 0.8)",
    "rgba(121, 85, 72, 0.8)",
    "rgba(96, 125, 139, 0.8)",
    "rgba(255, 235, 59, 0.8)",
    "rgba(139, 195, 74, 0.8)",
    "rgba(233, 30, 99, 0.8)",
    "rgba(103, 58, 183, 0.8)",
    "rgba(0, 150, 136, 0.8)",
    "rgba(244, 67, 54, 0.6)",
    "rgba(33, 150, 243, 0.6)",
    "rgba(76, 175, 80, 0.6)",
    "rgba(156, 39, 176, 0.6)",
    "rgba(63, 81, 181, 0.6)",
    "rgba(255, 87, 34, 0.6)",
    "rgba(205, 220, 57, 0.6)",
    "rgba(121, 85, 72, 0.6)",
    "rgba(96, 125, 139, 0.6)",
    "rgba(255, 235, 59, 0.6)",
    "rgba(139, 195, 74, 0.6)",
    "rgba(233, 30, 99, 0.6)",
    "rgba(103, 58, 183, 0.6)",
    "rgba(0, 150, 136, 0.6)",
    "rgba(244, 67, 54, 0.7)",
    "rgba(33, 150, 243, 0.7)",
    "rgba(76, 175, 80, 0.7)",
    "rgba(156, 39, 176, 0.7)",
    "rgba(63, 81, 181, 0.7)",
    "rgba(255, 87, 34, 0.7)",
    "rgba(205, 220, 57, 0.7)",
    "rgba(121, 85, 72, 0.7)",
    "rgba(96, 125, 139, 0.7)",
    "rgba(255, 235, 59, 0.7)",
    "rgba(139, 195, 74, 0.7)",
    "rgba(233, 30, 99, 0.7)",
    "rgba(103, 58, 183, 0.7)",
    "rgba(0, 150, 136, 0.7)",
    "rgba(244, 67, 54, 0.8)",
    "rgba(33, 150, 243, 0.8)",
    "rgba(76, 175, 80, 0.8)",
    "rgba(156, 39, 176, 0.8)",
    "rgba(63, 81, 181, 0.8)",
    "rgba(255, 87, 34, 0.8)",
    "rgba(205, 220, 57, 0.8)",
    "rgba(121, 85, 72, 0.8)",
    "rgba(96, 125, 139, 0.8)",
    "rgba(255, 235, 59, 0.8)",
    "rgba(139, 195, 74, 0.8)",
    "rgba(233, 30, 99, 0.8)",
    "rgba(103, 58, 183, 0.8)",
    "rgba(0, 150, 136, 0.8)",
    "rgba(255, 193, 7, 0.6)",
    "rgba(255, 64, 129, 0.6)",
    "rgba(76, 175, 80, 0.6)",
    "rgba(0, 188, 212, 0.6)",
    "rgba(156, 39, 176, 0.6)",
    "rgba(233, 30, 99, 0.6)",
    "rgba(0, 150, 136, 0.6)",
    "rgba(0, 188, 212, 0.6)",
    "rgba(98, 0, 238, 0.6)",
    "rgba(255, 87, 34, 0.6)",
    "rgba(38, 198, 218, 0.6)",
    "rgba(255, 64, 129, 0.6)",
    "rgba(0, 150, 136, 0.6)",
    "rgba(255, 193, 7, 0.7)",
    "rgba(255, 64, 129, 0.7)",
    "rgba(76, 175, 80, 0.7)",
    "rgba(0, 188, 212, 0.7)",
    "rgba(156, 39, 176, 0.7)",
    "rgba(233, 30, 99, 0.7)",
    "rgba(0, 150, 136, 0.7)",
    "rgba(0, 188, 212, 0.7)",
    "rgba(98, 0, 238, 0.7)",
    "rgba(255, 87, 34, 0.7)",
    "rgba(38, 198, 218, 0.7)",
    "rgba(255, 64, 129, 0.7)",
    "rgba(0, 150, 136, 0.7)",
    "rgba(255, 193, 7, 0.8)",
    "rgba(255, 64, 129, 0.8)",
    "rgba(76, 175, 80, 0.8)",
    "rgba(0, 188, 212, 0.8)",
    "rgba(156, 39, 176, 0.8)",
    "rgba(233, 30, 99, 0.8)",
    "rgba(0, 150, 136, 0.8)",
    "rgba(0, 188, 212, 0.8)",
    "rgba(98, 0, 238, 0.8)",
    "rgba(255, 87, 34, 0.8)",
    "rgba(38, 198, 218, 0.8)",
    "rgba(255, 64, 129, 0.8)",
    "rgba(0, 150, 136, 0.8)",
];












