import * as React from "react";

import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";


export default function CustomCheckBox ({ variant }) {
    const [checked, setChecked] = React.useState(false);

    const handleCheck = () => {
      if (checked) {
        setChecked(false);
      } else {
        setChecked(true);
      }
    };

    return checked ? (
      <Box
        sx={{
          height: "18px",
          width: "18px",
          backgroundColor: "rgba(70, 67, 212, 1)",
          border: "1px solid rgba(0, 0, 0, 0.24)",
          borderRadius: "3px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleCheck}
      >
        {variant == "all" ? (
          <HorizontalRuleIcon sx={{ stroke: "white", fill: "white" }} />
        ) : (
          <CheckIcon sx={{ stroke: "white", fill: "white" }} />
        )}
      </Box>
    ) : (
      <Box
        sx={{
          height: "18px",
          width: "18px",
          backgroundColor: "rgba(247, 247, 247, 1)",
          border: "1px solid rgba(0, 0, 0, 0.24)",
          borderRadius: "3px",
        }}
        onClick={handleCheck}
      ></Box>
    );
};