import { useState } from "react";

import { Box, Typography } from "@mui/material";

import TrainingTimeRange from "./training-time-range";
import InputParameters from "./input-parameters";

export default function ModelConfig({setModel, model, startDate, setStartDate, endDate, setEndDate}){
    return (
        <Box
        sx={{
                padding:'10px',
                // paddingLeft:'50px',
                // paddingRight:'50px',
                display:'flex',
                flexDirection:'column',
                gap:'20px'
            }}
        >
            <TrainingTimeRange startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}/>
            <InputParameters model={model} setModel={setModel}/>
        </Box>
    )
}