import ThermostatIcon from "@mui/icons-material/Thermostat";
import SpeedIcon from "@mui/icons-material/Speed";
import VerifiedIcon from "@mui/icons-material/Verified";
import CompressIcon from "@mui/icons-material/Compress";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import VibrationIcon from "@mui/icons-material/Vibration";

export const SENSOR_ICONS = {
    Temperature: <ThermostatIcon />,
    Speed: <SpeedIcon />,
    Quality: <VerifiedIcon />,
    Pressure: <CompressIcon />,
    Steam: <WhatshotIcon />,
    Vibration: <VibrationIcon />,
};

export const SENSOR_COLORS = {
    PV: "rgba(39, 174, 96, 1)",
    SP: "rgba(41, 128, 185, 1)",
    OUT: "rgba(231, 76, 60, 1)",
    TEST: "rgba(243, 156, 18, 1)",
};

export const GROUP_COLORS = [
    "rgba(39, 174, 96, 1)",
    "rgba(41, 128, 185, 1)",
    "rgba(231, 76, 60, 1)",
    "rgba(243, 156, 18, 1)",
]