import { Box, Typography } from "@mui/material";

export default function DocumentCard({manual}){
    const formatDateString = (dateString) => {
        const date = new Date(dateString);

        // Options for formatting
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        
        // Convert to human-readable format
        const humanReadableDate = date.toLocaleString("en-US", options);

        return humanReadableDate;
    }

    return (
        <Box
            sx={{
                padding:'16px',
                backgroundColor:'#F5F5F5',
                border:'1px solid rgba(0,0,0,0.15)',
                display:'flex',
                flexDirection:'column',
                gap:'5px',
                cursor:'pointer',
                '&:hover':{
                    border:'1px solid rgba(0,0,0,0.25)',
                }
            }}
        >
            <Typography fontSize='14.5px'>{manual.name}</Typography>
            <Box
                sx={{
                    display:'flex',
                    alignItems:'center',
                    gap:'8px',
                }}
            >
                <Typography fontSize='12px'>4 pages</Typography>
                <Typography fontSize='12px'>●</Typography>
                <Typography fontSize='12px'>{formatDateString(manual.date_uploaded)}</Typography>
            </Box>
        </Box>
    )
}